<template>
    <div>
        <Header loc-nm="알림" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="alrim-list">
                        <li v-for="(alarm, index) in alarms.content" :key="index">
                            <a @click="goLink(alarm)">
                                <div class="alrim-box">
                                    <p>{{ alarm.msg }}</p>
                                    <div class="meta-info">
                                        <span class="datetime">{{ alarm.lastDt | longFormatTime }}</span>
                                        <span>{{ roomName(alarm) }}</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="view-more-wrap">
                        <a v-if="alarms.isMore" class="view-more" @click="more()"><span>더보기</span></a>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/SimpleHeader.vue';
import AlarmApi from '../../api/AlarmApi';
import LoginApi from '../../api/LoginApi';
import PageModel from '../../model/PageModel';
import MutationTypes from '../../store/MutationTypes';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../../const';
import { confirmDialog } from '../../helper/DialogHelper';
export default {
    name: 'AlarmList',
    components: { Header },
    data: () => {
        return {
            alarms: new PageModel(),
            page: 1,
            lastId: null
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.alarms.page = this.page;
        this.doSearch();
    },
    beforeDestroy() {
        if (this.lastId) AlarmApi.check(this.lastId);
    },
    methods: {
        ...mapActions([]),
        async doSearch() {
            const query = {
                ...this.alarms.query()
            };
            const response = await AlarmApi.myAllList(query);
            if (response) {
                this.alarms.bind(response, true);
                if (response.content.length > 0) this.lastId = response.content[0].id; //AlarmApi.check(response.content[0].id);
            }

            this.$store.dispatch('hideLoading');
        },
        more() {
            //this.alarms.addPage();
            this.alarms.page = this.page++;
            this.doSearch();
        },
        roomName(alarm) {
            if (alarm.type === 'work') {
                if (alarm.room) return alarm.room.name;
                return alarm.name || '작업';
            } else if (alarm.type === 'notice') return '공지';
            else return '전체알람';
        },
        goLink(alarm) {
            //만약 다름.. csId가...
            if (alarm.csId !== this.basic.csId) {
                confirmDialog('다른 현장의 알림입니다.\n현장을 변경하시겠습니까?', async () => {
                    this.changeSite(alarm);
                });
            } else this.$router.push({ path: alarm.link });
        },
        async changeSite(alarm) {
            const session = {
                name: this.basic.name,
                postionName: this.basic.position,
                companyName: this.basic.company,
                id: this.basic.id,
                csId: alarm.csId
            };

            const res = await LoginApi.selectSite(session);
            //오류나는건 추후 공통처리한다.
            if (res.content) {
                const basic = {
                    csId: session.csId,
                    name: session.name,
                    position: session.postionName,
                    company: session.companyName,
                    companyId: res.content.companyId,
                    id: session.id,
                    hp: res.content.hp,
                    site: res.content.siteName,
                    imgUrl: res.content.imgUrl,
                    csAdminYn: res.content.csAdminYn,
                    csCompanyAdminYn: res.content.csCompanyAdminYn
                };

                this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);

                const oauth = res.content.token;
                this.$store.commit(MutationTypes.SET_OAUTH, oauth);
                window.localStorage.setItem(CS_ID, session.csId);
                window.localStorage.setItem(WORKER_ID, session.id);
                window.localStorage.setItem(JWT_TOKEN, oauth.token);
                window.localStorage.setItem(JWT_REFRESH_TOKEN, oauth.refreshToken);
                //await this.doLastAccess();
                this.$router.push({ path: alarm.link });
            }
        }
    }
};
</script>

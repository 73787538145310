<template>
    <div id="signup-wrap">
        <div id="signup-container">
            <div id="signup-header">
                <ul class="step">
                    <li class="on">
                        <span>1</span>
                    </li>
                    <li><span>2</span></li>
                    <li><span>3</span></li>
                </ul>
                <p class="nm">
                    {{ basic.site }}
                </p>
                <p class="subtit">
                    아이디로 사용할 휴대번호 입력 후 본인인증 하세요.
                </p>
            </div>

            <div id="signup-body">
                <div class="signup-form-wrap">
                    <p>휴대번호 <span class="require">*</span></p>
                    <div class="field">
                        <div class="field-border">
                            <input
                                ref="hp"
                                v-model="hp"
                                type="tel"
                                class="txt"
                                placeholder="-없이 입력 (ex.01012345678)"
                            />
                            <a class="btn-certi" @click="requestCertNum"><span>인증번호 발송</span></a>
                        </div>
                    </div>
                    <div class="field">
                        <input
                            ref="certNo"
                            v-model="certNo"
                            type="text"
                            class="txt"
                            placeholder="인증번호 입력"
                            @blur="onfocusOut"
                        />
                    </div>
                </div>

                <div class="join-agree-box">
                    <div class="field-wrap">
                        <p>회원가입을 위해 아래 약관에 동의하세요.</p>
                    </div>
                    <div class="field-wrap sub-field">
                        <div class="field">
                            <div class="chk">
                                <input id="chk-1" ref="agree1" v-model="agree1" type="checkbox" />
                                <label for="chk-1"><span class="blind">선택</span></label>
                            </div>
                            <div class="guide">
                                <label for="chk-1">서비스 이용약관 동의</label>
                            </div>
                            <div class="link">
                                <a @click="menuPolicy">보기</a>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrap sub-field">
                        <div class="field">
                            <div class="chk">
                                <input id="chk-2" ref="agree2" v-model="agree2" type="checkbox" />
                                <label for="chk-2"><span class="blind">선택</span></label>
                            </div>
                            <div class="guide">
                                <label for="chk-2">개인정보처리방침 동의</label>
                            </div>
                            <div class="link">
                                <a @click="menuPrivacy">보기</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="signup-btn-wrap">
                <a class="btn-next" @click="next"><span>다음으로</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ConstructionApi from '../../api/ConstructionApi';
import LoginApi from '../../api/LoginApi';
import MenuHelper from '../../helper/MenuHelper';
import MutationTypes from '../../store/MutationTypes';

export default {
    name: 'JoinStep1',
    components: {},
    data: () => {
        return {
            csId: 0,
            hp: '',
            certNo: '',
            isRequest: false,
            joinNo: '',
            agree1: false,
            agree2: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            smsCert: state => state.join.smsCert
        })
    },
    created() {
        this.logout();
    },
    mounted() {
        this.csId = Number(this.$route.params.csId);
        this.getCsInfo();
        this.hp = this.smsCert.hp;
        this.certNo = this.smsCert.certNo;
        this.isRequest = this.smsCert.isRequest;
        this.agree1 = this.smsCert.agree1;
        this.agree2 = this.smsCert.agree2;
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setJoin', 'setSmsCert', 'logout']),
        onfocusOut() {
            window.scrollTo(0, 0);
        },
        getCsInfo() {
            ConstructionApi.detail(this.csId).then(res => {
                if (res.content) {
                    const basic = {
                        csId: res.content.id,
                        site: res.content.name
                    };
                    this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                }
            });
            //
        },
        async requestCertNum() {
            if (!this.hp) {
                alert('인증번호를 입력받을 휴대폰을 입력하세요');
                this.$refs.hp.focus();
                return false;
            }
            try {
                await LoginApi.sendAuthNoUsingSms({
                    csId: this.csId,
                    number: this.hp
                });
                alert('입력하신 휴대번호로 인증번호를 발송하였습니다.');
                this.isRequest = true;
            } catch (error) {
                if (error.response) {
                    if (error.response.data.code === 'err.login.006') {
                        this.$router.push({ path: '/join/site/' + this.csId });
                    } else if (error.response.data.code === 'err.login.009') {
                        this.$router.push({ path: '/join/login/' + this.csId });
                    } else alert(error.response.data.msg);
                }
            }
        },
        async next() {
            if (await this.validation()) {
                this.$router.push({ path: '/join/step2' });
            }
        },
        async validation() {
            if (this.isRequest === false) {
                alert('인증번호를 요청하신다음, 인증번호를 정확하게 입력하세요');
                this.$refs.hp.focus();
                return false;
            }
            if (!this.certNo) {
                alert('인증번호를 정확하게 입력하세요');
                this.$refs.certNo.focus();
                return false;
            }
            if (!this.agree1) {
                alert('서비스 이용약관에 동의해 주세요');
                this.$refs.agree1.focus();
                return false;
            }
            if (!this.agree2) {
                alert('개인정보방침에 동의해 주세요');
                this.$refs.agree2.focus();
                return false;
            }
            try {
                const response = await LoginApi.checkAuthNo({
                    authNo: this.certNo
                });

                if (!response.content) {
                    alert('인증번호가 일치하지 않습니다.');
                    return false;
                }

                this.joinNo = response.content;
                await this.setJoin({ hp: this.hp, csId: this.csId, joinNo: this.joinNo });
                return true;
            } catch (error) {
                if (error.response) {
                    alert(error.response.data.msg);
                }
            }

            return false;
        },
        menuPolicy() {
            this.setSmsCert({
                hp: this.hp,
                certNo: this.certNo,
                isRequest: this.isRequest,
                agree1: this.agree1,
                agree2: this.agree2
            });
            MenuHelper.policy(this.$router);
        },
        menuPrivacy() {
            this.setSmsCert({
                hp: this.hp,
                certNo: this.certNo,
                isRequest: this.isRequest,
                agree1: this.agree1,
                agree2: this.agree2
            });
            MenuHelper.privacy(this.$router);
        }
    }
};
</script>

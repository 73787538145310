<template>
    <section class="round-section">
        <div class="in-section task-box">
            <div v-if="imgs.length > 0" class="task-thumb">
                <div class="swiper-container">
                    <swiper :options="swiperOption" class="swiper-wrapper">
                        <swiper-slide v-for="(img, index) in imgs" :key="index" class="swiper-slide">
                            <a @click="closeUtilBox">
                                <div class="thumb-slider">
                                    <div class="thumb-inner">
                                        <img
                                            v-gallery="'actionImage'"
                                            :data-index="index"
                                            :data-large="img.url"
                                            :src="img.url | thumbUrl"
                                        />
                                    </div>
                                </div>
                            </a>
                        </swiper-slide>
                        <div slot="pagination" class="swiper-pagination" />
                    </swiper>
                </div>
            </div>

            <div class="task-info-box">
                <div class="task-info-top-box">
                    <div class="task-user-info">
                        <div class="task-type">
                            <span v-if="work.regId === item.register.id" class="type-1">작업</span>
                            <span v-else class="type-4">조치</span>
                        </div>
                        <span @click="sendHp(item.register.hp)">
                            {{ item.register.name + ' ' + item.register.positionName }}&middot;
                            {{ item.register.company.name }}
                        </span>
                        <div class="more-util">
                            <button class="btn-more-util" @click="utilMore(item.id)">
                                <span class="blind">툴 더보기</span>
                            </button>
                            <more-util
                                v-if="isSelected(item)"
                                :item="item"
                                more-type="action"
                                :imgs="imgs"
                                :work-id="work.id"
                                @moreClose="() => $emit('update:selectId', 0)"
                                @removeAction="removeAction"
                            />
                        </div>
                    </div>
                    <p
                        class="task-memo"
                        :inner-html.prop="convertCrToBr(item.content) | withLinks"
                        @click="closeUtilBox()"
                    />
                </div>
                <!-- 20200527 첨부파일 추가내용 -->
                <div v-if="docFileList.length > 0" class="task-info-btm-box">
                    <dl>
                        <dt><span class="tit-files">첨부</span></dt>
                        <dd>
                            <div>
                                <a v-for="(file, index) in docFileList" :key="index" @click="download(file)">
                                    <span class="files">{{ file.name }}</span>
                                </a>
                            </div>
                        </dd>
                    </dl>
                </div>
                <!-- 20200527 첨부파일 추가내용 //-->
            </div>
        </div>
        <div class="meta-info" @click="closeUtilBox()">
            <span class="datetime">{{ item.regDt | longFormatTime }}</span>
            <span class="comment-cnt" @click="menuComment(item.id, item.commentId)">
                댓글 {{ item.countOfComment || 0 }}
            </span>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import MoreUtil from './MoreUtil.vue';
import MenuHelper from '../../helper/MenuHelper';
import MobileHelper from '../../helper/MobileHelper';
import FileApi from '../../api/FileApi';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { convertCrToBr } from '../../utils/string';

//import { confirmDialog } from '../../helper/DialogHelper';

export default {
    name: 'ActionItem',
    components: { MoreUtil, swiper, swiperSlide },
    props: {
        item: {
            type: Object,
            required: true
        },
        work: {
            type: Object,
            required: true
        },
        selectId: {
            type: Number,
            required: true
        }
    },
    data: () => {
        return {
            isMore: false,
            imgs: [],
            swiperOption: {
                //direction: 'vertical',
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                }
            },
            docFileList: []
        };
    },
    computed: {},
    created() {},
    mounted() {
        if (this.item.file) this.imgs = this.item.file.detail || [];
        if (this.item.docFileId) {
            FileApi.list(this.item.docFileId).then(res => {
                this.docFileList = res;
            });
        }
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        closeUtilBox() {
            this.$emit('update:selectId', 0);
            //this.selectId = 0;
        },
        isSelected(item) {
            return this.selectId === item.id;
        },
        utilMore(id) {
            //if (this.isMore) this.isMore = false;
            //else this.isMore = true;
            //this.selectId = id;
            //alert(this.selectId);
            this.$emit('update:selectId', id);
            this.$emit('moreClose');
        },
        menuComment(id, commentId) {
            MenuHelper.commentOfaction(this.$router, id, commentId || 0);
        },
        convertCrToBr,
        removeAction(id) {
            this.$emit('removeAction', id);
        },
        sendHp(hp) {
            this.isMore = false;
            if (hp) window.open('tel:' + hp, '_system');
        },
        async download(file) {
            if (MobileHelper.checkDownload()) {
                this.showLoading();
                if (MobileHelper.isWebView()) {
                    await FileApi.downloadData(file.id);
                } else await FileApi.fileDownload(file.id);
                this.hideLoading();
            }
            /*
            confirmDialog('파일을 다운로드 하시겠습니까?', async () => {
                //await this.showLoading();
                if (MobileHelper.isWebView()) {
                    await FileApi.downloadData(file.id);
                } else await FileApi.fileDownload(file.id);
                //await this.hideLoading();
            });
            */
        }
    }
};
</script>

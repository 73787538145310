import BaseModel from './BaseModel';
class WorkModel extends BaseModel {
    constructor(json, options) {
        super(json, options);
    }

    get id() {
        return this.getPayload().id;
    }

    get type() {
        return this.getPayload().type;
    }

    get name() {
        return this.getPayload().register.name;
    }

    get companyName() {
        return this.getPayload().register.company.name;
    }

    get positionName() {
        return this.getPayload().register.positionName;
    }

    get countOfComment() {
        return this.getPayload().countOfComment;
    }

    get finishYn() {
        return this.getPayload().finishYn;
    }

    set finishYn(value) {
        this.getPayload().finishYn = value;
    }

    get fileId() {
        return this.getPayload().fileId;
    }

    get commentId() {
        return this.getPayload().commentId;
    }

    get regDt() {
        return this.getPayload().regDt;
    }

    get content() {
        return this.getPayload().content;
    }

    get hp() {
        return this.getPayload().register.hp;
    }

    get regId() {
        return this.getPayload().register.id;
    }

    get docFileId() {
        return this.getPayload().docFileId;
    }
}

export default WorkModel;

<template>
    <div>
        <header id="header" class="header group">
            <div class="in-section blue">
                <ul>
                    <li class="back">
                        <button type="button" class="btn-back" @click="goMain()">
                            <span class="blind">메인</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>새로운 그룹 생성</span>
                    </li>
                    <li class="etc">
                        <button type="button" class="btn-etc" @click="registGroup()">
                            <span>확인</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>

        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body blueFull">
                    <div class="group-form-wrap">
                        <input
                            v-model="room.name"
                            type="text"
                            class="txt"
                            placeholder="그룹명을 입력하세요."
                            autofocus
                            maxlength="10"
                        />
                    </div>
                    <div class="alarm">
                        <p>
                            그룹 생성 후에는 해당 그룹의 [멤버 관리] 아이콘을 눌러
                            <br />
                            활동 멤버를 추가/삭제할 수 있습니다.
                        </p>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import WorkRoomApi from '../../api/WorkRoomApi';
import MenuHelper from '../../helper/MenuHelper';
import { alertDialog } from '../../helper/DialogHelper';
export default {
    name: 'RegistRoom',
    components: {},
    data: () => {
        return {
            room: { name: '' }
        };
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        goMain() {
            this.$router.push({ path: '/main' });
        },
        async registGroup() {
            if (this.room.name) {
                const res = await WorkRoomApi.regist(this.room);
                if (res) {
                    //MenuHelper.room(this.$router, res.id);
                    MenuHelper.addWorkerNoHistory(this.$router, res.id);
                }
            } else {
                alertDialog('그룹명을 입력하세요.');
            }
        }
    }
};
</script>

<template>
    <section class="round-section">
        <div class="in-section task-box">
            <div v-if="imgs.length > 0" class="task-thumb">
                <div class="swiper-container">
                    <swiper :options="swiperOption" class="swiper-wrapper">
                        <swiper-slide v-for="(img, index) in imgs" :key="index" class="swiper-slide">
                            <a>
                                <div class="thumb-slider">
                                    <div class="thumb-inner">
                                        <img
                                            v-gallery="'actionImage'"
                                            :data-index="index"
                                            :data-large="img.url"
                                            :src="img.url | thumbUrl"
                                        />
                                    </div>
                                </div>
                            </a>
                        </swiper-slide>
                        <div slot="pagination" class="swiper-pagination" />
                    </swiper>
                </div>
            </div>

            <div class="task-info-box">
                <div class="task-info-top-box">
                    <div class="task-user-info">
                        <div class="task-type">
                            <span v-if="work.regId === item.register.id" class="type-1">작업</span>
                            <span v-else class="type-4">조치</span>
                        </div>
                        <span>
                            {{ item.register.name + ' ' + item.register.positionName }}&middot;
                            {{ item.register.companyName }}
                        </span>
                    </div>
                    <p class="task-memo" v-html="convertCrToBr(item.content)" />
                </div>
            </div>
        </div>
        <div class="meta-info">
            <span class="datetime">{{ item.regDt | longFormatTime }}</span>
        </div>
    </section>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { convertCrToBr } from '../../utils/string';

export default {
    name: 'KakaoActionItem',
    components: { swiper, swiperSlide },
    props: {
        item: {
            type: Object,
            required: true
        },
        work: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            isMore: false,
            imgs: [],
            swiperOption: {
                //direction: 'vertical',
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                }
            }
        };
    },
    computed: {},
    created() {},
    mounted() {
        if (this.item.file) this.imgs = this.item.file.detail || [];
    },
    beforeDestroy() {},
    methods: {
        convertCrToBr
    }
};
</script>

<template>
    <section class="round-section">
        <div v-for="(room, index) in rooms" :key="index" class="in-section right">
            <div class="group-box">
                <div class="group-inbox">
                    <div class="group-thumb">
                        <div class="thumb">
                            <div class="thumb-inner">
                                <a @click="menuRoom(room.id)">
                                    <img
                                        :src="room.master.imgUrl | sProfileThumbUrl"
                                        onerror="javascript:this.src='/images/main/profile.jpg'"
                                    />
                                </a>
                            </div>
                        </div>
                        <div v-if="room.alarmCnt > 0" class="member-count">
                            {{ room.alarmCnt }}
                        </div>
                    </div>

                    <div class="group-summary">
                        <a @click="menuRoom(room.id)">
                            <p class="group">
                                {{ room.name }}
                                <span>방장 {{ room.master.name }} {{ getMemberCount(room.countOfMember) }}</span>
                            </p>
                            <p class="member">
                                {{ room.workers }}
                            </p>
                        </a>

                        <div class="more">
                            <a class="btn-more" @click="menuRoom(room.id)"><span class="blind">더보기</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import WorkApi from '../../api/WorkApi';
//import WorkerApi from '../../api/WorkerApi';
//joinOfRoomList
//import DataHelper from '../../helper/DataHelper';
import MenuHelper from '../../helper/MenuHelper';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';
//import MutationTypes from '../../store/MutationTypes';
export default {
    name: 'RoomList',
    components: {},
    data: () => {
        return {
            //rooms: []
        };
    },
    computed: {
        ...mapState({
            rooms: state => state.work.roomList,
            basic: state => state.main.basic,
            searchCondition: state => state.work.searchCondition
        })
    },
    created() {},
    mounted() {
        this.getWorkOfRoomList();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getMyRooms', 'getWorkOfRoom', 'incRoomCnt', 'incAlarmCnt']),
        async getWorkOfRoomList() {
            await this.getMyRooms();
            if (!window.stompClient) {
                this.connectSoc();
            }
        },
        menuRoom(id) {
            MenuHelper.room(this.$router, id);
        },
        taskName(work) {
            return WORK_TYPE[work.type];
        },
        taskType(work) {
            return WORK_TYPE_CSS[work.type].replace('-', '0');
        },
        menuWork(id) {
            MenuHelper.work(this.$router, id);
        },
        classCount(work) {
            if (work.finishYn === 'Y') return 'donedone';
            else if (work.countOfAction === 0) return 'done';
            return '';
        },
        substr(value) {
            if (value && value.length > 10) return value.substring(0, 10);
            return value;
        },
        finishCount(work) {
            if (work.finishYn === 'Y') return '완료';
            return work.countOfAction;
        },
        async connectSoc() {
            if (!window.stompClient) {
                this.socket = new SockJS('/websocket');
                this.stompClient = Stomp.over(this.socket);
                window.stompClient = this.stompClient;
                window.stompClient.connect(
                    {},
                    () => {

                        window.stompClient.subscribe("/topic/chat/addRoom", tick => {
                          console.log("workerIds::"+JSON.parse(tick.body).workerIds);
                          console.log("this.basic.id::"+this.basic.id)
                          if(JSON.parse(tick.body).workerIds.includes(String(this.basic.id))){
                            console.log("OK!!");
                            this.getWorkOfRoomList();
                            this.addSubRoom(JSON.parse(tick.body).roomId);
                          }
                        });

                        window.stompClient.subscribe('/topic/chat/addAlarm', tick => {
                            if (
                                localStorage.getItem('csIds').includes(JSON.parse(tick.body).csId) ||
                                JSON.parse(tick.body).workerId === this.basic.id
                            ) {
                                this.incAlarmCnt();
                            }
                        });
                        this.rooms.map(v => {
                            this.addSubRoom(v.id);
                        });
                    },
                    error => {
                        console.log(error);
                        this.$router.go(this.$router.currentRoute);
                        if (window.stompClient) {
                            window.stompClient.disconnect();
                            window.stompClient = null;
                            setTimeout(this.connectSoc(), 10000);
                            console.log('STOMP: Reconecting in 10 seconds');
                        }
                    }
                );
            }
        },
        addSubRoom(roomId) {
            if (window.stompClient) {
                window.stompClient.subscribe('/topic/chat/' + roomId, this.subRoomSuc);
            }
        },
        async addRoom(roomId, workerIds) {
            if(window.stompClient){
              await this.getMyRooms();
              console.log("send");
              console.log(this.rooms);
              window.stompClient.send("/app/chat.message/addRoom", JSON.stringify({'roomId': roomId, 'workerIds' : workerIds}), {});
              this.addSubRoom(roomId);
            }
        },
        subRoomSuc(tick){
            const roomId = Number(JSON.parse(tick.body).roomId);
            console.log("roomId"+roomId);
            if (this.$router.history.current.name === 'Room') {
              this.getWorkOfRoom(this.searchCondition);
            } else {
              this.incRoomCnt(roomId);
            }
        },
        getMemberCount(count) {
            if (count === 1) return '';
            else return '외 ' + (count - 1);
        }
    }
};
</script>

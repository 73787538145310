<template>
    <div>
        <Header :loc-nm="boardName" loc-type="regist" :fn-confirm="regist" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <div class="write-form-wrap">
                        <div class="form-field border-none">
                            <div class="field">
                                <input
                                    ref="boardTitle"
                                    v-model="board.title"
                                    type="text"
                                    class="txt"
                                    placeholder="기술문의 제목을 입력하세요."
                                />
                            </div>
                        </div>
                        <!-- 20200527 추가내용 border-none 추가 -->
                        <div class="form-field border-none">
                            <div class="field">
                                <div class="file-add-box">
                                    <input
                                        type="button"
                                        class="button"
                                        value="파일찾기/이후파일명노출"
                                        @click="pickerFile"
                                    />
                                    <input
                                        id="docFile"
                                        ref="docFile"
                                        type="file"
                                        :accept="DOC_ALLOW_FILE"
                                        class="blind"
                                        @change="doDocFileAttach"
                                    />
                                    <a class="btn-file-add" @click="pickerFile"><span class="blind">파일검색</span></a>
                                </div>
                                <div class="selected-list">
                                    <span v-for="(fileInfo, index) in showFileList" :key="index" class="file">
                                        {{ fileInfo.name }}
                                        <button class="btn_remove" @click="onClickFileDel(fileInfo)">삭제</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- 20200527 추가내용 border-none 추가 //-->

                        <div id="toolbar" class="editor-box">
                            <input id="camera" type="file" accept="image/*" capture="camera" class="blind" />
                            <input id="gallery" type="file" class="blind" />
                            <div class="util-list">
                                <ul>
                                    <li>
                                        <a class="color-black" @click="setColor('#000000')">
                                            <span class="blind">검정</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="color-blue" @click="setColor('#0070c0')">
                                            <span class="blind">파랑</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="color-red" @click="setColor('#ff0000')">
                                            <span class="blind">빨강</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="color-yellow" @click="setColor('#ffc000')">
                                            <span class="blind">노랑</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="color-green" @click="setColor('#00b050')">
                                            <span class="blind">초록</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="color-violet" @click="setColor('#7030a0')">
                                            <span class="blind">보라</span>
                                        </a>
                                    </li>
                                    <li>
                                        <button
                                            style="float: none;"
                                            href="#"
                                            class="tool-bold ql-bold"
                                            data-style="bold"
                                        >
                                            <span class="blind">진하게</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            style="float: none;"
                                            href="#"
                                            class="tool-italic ql-italic"
                                            data-style="italic"
                                        >
                                            <span class="blind">기울기</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            style="float: none;"
                                            class="tool-under ql-underline"
                                            data-style="underline"
                                        >
                                            <span class="blind">밑줄</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="write-memo-box">
                        <quill-editor
                            ref="myQuillEditor"
                            v-model="board.content"
                            style="height: 95%;margin-left: 15px;"
                            :options="editorOption"
                            @blur="onEditorBlur($event)"
                            @focus="onEditorFocus($event)"
                            @ready="onEditorReady($event)"
                        />
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/RegistWhiteHeader.vue';
import BoardApi from '../../api/BoardApi';
import ArrayHelper from '../../helper/ArrayHelper';
import EventHelper from '../../helper/EventHelper';
import FileHelper from '../../helper/FileHelper';

import { alertDialog } from '../../helper/DialogHelper';

//import MenuHelper from '../../helper/MenuHelper';
import FileApi from '../../api/FileApi';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
export default {
    name: 'AlarmList',
    components: { Header, quillEditor },
    data: () => {
        return {
            bid: null,
            board: {},
            boardName: '기술문의',
            boardType: 'qna',
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: '#toolbar'
                }
            },
            content: '',
            addFileList: [],
            delFileList: [],
            showFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            fileId: 0,
            fileNo: 0,
            test: ''
        };
    },
    computed: {
        ...mapState({}),
        editor() {
            return this.$refs.myQuillEditor.quill;
        }
    },
    created() {},
    mounted() {
        this.bid = this.$route.params.id;
        this.boardType = this.$route.meta.type;
        this.board.type = this.boardType;
        this.board.noticeYn = 'N';
        EventHelper.onNavMenu();
        EventHelper.offNavMenu();
        this.makeQuillUpload(this.editor);
        if (this.bid) {
            BoardApi.detail(this.bid).then(res => {
                this.board = res;
                if (this.board.fileId) {
                    this.fileId = this.board.fileId;
                    FileApi.list(this.board.fileId).then(fileRes => {
                        this.showFileList = fileRes.map(item => {
                            return { fileId: item.id.fileId, fileNo: item.id.fileNo, name: item.name };
                        });
                        this.fileNo = this.showFileList[this.showFileList.length - 1].fileNo;
                    });
                }

                //
            });
        }
    },
    beforeDestroy() {
        //console.log('event제거');
    },
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        pickerFile() {
            if (FileHelper.isCount(this.showFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    ++this.fileNo;
                    const formData = new FormData();
                    formData.append('file_id', this.fileId || '');
                    formData.append('file_no', this.fileNo);
                    formData.append('file', fileObject);
                    const addFile = {
                        fileNo: this.fileNo,
                        form: formData
                    };
                    this.addFileList.push(addFile);
                    const fileDetail = {
                        name: fileObject.name,
                        fileId: this.fileId,
                        fileNo: this.fileNo
                    };
                    this.showFileList.push(fileDetail);
                }
            }
        },
        onClickFileDel(fileInfo) {
            if (fileInfo.fileId > 0) {
                this.delFileList.push(fileInfo);
            }
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.showFileList, condition);
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        check(board) {
            BoardApi.check(board.id).then(() => {
                const condition = item => item.id === board.id;
                ArrayHelper.oneRemove(this.board, condition);
            });
        },
        async regist() {
            if (!this.board.title) {
                alertDialog('제목을 입력하세요');
                this.$refs.boardTitle.focus();
                return;
            }
            //파일 삭제
            this.showLoading();
            for (var delFile of this.delFileList) {
                delFile.file_id = delFile.fileId;
                delFile.file_no = delFile.fileNo;
                const res = await FileApi.remove(delFile);
                if (res.content) {
                    this.fileId = res.content[0].id.fileId;
                    this.board.fileId = this.fileId;
                }
            }
            //파일 추가

            for (var docFile of this.addFileList) {
                const docFileForm = docFile.form;
                if (this.fileId > 0) docFileForm.set('file_id', this.fileId);
                const res = await FileApi.fileUpload(docFileForm, 'qna');
                if (res.content) {
                    this.fileId = res.content[0].id.fileId;
                    this.board.fileId = this.fileId;
                }
            }

            if (this.bid) {
                BoardApi.modify(this.bid, this.board).then(res => {
                    this.hideLoading();
                    if (res) {
                        this.$router.go(-1);
                    }
                });
            } else {
                BoardApi.regist(this.board).then(res => {
                    this.hideLoading();
                    if (res) {
                        this.$router.go(-1);
                    }
                });
            }
        },
        share(board) {
            //sns helper를 만들자
            console.log('share', board);
        },
        remove(board) {
            //권한체크 helper를 만들자
            console.log('remove', board);
        },
        onEditorBlur(quill) {
            console.log('editor blur!', quill);
        },
        onEditorFocus(quill) {
            console.log('editor focus!', quill);
        },
        onEditorReady(quill) {
            console.log('editor ready!', quill);
        },
        onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text);
            this.content = html;
        },
        setColor(color) {
            this.$refs.myQuillEditor.quill.format('color', color);
        },
        setStyle(style) {
            this.$refs.myQuillEditor.quill.format(style, true);
        },
        makeQuillUpload(quill) {
            $('#gallery').on('click', function(e) {
                e.preventDefault();
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.click();

                input.onchange = function() {
                    const fd = new FormData();
                    const file = $(this)[0].files[0];
                    fd.append('image', file);
                    fd.append('file_id', '');

                    $.ajax({
                        type: 'post',
                        enctype: 'multipart/form-data',
                        url: '/api/file/imgupload',
                        data: fd,
                        processData: false,
                        contentType: false,
                        success: function(data) {
                            const range = quill.getSelection();
                            quill.insertEmbed(
                                range,
                                'image',
                                '/api/file/download/image?file_id=' + data.content.fileId
                            );
                        },
                        error: function(err) {
                            console.error('Error :::' + err);
                        }
                    });
                };
            });
            //quill.getModule('toolbar').addHandler('image', function() {});
        }
    }
};
</script>
<style type="text/css">
.ql-container.ql-snow {
    border: 0px;
}
</style>

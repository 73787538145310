<template>
    <div id="signup-wrap">
        <div id="signup-container">
            <div id="signup-header">
                <ul class="step">
                    <li><span>1</span></li>
                    <li class="on">
                        <span>2</span>
                    </li>
                    <li><span>3</span></li>
                </ul>
                <p class="nm">
                    {{ basic.site }}
                </p>
                <p class="subtit">
                    회원님의 성명과 비밀번호를 입력하세요.
                </p>
            </div>

            <div id="signup-body">
                <div class="signup-form-wrap">
                    <p>성명 <span class="require">*</span></p>
                    <div class="field">
                        <input ref="name" v-model="name" type="text" class="txt" autocomplete="false" placeholder="성명을 입력하세요." />
                    </div>

                    <p>비밀번호 <span class="require">*</span></p>
                    <div class="field">
                        <input
                            ref="passwd"
                            v-model="passwd"
                            type="password"
                            autocomplete="new-password"
                            class="txt"
                            placeholder="비밀번호를 입력하세요."
                        />
                    </div>
                    <div class="field">
                        <input
                            ref="repasswd"
                            v-model="repasswd"
                            type="password"
                            class="txt"
                            placeholder="비밀번호를 다시 한 번 입력하세요."
                        />
                    </div>
                </div>
            </div>

            <div class="signup-btn-wrap cnt2">
                <a class="btn-prev" @click="prev"><span>이전으로</span></a>
                <a class="btn-next" @click="next"><span>다음으로</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoginApi from '../../api/LoginApi';
export default {
    name: 'JoinStep2',
    components: {},
    data: () => {
        return {
            name: '',
            passwd: '',
            repasswd: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            join: state => state.join.join
        })
    },
    created() {},
    mounted() {
        setTimeout(() => {
            this.checkAuth();
        }, 200);
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setJoin']),
        async checkAuth() {
            try {
                const response = await LoginApi.checkJoinNo({
                    authNo: this.join.joinNo
                });

                if (!response.content) {
                    alert('잘못된 접근입니다.');
                    this.$router.go(-1);
                    return false;
                }
            } catch (error) {
                if (error.response) {
                    alert(error.response.data.msg);
                }
            }
        },
        prev() {
            this.$router.go(-1);
        },
        next() {
            //등록검사
            //const hp = this.join.hp;
            //const csId = this.join.csId;

            this.setJoin({
                ...this.join,
                name: this.name,
                passwd: this.passwd
            });
            if (this.validation()) this.$router.push({ path: '/join/step3' });
        },
        validation() {
            if (!this.name) {
                alert('성명을 정확하게 입력하세요');
                this.$refs.name.focus();
                return false;
            }
            if (!this.passwd) {
                alert('비밀번호를 정확하게 입력하세요');
                this.$refs.passwd.focus();
                return false;
            }

            if (this.passwd !== this.repasswd) {
                alert('비밀번호가 일치하지 않습니다.');
                this.$refs.repasswd.focus();
                return false;
            }
            return true;
        }
    }
};
</script>

import WorkDailyList from '../components/daily/WorkDailyList';
import WorkDailyRegist from '../components/daily/WorkDailyRegist';
import WorkDailyDetail from '../components/daily/WorkDailyDetail';

export default [
    {
        path: '/workdaily',
        name: 'WorkDailyList',
        component: WorkDailyList
    },
    {
        path: '/workdaily/regist',
        name: 'WorkDailyRegist',
        component: WorkDailyRegist,
        meta: {
            bodyClass: 'write-report'
        }
    },
    {
        path: '/workdaily/:id',
        name: 'WorkDailyDetail',
        component: WorkDailyDetail,
        meta: {
            bodyClass: 'write-report'
        }
    },
    {
        path: '/workdaily/regist/:id',
        name: 'WorkDailyRegistById',
        component: WorkDailyRegist,
        meta: {
            bodyClass: 'write-report'
        }
    }
];

<template>
    <div>
        <header id="header" class="header primary">
            <div class="in-section">
                <ul>
                    <li class="back">
                        <button type="button" class="btn-back" @click="back()">
                            <span class="blind">뒤로</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>대상 그룹 선택</span>
                    </li>
                    <li class="board-detail">
                        <a class="btn-modify" @click="shareWork()">
                            <span>확인</span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="그룹명, 방장 검색"
                            @keyup="setFilterRoom"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>
        <article id="content-wrap">
            <main id="contents">
                <div class="contents-body">
                    <ul class="groupcopy-list">
                        <li v-for="(room, index) in filterRooms" :key="index">
                            <div class="group-info-box">
                                <div class="chk">
                                    <input :id="'chk-1-' + index" v-model="roomIds" type="checkbox" :value="room.id" />
                                    <label :for="'chk-1-' + index"><span class="blind">선택</span></label>
                                </div>
                                <div class="group-box">
                                    <div class="group-inbox">
                                        <div class="group-thumb">
                                            <div class="thumb">
                                                <div class="thumb-inner">
                                                    <img
                                                        :src="room.master.imgUrl | sProfileThumbUrl"
                                                        onerror="javascript:this.src='/images/main/profile.jpg'"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="member-count1">
                                            {{ room.countOfMember }}
                                        </div>
                                        <div class="group-summary">
                                            <a>
                                                <p class="group">
                                                    {{ room.name }}
                                                    <span v-if="room.master"> 방장 {{ room.master.name }} </span>
                                                </p>
                                                <p class="member">{{ substr(room.workers) }}</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </main>
        </article>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';
import WorkApi from '../../api/WorkApi';
export default {
    name: 'ShareRoom',
    components: {},
    data: () => {
        return {
            //rooms: []
            workId: null,
            filterRooms: [],
            room: {},
            searchText: '',
            roomIds: []
        };
    },
    computed: {
        ...mapState({
            rooms: state => state.work.roomList,
            basic: state => state.main.basic
        })
    },
    created() {
        $('html').scrollTop(0);
        this.workId = parseInt(this.$route.params.id);
    },
    mounted() {
        this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getMyRooms', 'getWorkOfRoom']),

        setFilterRoom() {
            this.filterRooms = this.rooms.filter(
                room => room.name.indexOf(this.searchText) > -1 || room.master.name.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterRoom();
        },
        bind() {
            //await this.getMyRooms();
            this.filterRooms = this.rooms.map(item => item);
        },
        back() {
            this.$router.go(-1);
        },
        substr(value) {
            if (value && value.length > 20) return value.substring(0, 20) + '..';
            return value;
        },
        async shareWork() {
            const param = this.roomIds.map(roomId => {
                return { roomId, workId: this.workId };
            });
            await WorkApi.shareWork(param);
            alert('공유하였습니다.');
            this.$router.go(-1);
        }
    }
};
</script>

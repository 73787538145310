<template>
    <div ref="filterOccupationLayer" class="filter-select-wrap filter-occupations-wrap">
        <header class="header primary">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="close">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>직종 추가</span>
                    </li>
                    <li class="board-detail">
                        <a class="btn-delete" @click="allSelect()">
                            <span>{{ allText }}</span>
                        </a>
                        <a class="btn-modify" @click="doConfirm()">
                            <span>확인</span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="직종 검색"
                            @input="setFilterOccupation"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-result-box">
            <div class="filter-result">
                <ul class="modal-option-list">
                    <li v-for="(occupation, index) in filterOccupations" :key="index">
                        <div class="chk-list">
                            <input
                                :id="'modal-chk-occupation-' + index"
                                v-model="selectOccupations"
                                type="checkbox"
                                class="blind"
                                :value="occupation"
                            />
                            <label :for="'modal-chk-occupation-' + index">{{ occupation.id.code.name }}</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkDailyApi from '../../api/WorkDailyApi';

export default {
    name: 'FilterOccupation',
    components: {},
    props: {},
    data: () => {
        return {
            occupations: [],
            filterOccupations: [],
            selectOccupations: [],
            searchText: '',
            allText: '전체선택',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.$emit('setOccupations', this.selectOccupations);
        //this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterOccupationLayer.classList.remove('on');
            //this.tempTag = [];
        },
        setFilterOccupation() {
            this.filterOccupations = this.occupations.filter(
                occupation => occupation.id.code.name.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterOccupation();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setOccupations', this.selectOccupations);
            this.$refs.filterOccupationLayer.classList.remove('on');
            this.selectOccupations = [];
        },
        async bind(today) {
            const response = (await WorkDailyApi.companyOccupations(today)) || { content: [] };
            this.occupations = response.content.map(item => item);
            this.filterOccupations = this.occupations;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.allText = '전체해제';
                this.selectOccupations = this.filterOccupations.map(item => item);
            } else {
                this.allText = '전체선택';
                this.selectOccupations = [];
            }
        }
    }
};
</script>

<template>
    <div ref="filterRoomLayer" class="filter-select-wrap filter-room-wrap">
        <header class="header">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="close">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>그룹 선택</span>
                    </li>
                    <li class="etc">
                        <button type="button" class="btn-etc" @click="doConfirm()">
                            <span>확인</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="그룹명 검색"
                            @input="setFilterRoom"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-result-box">
            <div class="filter-result">
                <ul class="modal-option-list">
                    <li v-for="(item, index) in filterRooms" :key="index">
                        <div class="radio-list">
                            <input
                                :id="'modal-radio-room-1-1' + index"
                                v-model="room"
                                type="radio"
                                class="blind"
                                :value="item"
                            />
                            <label :for="'modal-radio-room-1-1' + index">
                                {{ item.name }}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'FilterRoom',
    components: {},
    props: {
        roomId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    data: () => {
        return {
            //roomId: 0,
            filterRooms: [],
            room: {},
            searchText: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            rooms: state => state.work.roomList
        })
    },
    created() {},
    mounted() {
        //this.roomId = Number(this.$route.params.roomId);
        this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterRoomLayer.classList.remove('on');
        },
        setFilterRoom() {
            this.filterRooms = this.rooms.filter(room => room.name.indexOf(this.searchText) > -1);
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterRoom();
        },
        doConfirm() {
            this.$emit('setRoom', this.room);
            this.close();
        },
        async bind() {
            this.filterRooms = this.rooms.map(item => {
                const room = {};
                room.id = item.id;
                room.name = item.name;
                return room;
            });
        }
    }
};
</script>

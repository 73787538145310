<template>
    <div>
        <Header loc-nm="통합검색" />
        <div class="subheader-list-box search">
            <div class="search-wrap">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="condition.content"
                            type="text"
                            class="txt"
                            placeholder="내용 검색"
                            @keyup.enter="doSearch"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="condition.content = ''">
                        <span class="blind">삭제</span>
                    </button>
                </div>
                <div class="filter-btn-box">
                    <button class="btn-filter" @click="onFilter">
                        <span class="blind">필터</span>
                    </button>
                </div>
            </div>
        </div>
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <template v-if="works.length === 0 && isSearch">
                        <ul class="list">
                            <li class="no-data">
                                <p>검색 결과가 없습니다.</p>
                            </li>
                        </ul>

                        <p class="recent-keyword-tit">
                            최근 검색어
                        </p>
                        <ul v-if="recentList.length > 0" class="list recent-keyword">
                            <li v-for="(item, index) in recentList" :key="index">
                                <a @click="reSearch(item.word)">
                                    {{ item.word }}
                                </a>
                                <a @click="remove(item.id)">
                                    <span class="blind">삭제</span>
                                </a>
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <search-item :works="works" />
                    </template>
                </div>
            </article>
        </main>
        <filter-option
            :condition="condition"
            :managers="managers"
            :tags="tags"
            :register="register"
            @doSearch="doSearch"
        />
        <filter-register @setRegister="setRegister" />
        <filter-manager tp-id="search" @setManager="setManager" />
        <filter-tags tp-id="search" @setTags="setTags" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/SimpleWhiteHeader.vue';

import SearchItem from './SearchItem.vue';
import FilterOption from '../room/FilterOption.vue';
import FilterManager from '../room/FilterManager.vue';
import FilterRegister from '../room/FilterRegister.vue';
import FilterTags from '../room/FilterTags.vue';

import EventHelper from '../../helper/EventHelper';
import DataHelper from '../../helper/DataHelper';
import ArrayHelper from '../../helper/ArrayHelper';

import WorkCondition from '../../model/WorkCondition';
import MutationTypes from '../../store/MutationTypes';
import SearchwordApi from '../../api/SearchwordApi';

export default {
    name: 'Search',
    components: { Header, SearchItem, FilterOption, FilterManager, FilterRegister, FilterTags },
    data: () => {
        return {
            recentList: [],
            isSearch: false,
            keyword: '',
            condition: new WorkCondition(),
            tags: [],
            managers: [],
            register: {
                name: '선택'
            },
            roomId: 0 /* 0이면 전체 방에서 검색 */
        };
    },
    computed: {
        ...mapState({
            works: state => state.work.searchList,
            searchCondition: state => state.work.searchCondition
        })
    },
    created() {},
    mounted() {
        if (this.searchCondition) this.condition = new WorkCondition(DataHelper.cloneObject(this.searchCondition));

        this.recentlySearch();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getWorkOfSearch']),
        onFilter() {
            EventHelper.onFilter();
        },
        async recentlySearch() {
            const response = await SearchwordApi.list(6);
            this.recentList = response.content;
        },
        setManager(workers) {
            this.managers = workers;
            this.condition.managerIds = this.managers.map(worker => worker.id);
        },
        setTags(tags) {
            this.tags = tags;
            this.condition.tags = this.tags;
        },
        setRegister(register) {
            this.register = register;
            this.condition.registerId = this.register.id;
        },
        reSearch(word) {
            this.condition.content = word;
            this.doSearch();
        },
        async doSearch() {
            this.isSearch = true;
            this.condition.hideYn = 'N';
            this.condition.roomId = null;
            this.condition.rows = 20;
            this.$store.commit(MutationTypes.SET_SEARCH_CONDITION, this.condition.clonePayload());
            this.getWorkOfSearch(this.condition.getPayload());
            await SearchwordApi.regist({ word: this.condition.content });
            await this.recentlySearch();
            EventHelper.offFilter();
        },
        async remove(id) {
            await SearchwordApi.remove(id);
            const condition = item => item.id === id;
            ArrayHelper.oneRemove(this.recentList, condition);
        }
    }
};
</script>

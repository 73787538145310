import { profileUrl, profileThumbUrl, sProfileThumbUrl, thumbUrl, sThumbUrl } from '../../utils/image';

const VueImageFilter = {
    install(Vue) {
        Vue.filter('profileUrl', profileUrl);
        Vue.filter('profileThumbUrl', profileThumbUrl);
        Vue.filter('sProfileThumbUrl', sProfileThumbUrl);
        Vue.filter('thumbUrl', thumbUrl);
        Vue.filter('sThumbUrl', sThumbUrl);
    }
};

export default VueImageFilter;

<template>
    <div>
        <div class="login-top">
            <img src="@static/images/login/login-logo.png" alt="" />
        </div>
        <div class="login-form">
            <div class="field-wrap">
                <label for="login-phone" class="blind">휴대번호</label>
                <span class="field">
                    <input
                        ref="loginHp"
                        v-model="login.hp"
                        type="number"
                        class="txt"
                        placeholder="- 없이 휴대번호 입력 (01023456789)"
                    />
                </span>
            </div>

            <div class="field-wrap">
                <label for="login-password" class="blind">비밀번호</label>
                <span class="field">
                    <input v-model="login.passwd" type="password" class="txt" placeholder="비밀번호 입력" />
                </span>
            </div>

            <div class="btn-wrap">
                <a @click="processLogin"><span>로그인</span></a>
            </div>

            <div class="save-login">
                <input id="save-login-info" v-model="login.auto" type="checkbox" class="chk" />
                <label for="save-login-info">로그인 상태 유지</label>
            </div>

            <div class="find-password">
                <a @click="menuJoinSelect"><span>회원 가입 후 로그인하세요.</span></a>
                <a @click="menuFindPassword"><span>비밀번호를 잊으셨나요?</span></a>
            </div>

            <div class="login-footer">
                <p>Copyright © DAEWOO ENGINEERING & CONSTRUCTION Co., Ltd. All rights reserved.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoginApi from '../api/LoginApi';
import MenuHelper from '../helper/MenuHelper';
import MutationTypes from '../store/MutationTypes';
import MobileHelper from '../helper/MobileHelper';
import { alertDialog, exitDialog } from '../helper/DialogHelper';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../const';
import { getCookie, setCookie } from '../utils/cookie';
export default {
    name: 'Login',
    components: {},
    data: () => {
        return {
            login: { hp: '', passwd: '', auto: true }
        };
    },
    computed: {
        ...mapState({})
    },
    created() {
        this.logout();
    },
    mounted() {
        document.addEventListener('backbutton', this.backbtn, false);
        const loginID = getCookie('COCO_LOGIN_ID');
        this.login.hp = loginID;
    },
    beforeDestroy() {
        document.removeEventListener('backbutton', this.backbtn, false);
    },
    methods: {
        ...mapActions(['logout']),
        doLastAccess() {
            MobileHelper.doLastAccess();
        },
        backbtn() {
            //alert(this.$router.name);
            //exitDialog();
            if (this.$route.name === 'Login' || this.$route.name === 'Main') exitDialog();
            else this.$router.go(-1);
        },
        async processLogin() {
            try {
                if (!this.login.hp) {
                    alertDialog('휴대번호를 입력하세요');
                    this.$refs.loginHp.focus();
                    return;
                }
                setCookie('COCO_LOGIN_ID', this.login.hp);
                const res = await LoginApi.login(this.login);
                const session = res.content;
                if (session) {
                    const basic = {
                        csId: session.csId,
                        name: session.name,
                        position: session.postionName,
                        company: session.companyName,
                        companyId: session.companyId,
                        id: session.id,
                        hp: session.hp,
                        site: session.siteName,
                        imgUrl: session.imgUrl,
                        csAdminYn: session.csAdminYn,
                        csCompanyAdminYn: res.csCompanyAdminYn
                    };

                    const oauth = session.token || { token: null, refreshToken: null };
                    oauth.tempToken = session.tempToken;
                    this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                    //만약 csId가 없는 경우는 2개 이상인이고 이 경우 현장선택화면으로 이동해야 한다.

                    if (!session.csId) {
                        localStorage.setItem(CS_ID, null);
                        localStorage.setItem(WORKER_ID, session.id);
                        localStorage.setItem(JWT_TOKEN, oauth.tempToken);
                        this.$router.push({ name: 'SelectSite' });
                    } else {
                        localStorage.setItem(CS_ID, session.csId);
                        localStorage.setItem(WORKER_ID, session.id);
                        localStorage.setItem(JWT_TOKEN, oauth.token);
                        localStorage.setItem(JWT_REFRESH_TOKEN, oauth.refreshToken);
                        //this.$router.push({ name: 'SelectSite' });
                        this.doLastAccess();
                        this.$router.push({ name: 'Main' });
                    }

                    if (MobileHelper.isWebView()) {
                        const platform = MobileHelper.getPlatform();
                        if (platform) {
                            this.onNotification();
                        }
                    }
                }
            } catch (error) {
                if (error.response) {
                    alertDialog(error.response.data.msg);
                }
            }
        },
        onNotification() {
            try {
                MobileHelper.onDeviceReady();
            } catch (e) {
                console.error(e);
                // alert(JSON.stringify(e));
            }
        },
        menuFindPassword() {
            MenuHelper.findpasswd(this.$router);
        },
        menuJoinSelect() {
            MenuHelper.joinSelect(this.$router);
        }
    }
};
</script>

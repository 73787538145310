<template>
    <div>
        <header id="header" class="header primary">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="back()">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>사진편집</span>
                    </li>
                    <li class="etc">
                        <button type="button" class="btn-etc" @click="getPictureBlob">
                            <span>다음</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>

        <main id="content-wrap">
            <article id="contents">
                <div class="photo-area">
                    <tui-image-editor
                        ref="editor"
                        :include-ui="useDefaultUI"
                        :options="options"
                        @undoStackChanged="undoStackChanged"
                        @redoStackChanged="redoStackChanged"
                    />
                </div>
                <div class="photo-toolbar-1">
                    <ul>
                        <li>
                            <button type="button" class="btn-editor-write" @click="resetImageEditor()">
                                <span class="blind">그리기</span>
                            </button>
                        </li>
                        <li>
                            <button ref="btnUndo" type="button" class="btn-editor-undo" disabled @click="undo()">
                                <span class="blind">되돌리기</span>
                            </button>
                        </li>
                        <li>
                            <button ref="btnRedo" type="button" class="btn-editor-redo" disabled @click="redo()">
                                <span class="blind">되살리기</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-editor-refresh" @click="rotate()">
                                <span class="blind">로테이션</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="photo-toolbar-2">
                    <ul>
                        <li>
                            <button type="button" class="btn-color-1" @click="setBrushColor('#ffffff')">
                                <span class="blind">컬러1</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-color-2" @click="setBrushColor('#fcea1b')">
                                <span class="blind">컬러2</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-color-3" @click="setBrushColor('#f79700')">
                                <span class="blind">컬러3</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-color-4" @click="setBrushColor('#ea402f')">
                                <span class="blind">컬러4</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-color-5" @click="setBrushColor('#9728b2')">
                                <span class="blind">컬러5</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-color-6" @click="setBrushColor('#3e4aae')">
                                <span class="blind">컬러6</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-color-7" @click="setBrushColor('#3f97f6')">
                                <span class="blind">컬러7</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-color-8" @click="setBrushColor('#000000')">
                                <span class="blind">컬러8</span>
                            </button>
                        </li>
                    </ul>

                    <div class="range">
                        <span>Range</span>
                        <div class="rangebar" />
                    </div>
                </div>
            </article>
        </main>
        <input
            id="camera"
            ref="uploadCamera"
            type="file"
            accept="image/*"
            capture="camera"
            class="blind"
            @change="doCamera"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ImageEditor } from '@toast-ui/vue-image-editor';
require('webpack-jquery-ui/slider');
require('webpack-jquery-ui/css');
require('jquery-ui-touch-punch');
//import MenuHelper from '../../helper/MenuHelper';
//import MobileHelper from '../../helper/MobileHelper';
//import CameraHelper from '../../helper/CameraHelper';

//import FileApi from '../../api/FileApi';

//import { PICTURE_SORCE_TYPE } from '../../const';

//require('webpack-jquery-ui/touch-punch');

import FileApi from '../../api/FileApi';

export default {
    name: 'ImageEditor',
    components: { 'tui-image-editor': ImageEditor },
    props: {},
    data: () => {
        return {
            workId: 0,
            roomId: 0,
            color: '#000000',

            fileObj: {
                fileNo: null,
                name: '',
                type: 'blob',
                dataUrl: null,
                data: null
            },

            useDefaultUI: false,
            options: {
                // for tui-image-editor component's "options" prop
                usageStatistics: false,
                cssMaxWidth: 1200,
                cssMaxHeight: 1200
                //cssMaxWidth: document.documentElement.clientWidth,
                //cssMaxHeight: document.documentElement.clientHeight
            },
            MAX_RESOLUTION: 3264 * 2448 * 2,
            supportingFileAPI: !!(window.File && window.FileList && window.FileReader),
            rImageType: /data:(image\/.+);base64,/,
            shapeOpt: {
                fill: '#fff',
                stroke: '#000',
                strokeWidth: 30
            }
        };
    },
    computed: {
        ...mapState({
            work: state => state.work.picture
        })
    },
    created() {},
    mounted() {
        //if (this.$route.name === 'ImageEditor') this.roomId = Number(this.$route.params.roomId);
        //else this.workId = Number(this.$route.params.workId);
        document.body.classList = 'off photo-editor';
        this.resetImageEditor();
        /*
        if (MobileHelper.isWebView()) {
            CameraHelper.getPicture(PICTURE_SORCE_TYPE.CAMERA, this.cameraImage, () => {
                this.$router.go(-1);
            });
        } else {
            document.getElementById('camera').click();
        }
        */

        //this.$nextTick(() => {
        //    this.setDataUrlImage(this.fileObj);
        //});

        setTimeout(() => {
            $('.rangebar').slider({
                range: 'min',
                min: 1,
                max: 100,
                value: 10
            });

            $('.rangebar').on('slidestop', (event, ui) => {
                this.setBrushWidthRange(ui.value);
            });
        }, 200);
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            //this.$router.go(-1);
            this.$emit('close');
        },
        /* deprecated 2020-10-24 */
        cameraImage(fileUrl) {
            this.$refs.editor.invoke('loadImageFromURL', fileUrl, 'FilterImage');
        },
        /* deprecated 2020-10-24 */
        selectImage() {
            const file = this.$refs.uploadCamera.files[0];
            this.$refs.editor.invoke('loadImageFromFile', file);
        },

        setUrlImage(url) {
            this.$refs.editor.invoke('loadImageFromURL', url, 'FilterImage');
        },
        setDataUrlImage(file) {
            this.fileObj = file;
            this.$refs.editor.invoke('loadImageFromURL', file.dataUrl, 'FilterImage');
        },
        async setServerUrl(file) {
            this.fileObj = file;
            //this.$refs.editor.invoke('loadImageFromURL', file.dataUrl, 'FilterImage');

            const res = await FileApi.urlTobase64(file.fileId, file.fileNo);
            this.$refs.editor.invoke('loadImageFromURL', 'data:image/jpeg;base64,' + res.content, 'FilterImage');

            /*
            this.getBase64Image(
                file.dataUrl,
                res => {
                    this.$refs.editor.invoke('loadImageFromURL', res, 'FilterImage');
                },
                e => {
                    alert(e);
                }
            );
            */
            /*, base64image => {
                console.log(base64image);
                this.$refs.editor.invoke('loadImageFromURL', base64image, 'FilterImage');
            });
            */
        },
        setFileImage(file) {
            //const file = this.$refs.uploadCamera.files[0];
            this.fileObj = file;
            this.$refs.editor.invoke('loadImageFromFile', file.data);
        },

        hexToRGBa(hex, alpha) {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);
            const a = alpha || 1;
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
        },
        base64ToBlob(data) {
            let mimeString = '';
            let raw, uInt8Array, i;

            raw = data.replace(this.rImageType, function(header, imageType) {
                mimeString = imageType;
                return '';
            });

            raw = atob(raw);
            const rawLength = raw.length;
            uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
            for (i = 0; i < rawLength; i += 1) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], { type: mimeString });
        },
        resetImageEditor() {
            const settings = this.getBrushSettings();
            this.$refs.editor.invoke('stopDrawingMode');
            this.$refs.editor.invoke('startDrawingMode', 'FREE_DRAWING', settings);
        },
        getBrushSettings() {
            const brushWidth = 10; //parseInt($inputBrushWidthRange.val(), 10);
            const brushColor = '#000000'; //brushColorpicker.getColor();

            return {
                width: brushWidth,
                color: this.hexToRGBa(brushColor, 0.5)
            };
        },
        setBrushWidthRange(value) {
            this.$refs.editor.invoke('setBrush', {
                width: parseInt(value, 10)
            });
        },
        setBrushColor(color) {
            this.$refs.editor.invoke('setBrush', {
                color: this.hexToRGBa(color, 0.5)
            });
        },
        undo() {
            this.$refs.editor.invoke('undo');
        },
        redo() {
            this.$refs.editor.invoke('redo');
        },
        undoStackChanged(length) {
            if (length > 1) {
                this.$refs.btnUndo.disabled = false;
            } else {
                this.$refs.btnUndo.disabled = true;
            }
        },
        redoStackChanged(length) {
            if (length) {
                this.$refs.btnRedo.disabled = false;
            } else {
                this.$refs.btnRedo.disabled = true;
            }
        },
        rotate() {
            this.$refs.editor.invoke('rotate', 90);
        },
        getPictureBlob() {
            //this.$store.dispatch('showLoading');
            let imageName = this.$refs.editor.invoke('getImageName');
            const dataURL = this.$refs.editor.invoke('toDataURL');
            const blob = this.base64ToBlob(dataURL);
            const type = blob.type.split('/')[1];
            if (imageName.split('.').pop() !== type) {
                //imageName += '.' + type;
                const now = new Date();
                imageName = now.getTime() + '.' + type;
            }
            //return formData;
            this.fileObj.name = imageName;
            this.fileObj.type = 'blob';
            this.fileObj.dataUrl = dataURL;
            this.fileObj.data = blob;

            //this.$router.go(-1);
            this.back();
            //return this.fileObj;
        },
        onColor(color) {
            if (this.color === color) return 'on';
            else return '';
        },
        /* deprecated 2020-10-24 */
        doCamera() {
            this.selectImage();
        },
        convert(url, callback) {
            var img = new Image();
            img.onload = function() {
                callback(img);
            };
            img.setAttribute('crossorigin', 'anonymous');
            img.src = url;
        },
        getBase64Image(url, callback) {
            this.convert(url, function(newImg) {
                var canvas = document.createElement('canvas');
                canvas.width = newImg.width;
                canvas.height = newImg.height;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(newImg, 0, 0);
                var base64 = canvas.toDataURL('image/png');
                callback(base64);
            });
        },

        encodeBase64ImageTagviaFileReader(url) {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    const reader = new FileReader();
                    reader.onloadend = function() {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            });
        }
    }
};
</script>

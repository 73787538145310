<template>
    <div v-show="isLoading" class="loading-wrap">
        <img id="coco-loding" src="/images/loading.svg" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Loading',
    components: {},
    data: () => {
        return {
            keyword: ''
        };
    },
    computed: {
        ...mapState({
            isLoading: state => state.main.isLoading
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([])
    }
};
</script>

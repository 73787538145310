<template>
    <div>
        <Header :loc-nm="roomName + ' 관리'" loc-type="list" btn-name="멤버추가" :fn-confirm="menuAddWorker" />
        <article id="content-wrap">
            <main id="contents">
                <div class="contents-body">
                    <ul class="group-list">
                        <li>
                            <p class="bangjang-comment">
                                방장의 경우 방장 권한을 다른 멤버에게 이전할 수 있습니다. <br />
                                권한을 이전할 멤버 목록을 2초간 꾹 눌러주세요. 곧장 뜨는 권한 이전 창에서 [확인] 버튼을 선택하면 방장 변경이 완료됩니다.
                            </p>
                        </li>
                        <li
                            v-for="(worker, index) in workers"
                            :key="index"
                            v-touch:touchhold="roomMasterModify(worker)"
                        >
                            <div class="member-info-box">
                                <div class="profile">
                                    <div class="short-nm">
                                        <img
                                            :src="worker.imgUrl | profileUrl"
                                            onerror="javascript:this.src='/images/common/default.png"
                                        />
                                    </div>
                                </div>
                                <div class="member-info">
                                    <p class="nm">
                                        {{ worker.name }}
                                        <span v-if="room.master.id === worker.id" class="owner">방장</span>
                                    </p>
                                    <div class="meta-info">
                                        <span>{{ worker.companyName }}</span>
                                        <span>{{ worker.regDt | formatTime }}</span>
                                    </div>
                                </div>
                                <div class="phone">
                                    <a @click="callhp(worker.hp)"><span class="blind">전화</span></a>
                                </div>
                                <div
                                    v-if="
                                        (room.master.id === basic.id &&
                                            room.master.id !== worker.id &&
                                            basic.id !== worker.id) ||
                                            workers.length === 1
                                    "
                                    class="trash"
                                >
                                    <a class="btn-delete" @click="remove(worker.id)"><span class="blind">삭제</span></a>
                                </div>
                                <div v-if="room.master.id === basic.id" class="owner">
                                    <a class="btn-delete" @click="openOwnerNoti">
                                        <span class="blind">방장표시 변환</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="room.master.id === basic.id" class="btn-wrap fixed">
                    <a class="btn-del-group" role="button" @click="roomModify()"><span>그룹명 변경하기</span></a>
                </div>
                <div v-else class="btn-wrap fixed">
                    <a class="btn-del-group" role="button" @click="roomExit()"><span>그룹 나가기</span></a>
                </div>
            </main>
        </article>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '../header/RegistWhiteHeader.vue';
import WorkRoomApi from '../../api/WorkRoomApi';

import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';
import WorkerApi from '../../api/WorkerApi';
import MenuHelper from '../../helper/MenuHelper';
import ArrayHelper from '../../helper/ArrayHelper';
import { alertDialog, confirmDialog } from '../../helper/DialogHelper';

export default {
    name: 'RoomWorker',
    components: { Header },
    data: () => {
        return {
            roomId: 0,
            roomName: '',
            workers: [],
            room: { master: { id: 0 } }
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.roomId = Number(this.$route.params.roomId);
        WorkRoomApi.detail(this.roomId).then(res => {
            this.roomName = res.name;
            this.room = res;
        });
        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        async doSearch() {
            const res = await WorkerApi.listOfroom(this.roomId);
            //const condition = item => item.id === this.basic.id;

            //ArrayHelper.oneRemove(res, condition);
            //wokers 에서 filter regId, name, 직위만 가진다.
            this.workers = res.map(item => {
                const worker = {};
                worker.id = item.id;
                worker.name = item.displayName;
                worker.positionName = item.positionName;
                worker.companyName = item.company.name;
                worker.hp = item.hp;
                worker.firstName = item.name.substring(0, 1);
                worker.imgUrl = item.imgUrl;
                worker.regDt = item.regDt;
                return worker;
            });
        },
        menuAddWorker() {
            MenuHelper.addWorker(this.$router, this.roomId);
            //this.$router.replace({ path: '/addWorker/' + this.roomId });
        },
        async remove(id) {
            const res = await WorkRoomJoinApi.remove(this.roomId, id);
            if (res) {
                const condition = item => item.id === id;
                ArrayHelper.oneRemove(this.workers, condition);
            }
        },
        async roomExit() {
            confirmDialog('정말 그룹에서 나가시겠습니까?', async () => {
                const res = await WorkRoomJoinApi.remove(this.roomId, this.basic.id);
                if (res) {
                    this.$router.push({ path: '/main' });
                }
            });
        },
        roomModify() {
            MenuHelper.modifyRoom(this.$router, this.roomId);
        },
        roomMasterModify(param) {
            return () => {
                if (this.room.master.id === this.basic.id && this.basic.id !== param.id) {
                    confirmDialog(param.name + '에게\n방장을 이전하겠습니까?', async () => {
                        this.room.master.id = param.id;
                        const res = await WorkRoomApi.modify(this.room.id, this.room);
                        if (res) {
                            MenuHelper.roomNoHistory(this.$router, this.room.id);
                        }
                    });
                }
            };
        },
        callhp(hp) {
            if (hp) window.open('tel:' + hp, '_system');
        },
        openOwnerNoti() {
            alertDialog('방장으로 변경할 멤버를 선택한 후 손으로 길게 누르시면 방장으로 변경됩니다.');
        }
    }
};
</script>

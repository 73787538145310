<template>
    <div>
        <Header :loc-nm="heaerTitle" :work="headerData" @update:finishYn="val => (work.finishYn = val)" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="list cont-detail">
                        <li>
                            <section class="round-section">
                                <div class="in-section task-box">
                                    <div v-if="imgs.length > 0" class="task-thumb">
                                        <div class="swiper-container">
                                            <swiper :options="swiperOption">
                                                <swiper-slide
                                                    v-for="(img, index) in imgs"
                                                    :key="index"
                                                    class="swiper-slide"
                                                >
                                                    <a @click="closeUtilBox">
                                                        <div class="thumb-slider">
                                                            <div class="thumb-inner">
                                                                <img
                                                                    v-gallery="'workImage'"
                                                                    :data-index="index"
                                                                    :data-large="img.url"
                                                                    :src="img.url | thumbUrl"
                                                                />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </swiper-slide>
                                                <div slot="pagination" class="swiper-pagination" />
                                            </swiper>
                                        </div>
                                    </div>

                                    <div class="task-info-box">
                                        <div class="task-info-top-box">
                                            <div class="task-user-info">
                                                <div class="task-type">
                                                    <span :class="taskType()">{{ taskName() }}</span>
                                                </div>
                                                <a @click="sendHp(work.hp)">
                                                    {{ work.name + ' ' + work.positionName }}&middot;
                                                    {{ work.companyName }}
                                                </a>
                                                <div class="more-util">
                                                    <button class="btn-more-util" @click="utilMore">
                                                        <span class="blind">툴 더보기</span>
                                                    </button>
                                                </div>
                                                <more-util
                                                    v-if="isMore"
                                                    :item="work"
                                                    :imgs="imgs"
                                                    more-type="work"
                                                    @moreClose="() => (isMore = false)"
                                                />
                                            </div>
                                            <p
                                                class="task-memo"
                                                :inner-html.prop="convertCrToBr(work.content) | withLinks"
                                                @click="closeUtilBox()"
                                            />
                                        </div>
                                        <charge-box v-if="work.type === 'work'" :users="manager" />
                                        <tag-box :tags="tags" />
                                        <!-- 20200527 첨부파일 추가내용 -->
                                        <div v-if="docFileList.length > 0" class="task-info-btm-box">
                                            <dl>
                                                <dt><span class="tit-files">첨부</span></dt>
                                                <dd>
                                                    <div>
                                                        <a
                                                            v-for="(file, index) in docFileList"
                                                            :key="index"
                                                            @click="download(file)"
                                                        >
                                                            <span class="files">{{ file.name }}</span>
                                                        </a>
                                                    </div>
                                                </dd>
                                            </dl>
                                        </div>
                                        <!-- 20200527 첨부파일 추가내용 //-->
                                    </div>
                                </div>
                                <div class="meta-info" @click="closeUtilBox()">
                                    <span class="datetime">{{ work.regDt | longFormatTime }}</span>
                                    <span class="comment-cnt" @click="menuComment(work.id, work.commentId)">
                                        담당확인 {{ managerViewCount }}
                                        <span class="m-divide" />
                                        댓글 {{ work.countOfComment }}
                                    </span>
                                </div>
                            </section>
                        </li>
                        <template v-if="actions.length > 0">
                            <li v-for="(action, index) in actions" :key="index">
                                <action-item
                                    :item="action"
                                    :work="work"
                                    :select-id="selectId"
                                    @removeAction="removeAction"
                                    @update:selectId="val => (selectId = val)"
                                    @moreClose="closeUtilBox"
                                />
                            </li>
                        </template>
                    </ul>
                    <div id="focus" />
                    <div v-if="work.type !== 'work'" class="comment-alrim-box">
                        <div @click="closeUtilBox()">
                            댓글 쓰기 아이콘을 누르고 <br />
                            현장 사진과 토크에 대한 대화를 나눠보세요.
                        </div>
                    </div>
                </div>
            </article>
        </main>
        <div v-if="isDone()" class="repair-done" @click="closeUtilBox()">
            <p>보고 계신 업무는 완료 처리 되었습니다.<br />그룹에서 새 업무나 사진을 등록하세요.</p>
        </div>

        <div
            v-if="work.type === 'work' && work.finishYn === 'N' && isManager()"
            class="comment-write"
            :class="workWriteClass"
            @click="closeUtilBox()"
        >
            <div v-if="show.photoUtil" class="photo-util">
                <input
                    id="gallery"
                    ref="uploadGallery"
                    type="file"
                    class="blind"
                    accept="image/*"
                    @change="doGalleryAttach"
                />

                <button class="btn-camera" @click="cameraPicker">
                    <span class="blind">카메라</span>
                </button>
                <button class="btn-gallery" @click="imagePicker">
                    <span class="blind">갤러리</span>
                </button>
            </div>
            <div class="field-wrap comment-wide">
                <label for="comment-memo" class="blind">조치등록</label>
                <textarea
                    id="comment-memo"
                    ref="commentMemo"
                    v-model="writeData.content"
                    onkeyup="this.style.height='38px'; this.style.height = this.scrollHeight + 'px';"
                    placeholder="조치를 등록하세요."
                    @input="ingContent"
                />
                <button type="button" class="btn-comment-done" @click="registAction">
                    <span class="blind">완료</span>
                </button>
                <!-- 20200527 첨부파일 추가내용 v-if="show.mentionHashList" ref="commentfile" 는 참고로 넣었음 -->
                <a v-if="show.mentionHashList" ref="commentfile" class="comment-file wide" @click="pickerFile">
                    <span>+</span>
                </a>
                <!-- 20200527 첨부파일 추가내용 //-->
                <input
                    id="docFile"
                    ref="docFile"
                    type="file"
                    :accept="DOC_ALLOW_FILE"
                    class="blind"
                    @change="doDocFileAttach"
                />
            </div>
            <div v-if="show.mentionHashList && addFileList.length > 0" class="mention-hash-list">
                <ul class="file">
                    <li v-for="(fileInfo, index) in addFileList" :key="index">
                        {{ fileInfo.name }}<button class="btn_remove" @click="onClickFileDel(fileInfo)" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
/* components */
import Header from '../header/WorkHeader.vue';
import TagBox from './TagBox.vue';
import ChargeBox from './ChargeBox.vue';
import MoreUtil from './MoreUtil.vue';
import ActionItem from './ActionItem.vue';

/* API */
import WorkApi from '../../api/WorkApi';
import WorkTagApi from '../../api/WorkTagApi';
import WorkManagerApi from '../../api/WorkManagerApi';
import WorkActionApi from '../../api/WorkActionApi';
import FileApi from '../../api/FileApi';

import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';
import WorkModel from '../../model/WorkModel';

import MenuHelper from '../../helper/MenuHelper';
import ArrayHelper from '../../helper/ArrayHelper';
//import AuthHelper from '../../helper/AuthHelper';

import MobileHelper from '../../helper/MobileHelper';
import ImagePickerHelper from '../../helper/ImagePickerHelper';

import { convertCrToBr } from '../../utils/string';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

//import { DOC_ALLOW_FILE } from '../../const';
import FileHelper from '../../helper/FileHelper';

import CameraHelper from '../../helper/CameraHelper';

import { PICTURE_SORCE_TYPE, MAX_IMG_COUNT } from '../../const';

//import { removeEmoji } from '../../utils/string';

export default {
    name: 'WorkDetail',
    components: { Header, TagBox, MoreUtil, ChargeBox, ActionItem, swiper, swiperSlide },
    props: {},
    data: () => {
        return {
            isMore: false,
            id: null,
            heaerTitle: '작업 상세',
            selectId: 0,
            work: {
                id: 0,
                register: { hp: '', id: null },
                regId: null,
                content: '',
                finishYn: 'N'
            },
            tags: [],
            manager: [],
            actions: [],
            imgs: [],
            swiperOption: {
                direction: 'horizontal',
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                }
            },
            writeData: {
                room: {},
                work: {},
                content: '',
                managers: [],
                tags: [],
                all: []
            },
            workWriteClass: '',
            show: {
                mentionHashList: false,
                photoUtil: true,
                comment: false
            },
            headerData: {
                id: 0,
                isFinish: false,
                regId: 0
            },
            docFileList: [],
            addFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            docfileId: 0,
            docfileNo: 0,
            managerViewCount: 0
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        $('html').scrollTop(0);
        this.id = this.$route.params.id;
        this.getDetail();
    },
    beforeDestroy() {
        //document.removeEventListener('touchend', this.listnerBody, true);
    },
    methods: {
        ...mapActions(['getWork', 'getCount', 'showLoading', 'hideLoading']),
        pickerFile() {
            if (FileHelper.isCount(this.addFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    ++this.docfileNo;
                    const formData = new FormData();
                    formData.append('file_id', '');
                    formData.append('file_no', this.docfileNo);
                    formData.append('file', fileObject);
                    const addFile = {
                        fileNo: this.docfileNo,
                        name: fileObject.name,
                        form: formData
                    };
                    this.addFileList.push(addFile);
                }
            }
        },
        onClickFileDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        closeUtilBox() {
            this.isMore = false;
        },
        sendHp(hp) {
            this.isMore = false;
            if (hp) window.open('tel:' + hp, '_system');
        },
        menuComment(id, commentId) {
            MenuHelper.commentOfwork(this.$router, id, commentId || 0);
        },
        back() {
            //this.$router.go(-1);
        },
        removeAction(id) {
            const condition = item => item.id === id;
            ArrayHelper.oneRemove(this.actions, condition);
        },
        getDetail() {
            if (this.id) {
                WorkApi.detail(this.id).then(res => {
                    if (!res) return;
                    this.work = new WorkModel(res.content);
                    this.headerData.id = this.work.id;
                    this.headerData.isFinish = res.content.finishYn === 'Y';
                    this.headerData.regId = res.content.register.id;

                    this.heaerTitle = WORK_TYPE[this.work.type] + ' 상세';
                    if (this.work.type === 'work') {
                        WorkActionApi.actionOfworkId(this.id).then(result => {
                            this.actions = result.content;
                        });

                        WorkManagerApi.workOfManagerList(this.id).then(res => {
                            this.manager = res.content;

                            for (var item of this.manager) {
                                if (item.viewDt) this.managerViewCount += 1;
                            }

                            if (this.isManager())
                                WorkManagerApi.updateViewCount(this.work.id, this.basic.id).then(res => {
                                    //console.log(res);
                                    if (res && res.content.viewDt) this.managerViewCount += 1;
                                });
                        });
                    }
                    if (this.work.fileId) {
                        FileApi.list(this.work.fileId).then(res => {
                            this.imgs = res;
                        });
                    }
                    if (this.work.docFileId) {
                        FileApi.list(this.work.docFileId).then(res => {
                            this.docFileList = res;
                        });
                    }
                });

                WorkTagApi.tagsOfwork(this.id).then(res => {
                    this.tags = res.content;
                });
            }
        },
        taskName() {
            return WORK_TYPE[this.work.type];
        },
        taskType() {
            return WORK_TYPE_CSS[this.work.type];
        },
        isDone() {
            return this.work.type === 'work' && this.work.finishYn === 'Y';
        },
        async registAction() {
            if (this.writeData.content && this.writeData.content.trim()) {
                this.docfileNo = 0;
                this.showLoading();
                if (this.addFileList.length > 0 && MobileHelper.checkUpload()) {
                    for (var docFile of this.addFileList) {
                        const docFileForm = docFile.form;
                        if (this.docFileId > 0) docFileForm.set('file_id', this.docFileId);
                        docFileForm.set('file_no', ++this.docfileNo);
                        const fileRes = await FileApi.fileUpload(docFileForm, 'doc');
                        if (fileRes.content) {
                            this.docFileId = fileRes.content[0].id.fileId;
                            this.writeData.docFileId = this.docFileId;
                        }
                    }
                }
                this.docFileId = 0;
                this.docfileNo = 0;
                this.addFileList = [];

                this.writeData.work.id = this.id;
                const response = await WorkActionApi.regist(this.writeData);
                this.hideLoading();
                if (response) {
                    await this.getDetail();
                    setTimeout(() => {
                        $('html, body').scrollTop($(document).height());
                    }, 300);
                }

                this.writeData.content = '';
                this.writeData.docFileId = null;
                this.ingContent();
                this.getWork();
                this.getCount();
            }

            //$('html, body').scrollTop($(document).height());
        },
        doGalleryAttach() {
            this.closeUtilBox();
        },
        cameraPicker() {
            if (MobileHelper.isWebView()) {
                CameraHelper.getPicture(PICTURE_SORCE_TYPE.CAMERA, this.cameraImage, () => {
                    //this.$router.go(-1);
                });
            }
        },
        cameraImage(fileUrl) {
            const now = new Date();
            /* camera는 jpg라 별 상관이 없겠군 */
            const imageName = now.getTime() + '.jpg';
            const blob = CameraHelper.base64ToBlob(fileUrl);
            const addFile = {
                fileNo: 1,
                name: imageName,
                type: 'blob',
                dataUrl: fileUrl,
                data: blob
            };
            const imgList = [];
            imgList.push(addFile);
            //MenuHelper.addWork(this.$router, this.roomId, imgList);
            MenuHelper.addWorkAction(this.$router, this.id, imgList);
        },
        utilMore() {
            if (this.isMore) this.isMore = false;
            else this.isMore = true;
            this.selectId = 0;
        },
        ingContent() {
            if (this.writeData.content > '') {
                this.show.mentionHashList = true;
                this.show.photoUtil = false;
                this.workWriteClass = 'mention-hash mention-hash-done';
            } else {
                this.show.mentionHashList = false;
                this.show.photoUtil = true;
                this.workWriteClass = '';
            }
            this.$refs.commentMemo.focus();
        },
        convertCrToBr,
        isManager() {
            const regId = this.basic.id;
            if (this.headerData.regId === regId) return true;
            else if (this.manager.find(manager => manager.manager.id === regId)) return true;
            else false;
        },
        imagePicker() {
            this.closeUtilBox();
            if (MobileHelper.isWebView()) {
                ImagePickerHelper.getPictures(this.doImagePickerAttach, MAX_IMG_COUNT);
            } else {
                //document.getElementById('gallery').click();
            }
        },
        async doImagePickerAttach(fileUrlList) {
            this.showLoading();
            this.closeUtilBox();
            let fileNo = 0;
            const imgList = [];
            for (var fileUrl of fileUrlList) {
                const blob = CameraHelper.base64ToBlob(fileUrl);
                const now = new Date();
                const imageName = now.getTime() + '.jpg';
                const addFile = {
                    fileNo: ++fileNo,
                    name: imageName,
                    type: 'blob',
                    dataUrl: fileUrl,
                    data: blob
                };
                imgList.push(addFile);
            }
            MenuHelper.addWorkAction(this.$router, this.id, imgList);
        },
        async download(file) {
            if (MobileHelper.checkDownload()) {
                this.showLoading();
                if (MobileHelper.isWebView()) {
                    await FileApi.downloadData(file.id);
                } else await FileApi.fileDownload(file.id);
                this.hideLoading();
            }
            /*
            confirmDialog('파일을 다운로드 하시겠습니까?', async () => {
                this.showLoading();
                if (MobileHelper.isWebView()) {
                    await FileApi.downloadData(file.id);
                } else await FileApi.fileDownload(file.id);
                this.hideLoading();
            });
            */
        }
    }
};
</script>

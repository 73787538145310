<template>
    <div>
        <Header loc-nm="댓글" />
        <!-- welcome-area -->
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <section v-if="comments.content.length < 1" class="comment-list-wrap">
                        <ul>
                            <li>
                                <div class="profile-thumb">
                                    <span class="thumb" />
                                </div>
                                <div class="comment-memo" />
                                <div class="comment-box">
                                    <div class="comment-memo">
                                        <p>첫번째 댓글을 등록해보세요!</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                    <section v-else class="comment-list-wrap">
                        <ul>
                            <li v-for="(comment, index) in comments.content" :key="index">
                                <div class="profile">
                                    <div class="short-nm">
                                        <img
                                            :src="comment.register.imgUrl | profileUrl"
                                            onerror="javascript:this.src='/images/common/default.png"
                                        />
                                    </div>
                                </div>
                                <div class="comment-box">
                                    <div class="comment-memo">
                                        <div class="user-info">
                                            {{ getDisplayName(comment.register) }}&middot;{{
                                                comment.register.company.name
                                            }}
                                        </div>
                                        <p :inner-html.prop="convertCrToBr(comment.content) | withLinks" />
                                    </div>
                                    <div class="meta-info">
                                        <span class="datetime">
                                            {{ comment.regDt | longFormatTime }}
                                        </span>
                                        <a
                                            v-if="basic.id === comment.register.id"
                                            class="btn-del-comment"
                                            @click="removeComment(comment.id)"
                                        >
                                            삭제
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="view-more-wrap">
                            <a v-if="comments.isMore" class="view-more" @click="more()"><span>더보기</span></a>
                        </div>
                    </section>
                    <div class="comment-write">
                        <div class="field-wrap">
                            <label for="comment-memo" class="blind">댓글 내용</label>
                            <textarea
                                id="comment-memo"
                                v-model="content"
                                placeholder="댓글을 입력하세요."
                                maxlength="254"
                            />
                            <button type="button" class="btn-comment-done" @click="registComment()">
                                <span class="blind">완료</span>
                            </button>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '../header/SimpleHeader.vue';
import PageModel from '../../model/PageModel';

import CommentApi from '../../api/CommentApi';
import WorkApi from '../../api/WorkApi';
import WorkActionApi from '../../api/WorkActionApi';

import { convertCrToBr } from '../../utils/string';

export default {
    name: 'CommentList',
    components: { Header },
    data: () => {
        return {
            id: 0,
            type: 'commentOfwork',
            commentId: 0,
            comments: new PageModel(),
            content: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        $('html').scrollTop(0);
        this.id = this.$route.params.id;
        this.commentId = this.$route.params.commentId;
        this.type = this.$route.name;
    },
    mounted() {
        this.id = this.$route.params.id;
        this.commentId = this.$route.params.commentId;
        this.type = this.$route.name;
        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        async registComment() {
            if (this.content.trim() === '') {
                this.content = '';
                return;
            }

            const comment = {
                //id: this.id,
                commentId: this.commentId,
                content: this.content
            };
            const response = await CommentApi.regist(comment);
            if (response) {
                this.comments.addItem(response.content);
                this.content = '';
                if (this.type === 'commentOfwork') {
                    await WorkApi.comment(this.id, { id: this.id, commentId: response.content.commentId });
                } else {
                    await WorkActionApi.comment(this.id, { id: this.id, commentId: response.content.commentId });
                }
                this.commentId = response.content.commentId;
            }
        },
        async doSearch() {
            const query = {
                ...this.comments.query()
            };

            if (this.commentId > 0) {
                const response = await CommentApi.list(this.commentId, query);
                if (response) {
                    //this.boards = new PageModel(response);
                    this.comments.bind(response, true);
                }
            }
        },
        more() {
            ++this.comments.page;
            this.doSearch();
        },
        getFirstName(register) {
            return register.name.substring(0, 1);
        },
        getDisplayName(register) {
            return register.name + ' ' + register.positionName;
        },
        async removeComment(id) {
            const response = await CommentApi.remove(id);
            if (response) {
                this.comments.removeItem(id);
            }
        },
        convertCrToBr
    }
};
</script>

//import router from '../router';
// 이렇게 이요하니까 중복오류가 발생함.
export default {
    exit: () => {
        if (window.cordova) window.cordova.plugins.exit();
        else window.close();
    },

    login: router => {
        router.push({ path: `/login` });
    },
    findpasswd: router => {
        router.push({ path: `/findpasswd` });
    },
    search: router => {
        router.push({ path: `/search` });
    },
    alarm: router => {
        router.push({ path: `/alarm` });
    },
    notice: (router, id) => {
        if (id) router.push({ path: `/notice/${id}` });
        else router.push({ path: `/notice` });
    },
    noticeRegist: (router, id) => {
        if (id) router.push({ path: `/notice/regist/${id}` });
        else router.push({ path: `/notice/regist` });
    },
    daily: (router, id) => {
        if (id) router.push({ path: `/daily/${id}` });
        else router.push({ path: `/daily` });
    },
    dailyRegist: (router, id) => {
        if (id) router.push({ path: `/daily/regist/${id}` });
        else router.push({ path: `/daily/regist` });
    },
    qna: (router, id) => {
        if (id) router.push({ path: `/qna/${id}` });
        else router.push({ path: `/qna` });
    },
    qnaRegist: (router, id) => {
        if (id) router.push({ path: `/qna/regist/${id}` });
        else router.push({ path: `/qna/regist` });
    },
    policy: router => {
        router.push({ path: `/policy` });
    },
    privacy: router => {
        router.push({ path: `/privacy` });
    },
    mypage: router => {
        router.push({ path: `/mypage` });
    },
    mypageNoHistory: router => {
        router.replace({ path: `/mypage` });
    },
    passwd: router => {
        router.push({ path: `/passwd` });
    },
    registRoom: router => {
        router.push({ path: `/room/regist` });
    },
    registRoomNoHistory: router => {
        router.replace({ path: `/room/regist` });
    },
    modifyRoom: (router, id) => {
        router.push({ path: `/room/modify/${id}` });
    },
    modifyRoomNoHistory: (router, id) => {
        router.replace({ path: `/room/modify/${id}` });
    },
    room: (router, id) => {
        router.push({ path: `/room/${id}` });
    },
    roomNoHistory: (router, id) => {
        router.replace({ path: `/room/${id}` });
    },
    work: (router, id) => {
        router.push({ path: `/work/${id}` });
    },
    addWorker: (router, id) => {
        router.push({ path: `/addWorker/${id}` });
    },
    addWorkerNoHistory: (router, id) => {
        router.replace({ path: `/addWorker/${id}` });
    },
    workerOfroom: (router, id) => {
        router.push({ path: `/workerOfroom/${id}` });
    },
    workerOfroomNoHistory: (router, id) => {
        router.replace({ path: `/workerOfroom/${id}` });
    },
    setting: router => {
        router.push({ path: `/setting` });
    },
    comment: (router, id) => {
        router.push({ path: `/comment/${id}` });
    },
    commentOfwork: (router, id, commentId) => {
        router.push({ path: `/commentOfwork/${id}/${commentId}` });
    },
    commentOfaction: (router, id, commentId) => {
        router.push({ path: `/commentOfaction/${id}/${commentId}` });
    },
    addWork: (router, roomId, tempImageList) => {
        router.push({ name: 'AddWork', params: { roomId, tempImageList } });
    },
    addWorkNohistory: (router, roomId, tempImageList) => {
        //router.replace({ path: `/addwork/${roomId}` });
        router.replace({ name: 'AddWork', params: { roomId, tempImageList } });
    },
    addWorkAction: (router, workId, tempImageList) => {
        //router.push({ path: `/addWorkAction/${workId}/${fileId}` });
        router.push({ name: 'AddWorkAction', params: { workId, tempImageList } });
    },
    addWorkActionNohistory: (router, workId, fileId) => {
        router.replace({ path: `/addWorkAction/${workId}/${fileId}` });
    },
    approveWorker: router => {
        router.push({ path: `/approveWorker` });
    },
    workDaily: (router, id) => {
        if (id) router.push({ path: `/workDaily/${id}` });
        else router.push({ path: `/workDaily` });
    },
    workDailyRegist: (router, id) => {
        if (id) router.push({ path: `/workDaily/regist/${id}` });
        else router.push({ path: `/workDaily/regist` });
    },
    joinSelect: router => {
        router.push({ path: `/join/select` });
    },
    join: (router, csId) => {
        router.push({ path: `/join/step1/${csId}` });
    },
    modifyWorkNohistory: (router, roomId, id) => {
        router.replace({ path: `/modifyWork/${roomId}/${id}` });
    },
    pictureRoom: router => {
        router.push({ path: `/pictureRoom` });
    },
    imageEdit: (router, roomId, fileObj) => {
        router.push({ name: 'ImageEditor', params: { roomId, fileObj } });
    },
    shareRoom: (router, workId) => {
        router.push({ path: `/shareRoom/${workId}` });
    }
};

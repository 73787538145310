<template>
    <header id="header" class="header primary">
        <div class="in-section blue">
            <ul>
                <li class="draw-nav">
                    <button type="button" class="btn-draw" @click="showNav()">
                        <span class="blind">메뉴</span>
                    </button>
                </li>
                <li class="loc-nm select-box" @click="goSelectSite">
                    <div class="select-inbox">
                        <span>{{ basic.site }}</span>
                    </div>
                </li>
                <li class="search">
                    <button type="button" class="btn-search" @click="goSearch()">
                        <span class="blind">검색</span>
                    </button>
                </li>
                <li class="notify">
                    <button type="button" :class="onAlarm()" @click="goAlarm()">
                        <span class="blind">알림</span>
                    </button>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EventHelper from '../../helper/EventHelper';
import MenuHelper from '../../helper/MenuHelper';

export default {
    name: 'MainHeader',
    components: {},
    data: () => {
        return {};
    },
    computed: {
        ...mapState({
            count: state => state.main.count,
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        showNav() {
            EventHelper.onNavMenu();
        },
        goAlarm() {
            MenuHelper.alarm(this.$router);
        },
        goSearch() {
            MenuHelper.search(this.$router);
        },
        onAlarm() {
            return this.count.alarm > 0 ? 'btn-notify on' : 'btn-notify';
        },
        goSelectSite() {
            this.$router.push({ name: 'SelectSite' });
        }
    }
};
</script>

import WorkDetail from '../components/work/WorkDetail';
import KakaoWorkDetail from '../components/work/KakaoWorkDetail';
import Room from '../components/room/Room';
import RegistRoom from '../components/room/RegistRoom';
import RoomWorker from '../components/room/RoomWorker';
import AddWorker from '../components/room/AddWorker';
import AddWork from '../components/work/AddWork';
import AddWorkAction from '../components/work/AddWorkAction';

import ModifyWork from '../components/work/ModifyWork';
import ModifyWorkAction from '../components/work/ModifyWorkAction';
/* [2020.08.22] add by wwweojin */
import ModifyRoom from '../components/room/ModifyRoom';
import PictureRoom from '../components/room/PictureRoom';

import ShareRoom from '../components/room/ShareRoom';
//import { requireAuth } from './before';

export default [
    {
        path: '/room/regist',
        name: 'RegistRoom',
        component: RegistRoom,
        meta: {
            bodyClass: 'write off lightblue'
        }
    },
    {
        path: '/work/:id',
        name: 'WorkDetail',
        component: WorkDetail,
        meta: {
            bodyClass: 'fixed'
        }
    },
    {
        path: '/modifyWork/:roomId/:id',
        name: 'ModifyWork',
        component: ModifyWork,
        props: true,
        meta: {
            bodyClass: 'write lightblue'
        }
    },
    {
        path: '/kakao/work/:id',
        name: 'KakaoWorkDetail',
        component: KakaoWorkDetail,
        meta: {
            bodyClass: 'fixed'
        }
    },
    {
        path: '/room/:roomId',
        name: 'Room',
        component: Room,
        meta: {
            bodyClass: 'fixed'
        }
    },
    {
        path: '/workerOfroom/:roomId',
        name: 'RoomWorker',
        component: RoomWorker,
        meta: {
            bodyClass: 'fixed'
        }
    },
    {
        path: '/addWorker/:roomId',
        name: 'AddWorker',
        component: AddWorker,
        meta: {
            bodyClass: 'fixed'
        }
    },
    {
        path: '/addWork/:roomId/',
        name: 'AddWork',
        component: AddWork,
        props: true,
        meta: {
            bodyClass: 'write lightblue'
        }
    },
    {
        path: '/addWorkAction/:workId',
        name: 'AddWorkAction',
        component: AddWorkAction,
        props: true,
        meta: {
            bodyClass: 'write lightblue'
        }
    },
    {
        path: '/modifyWorkAction/:workId/:workActionId',
        name: 'ModifyWorkAction',
        props: true,
        component: ModifyWorkAction,
        meta: {
            bodyClass: 'write lightblue'
        }
    },
    {
        path: '/room/modify/:roomId',
        name: 'ModifyRoom',
        component: ModifyRoom,
        meta: {
            bodyClass: 'write off lightblue'
        }
    },
    {
        path: '/pictureRoom',
        name: 'PictureRoom',
        component: PictureRoom,
        meta: {
            bodyClass: 'main'
        }
    },
    {
        path: '/shareRoom/:id',
        name: 'ShareRoom',
        component: ShareRoom,
        meta: {
            bodyClass: ''
        }
    }
];

<template>
    <div id="signup-wrap">
        <div id="signup-container">
            <div id="signup-header">
                <ul class="step">
                    <li><span>1</span></li>
                    <li><span>2</span></li>
                    <li class="on">
                        <span>3</span>
                    </li>
                </ul>
                <p class="nm">
                    {{ basic.site }}
                </p>
                <p class="subtit">
                    소속회사와 직위/직종을 선택하세요.
                </p>
            </div>

            <div id="signup-body">
                <div class="signup-form-wrap">
                    <p>소속회사 <span class="require">*</span></p>
                    <div class="field">
                        <div class="select">
                            <select v-model="worker.company.id">
                                <option value="0">
                                    선택
                                </option>
                                <option v-for="(code, index) in company" :key="index" :value="code.id">
                                    {{ code.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <p class="subtext">
                        ※ 목록에 소속회사가 없는 경우 ‘기타’를 선택하세요.
                    </p>
                    <div v-if="worker.company.id === 2">
                        <p>대우건설직원 인증</p>
                        <div class="field">
                            <div>
                                <input v-model="authId" type="text" class="txt" autocomplete="false" placeholder="사번을 입력하세요." />
                                <input
                                    v-model="authPasswd"
                                    type="password"
                                    class="txt daewoo"
                                    autocomplete="new-password"
                                    placeholder="비일번호를 입력하세요."
                                />
                                <input type="button" value="인증" class="daewoo_btn" @click="checkDwEncAuth" />
                            </div>
                        </div>
                    </div>

                    <p>직위 <span class="require">*</span></p>
                    <div class="field">
                        <div class="select">
                            <select v-model="worker.positionCode">
                                <option value="">
                                    선택
                                </option>
                                <option v-for="(code, index) in position" :key="index" :value="code.code">
                                    {{ code.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <p>직종 <span class="require">*</span></p>

                    <div class="field">
                        <div class="select">
                            <select v-model="worker.occuationCode">
                                <option value="">
                                    선택
                                </option>
                                <option v-for="(code, index) in occupation" :key="index" :value="code.code">
                                    {{ code.name }}
                                </option>
                                <option value="etc">
                                    기타 직접 입력
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="field">
                        <input
                            v-model="worker.occupationsEtc"
                            type="text"
                            class="txt"
                            placeholder="직종을 직접 입력하세요."
                        />
                    </div>
                </div>
            </div>

            <div class="signup-btn-wrap cnt2">
                <a class="btn-prev" @click="prev"><span>이전으로</span></a>
                <a class="btn-next" @click="next"><span>다음으로</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkerApi from '../../api/WorkerApi';
import LoginApi from '../../api/LoginApi';
import SiteCompanyApi from '../../api/SiteCompanyApi';
export default {
    name: 'JoinStep3',
    components: {},
    data: () => {
        return {
            worker: {
                company: { id: 0 },
                positionCode: '',
                occuationCode: ''
            },
            company: [],
            authKey: 0,
            authId: null,
            authPasswd: null
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            position: state => state.code.position,
            occupation: state => state.code.occupation,
            //company: state => state.code.company,
            join: state => state.join.join
        })
    },
    created() {},
    mounted() {
        setTimeout(() => {
            this.checkAuth();
        }, 200);

        this.getPositionCodes();
        this.getOccupationCodes();
        //this.getCompany();
        SiteCompanyApi.list(this.basic.csId).then(res => {
            this.company = res.content.map(item => item.company);
        });
        //console.log(this.join);
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getPositionCodes', 'getOccupationCodes', 'getCompany']),
        async checkAuth() {
            try {
                const response = await LoginApi.checkJoinNo({
                    authNo: this.join.joinNo
                });

                if (!response.content) {
                    alert('잘못된 접근입니다.');
                    this.$router.go(-1);
                    return false;
                }
            } catch (error) {
                if (error.response) {
                    alert(error.response.data.msg);
                }
            }
        },
        prev() {
            this.$router.go(-1);
        },
        async next() {
            //등록검사

            if (await this.validation()) {
                const addParam = { dwenckey: this.authKey };
                const regist = { ...this.worker, ...this.join, ...addParam };
                //regist.company.id = this.join.csId;
                //console.log(regist);
                const res = await WorkerApi.regist(regist);
                if (!res.code) this.$router.push({ path: '/join/done' });
            }
        },
        async checkDwEncAuth() {
            const param = {
                id: this.authId,
                passwd: this.authPasswd
            };
            const res = await WorkerApi.dwencAuth(param);
            if (res.content) {
                if (res.content.indexOf('[ERROR]') >= 0) {
                    alert('아이디 및 패스워드를 확인해 주세요.');
                    return;
                }
                this.authKey = res.content;
                alert('인증되었습니다.');
            } else alert('아이디 및 패스워드를 확인해 주세요.');
        },
        validation() {
            if (this.worker.company.id === 2) {
                if (this.authKey > 0) return true;
                else {
                    alert('대우건설 직원인증을 완료하여야 가입이 가능합니다.');
                    return false;
                }
            }
            return true;
        }
    }
};
</script>

<template>
    <div>
        <header id="header" class="header primary">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="close()">
                            <span class="blind">메인</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>사진함</span>
                    </li>
                    <li class="folder">
                        <a class="btn-deleteall" @click="allSelect">
                            <span>{{ isAll ? '전체해제' : '전체선택' }}</span>
                        </a>
                        <a class="btn-delete" @click="allRelease">
                            <span>선택삭제</span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <main id="content-wrap">
            <article id="contents">
                <div ref="listBox" class="contents-body">
                    <section v-for="(val, name) in imgList" v-bind:key="val" class="myfolder-photo-list">
                        <div class="inner">
                            <div class="date">
                                {{ name }}
                            </div>
                            <ul class="myfolder-list">
                                <li v-for="(img, index) in val" :key="index">
                                    <div class="chk">
                                        <input id="myfolder-01" type="checkbox" />
                                        <input
                                            :id="'myfolder-' + name + index"
                                            v-model="selectImgs"
                                            type="checkbox"
                                            class="modal-link"
                                            :value="img"
                                        />
                                        <label :for="'myfolder-' + name + index">
                                            <span class="blind">선택</span>
                                        </label>
                                    </div>
                                    <a>
                                        <div class="thumb">
                                            <div class="thumb-inner">
                                                <img
                                                    :id="'myphoto-' + name + index"
                                                    v-gallery="name"
                                                    :data-index="index"
                                                    :data-large="img.url"
                                                    :src="img.imgUrl | thumbUrl"
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
                <div class="about-myfolder">
                    <p style="margin-bottom: 20px;">
                        사진함에 등록한 모든 사진은 COCO PC 버전의 '사진함'과 연동되어 확인할 수 있으며, 업무 자료로
                        활용하실 수 있습니다.
                    </p>
                </div>
            </article>
        </main>
        <footer id="myfolder-footer">
            <ul>
                <li>
                    <a @click="btnCamera"><span class="blind">사진 촬영</span></a>
                </li>
                <li>
                    <a @click="btnGallery"><span class="blind">파일 선택</span></a>
                </li>
            </ul>
        </footer>

        <input
            id="gallery"
            ref="uploadGallery"
            type="file"
            class="blind"
            accept="image/*"
            multiple
            @change="doDirImageAttach"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PictureRoomApi from '../../api/PictureRoomApi';
import CameraHelper from '../../helper/CameraHelper';
//import GpsHelper from '../../helper/GpsHelper';
import FileApi from '../../api/FileApi';

export default {
    name: 'PopPictureRoom',
    components: {},
    data: () => {
        return {
            imgList: [],
            selectImgs: [],
            condition: { page: 1, rows: 30 },
            totalRows: 0,
            originList: [],
            idx: 0,
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        window.addEventListener('scroll', this.morePage);
    },
    mounted() {
        //this.$emit('setTags', this.selectTags);
        this.bind();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.morePage);
    },
    methods: {
        ...mapActions([]),
        close() {
            //this.$emit('close');
            this.$router.go(-1);
        },
        async bind(page) {
            if (page > 0) this.condition.page = page;
            else this.condition.page = 1;
            this.imgList = [];
            this.selectImgs = [];
            const response = await PictureRoomApi.myList(this.condition);
            if (response) {
                if (this.condition.page > 1) this.originList.push(...response.content);
                else this.originList = response.content;
                //const temp = response.content;
                if (this.condition.page > 1)
                    this.imgList = { ...this.imgList, ...this.groupBy(this.originList, 'deviceYm') };
                else this.imgList = this.groupBy(this.originList, 'deviceYm');
                if (response.pageable) this.totalRows = response.pageable.total || 0;
            }
        },
        async allRelease() {
            //this.selectImgs = [];
            const param = this.selectImgs.map(item => item.id);
            await PictureRoomApi.removeIn({ list: param });
            this.bind(1);
        },
        groupBy(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        morePage() {
            const box = this.$refs.listBox;
            if (box.scrollTop + box.clientHeight >= box.scrollHeight) {
                if (this.totalRows > this.originList.length) this.bind(this.condition.page + 1);
            }
        },
        btnCamera() {
            CameraHelper.originPicture(this.roomCameraImage, () => {
                //this.$router.go(-1);
            });
        },
        btnGallery() {
            document.getElementById('gallery').click();
        },
        async roomCameraImage(fileUrl) {
            const formData = new FormData();
            var imgBlob = CameraHelper.base64ToBlob(fileUrl);
            const now = new Date();
            const imageName = now.getTime() + '.jpg';
            formData.append('file_id', '');
            formData.append('file_no', 1);
            formData.append('file', imgBlob);
            formData.append('file_name', imageName);
            const res = await FileApi.blolImgUpload(formData);
            //const res = await FileApi.myPhotoBlolImgupload(formData);
            const fileId = res.content.fileId;
            PictureRoomApi.regist({
                workerId: this.basic.id,
                fileId
            }).then(res => {
                if (res) this.bind(1);
            });
            /*
            GpsHelper.getPosition(position => {
                const param = {
                    fileId,
                    fileNo: 1,
                    latitude: position.latitude,
                    longitude: position.longitude,
                    altitude: position.altitude
                };
                FileApi.updateGps(param);
            });
            */
        },
        async doDirImageAttach() {
            //this.showLoading();
            const fileObjectList = this.$refs.uploadGallery.files;
            for (var fileObject of fileObjectList) {
                this.fileName = fileObject.name;
                if (fileObject) {
                    const formData = new FormData();
                    formData.append('file_id', '');
                    formData.append('file', fileObject);
                    const res = await FileApi.imgUpload(formData);
                    const fileId = res.content.fileId;
                    await PictureRoomApi.regist({
                        workerId: this.basic.id,
                        fileId
                    });
                }
            }
            this.bind(1);
        },
        getIndex() {
            return ++this.idx;
        },
        allSelect() {
            //this.selectImgs = this.imgList;
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.selectImgs = this.originList;
                //for
            } else {
                this.selectImgs = [];
            }
        },
        printImage(fileId) {
            //const element = this.$el;
            const id = {
                fileId,
                fileNo: 1
            };
            const thumb = '400X400';
            return `/api/file/download/file?file_id=${id.fileId}&file_no=${id.fileNo}&thumNails=${thumb}`;
        }
    }
};
</script>

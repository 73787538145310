<template>
    <div>
        <Header :loc-nm="boardName[boardType]" loc-type="list" :fn-confirm="goRegist" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="board-list">
                        <li v-for="(board, index) in boards.content" :key="index">
                            <div class="board-header">
                                <a @click="menuBoard(board)">
                                    <div class="board-box">
                                        <p v-if="board.type === 'notice'">{{ board.title }}</p>
                                        <p v-else>{{ board.workDay | formatTime }} {{ board.title }}</p>
                                        <div class="meta-info new_meta">
                                            <span v-if="board.type === 'notice'" class="datetime">
                                                {{ board.regDt | longFormatTime }}
                                            </span>
                                            <span v-else class="charger new_charge">
                                                {{ board.register.displayName }}
                                                &middot;
                                                {{ board.register.company.name }}
                                            </span>
                                        </div>
                                    </div>
                                </a>

                                <div class="more-util">
                                    <button class="btn-more-util" @click="moreTool(board.id)">
                                        <span class="blind">툴 더보기</span>
                                    </button>
                                </div>
                                <div :id="'util-box-' + board.id" class="util-box board-more hide">
                                    <ul>
                                        <li>
                                            <button class="btn-share-kakao" @click="share(board)">
                                                <span>카톡 공유</span>
                                            </button>
                                        </li>
                                        <li>
                                            <button class="btn-hide" @click="hide(board)">
                                                <span>숨기기</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="view-more-wrap">
                        <a v-if="boards.isMore" class="view-more" @click="more()"><span>더보기</span></a>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/RegistWhiteHeader.vue';
import BoardApi from '../../api/BoardApi';
//import ArrayHelper from '../../helper/ArrayHelper';
//import EventHelper from '../../helper/EventHelper';
import MenuHelper from '../../helper/MenuHelper';
import PageModel from '../../model/PageModel';
//import MobileHelper from '../../helper/MobileHelper';
import KakaoHelper from '../../helper/KakaoHelper';

export default {
    name: 'BoardList',
    components: { Header },
    data: () => {
        return {
            boards: new PageModel(),
            boardName: { notice: '공지', daily: '작업일보' },
            boardType: ''
        };
    },
    computed: {
        ...mapState({})
    },
    created() {
        this.boardType = this.$route.name.toLowerCase();
        //EventHelper.offNavMenu();
    },
    mounted() {
        this.boardType = this.$route.meta.type;
        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        async hide(board) {
            await BoardApi.hide(board.id);
            this.boards.removeItem(board.id);
            //ArrayHelper.oneRemove(this.boards.content, condition);
            //this.boards.total
        },
        moreTool(id) {
            $('.util-box.board-more').addClass('hide');
            $('#util-box-' + id).removeClass('hide');
        },
        blurTool(id) {
            $('#util-box-' + id).addClass('hide');
        },
        goRegist() {
            if (this.boardType === 'notice') MenuHelper.noticeRegist(this.$router);
            else MenuHelper.dailyRegist(this.$router);
        },
        share(board) {
            if (board.type === 'notice') {
                KakaoHelper.share('notice', board.id, 'COCO 공지사항', null, board.title);
            } else {
                KakaoHelper.share('notice', board.id, 'COCO 작업일보', null, board.workDay + '작업일보');
            }
        },
        menuBoard(board) {
            if (board.type === 'notice') MenuHelper.notice(this.$router, board.id);
            else MenuHelper.daily(this.$router, board.id);
        },
        async doSearch() {
            const query = {
                ...this.boards.query(),
                type: this.boardType
            };
            const response = await BoardApi.list(query);
            if (response) {
                //this.boards = new PageModel(response);
                this.boards.bind(response, true);
            }
        },
        more() {
            ++this.boards.page;
            this.doSearch();
        }
    }
};
</script>

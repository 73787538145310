import Vue from 'vue';
import Router from 'vue-router';

import Main from '../components/Main';
import Login from '../components/Login';
import NotFound from '../components/errors/404';

import SelectSite from '../components/SelectSite';
import Search from '../components/common/Search';
import ImageEditor from '../components/common/ImageEditor';
import ProfileEditor from '../components/common/ProfileEditor';

import AlarmRoutes from './alarm';
import BoardRoutes from './board';
import WorkRoutes from './work';
import CommentRoutes from './comment';
import JoinRoutes from './join';
import MemberRoutes from './member';

import DailyRoutes from './daily';

//import { requireAuth } from './before';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Main',
            alias: '/main',
            component: Main,
            meta: {
                bodyClass: 'main fixed'
            }
            //beforeEnter: requireAuth()
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                bodyClass: 'login'
            }
        },
        {
            path: '/site',
            name: 'SelectSite',
            component: SelectSite,
            meta: {
                bodyClass: 'main fixed'
            }
        },
        {
            path: '/search',
            name: 'Search',
            component: Search,
            meta: {
                bodyClass: ''
            }
        },
        {
            path: '/imageEditor/:roomId',
            name: 'ImageEditor',
            component: ImageEditor,
            props: true,
            meta: {
                bodyClass: 'off photo-editor'
            }
        },
        {
            path: '/imageEditorOfwork/:workId',
            name: 'ImageEditorOfwork',
            component: ImageEditor,
            props: true,
            meta: {
                bodyClass: 'off photo-editor'
            }
        },
        {
            path: '/profileEditor',
            name: 'ProfileEditor',
            component: ProfileEditor,
            meta: {
                bodyClass: 'off photo-editor'
            }
        },
        ...AlarmRoutes,
        ...BoardRoutes,
        ...WorkRoutes,
        ...CommentRoutes,
        ...MemberRoutes,
        ...JoinRoutes,
        ...DailyRoutes,
        { path: '*', component: NotFound }
    ]
});

export default router;

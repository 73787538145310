import { format } from 'date-fns';
import { getLanguage } from './system';
import en from 'date-fns/locale/en';
import ko from 'date-fns/locale/ko';

const locales = { en, ko };

const formatWithLocale = (date, formatStr) => {
    if (typeof date === 'undefined') {
        return '';
    }
    return format(date, formatStr, { locale: locales[getLanguage()] });
};

export const lastAccessTime = () => {
    const objDate = new Date();
    return formatWithLocale(objDate.getTime(), 'YYYY.MM.DD HH:mm:ss');
};

export const toDayFormat = () => {
    const objDate = new Date();
    return formatWithLocale(objDate.getTime(), 'YYYY-MM-DD');
};

export const toDayFormatAddMonth = month => {
    const objDate = new Date();
    objDate.setMonth(objDate.getMonth() + month);
    return formatWithLocale(objDate.getTime(), 'YYYY-MM-DD');
};

export const toDayFormatAddDay = day => {
    const objDate = new Date();
    objDate.setDate(objDate.getDate() + day);
    return formatWithLocale(objDate.getTime(), 'YYYY-MM-DD');
};

export const longFormatTime = timestamp => formatWithLocale(timestamp, 'YYYY.MM.DD HH:mm:ss');
export const formatTime = timestamp => formatWithLocale(timestamp, 'YYYY.MM.DD'); // TODO: XLT format should be applied
export const shortTime = timestamp => formatWithLocale(timestamp, 'MM.DD'); // TODO: XLT format should be applied
export const formatDate = timestamp => formatWithLocale(timestamp, 'YYYY-MM-DD');

export const timeBefore = inputDate => {
    //현재시간
    const now = new Date();
    //글쓴 시간
    inputDate = inputDate.replace(/ /g, 'T');

    const writeDay = new Date(inputDate);
    const nowTime = now.getTime();
    const writeTime = writeDay.getTime();

    if (nowTime > writeTime) {
        let sec = parseInt(nowTime - writeTime) / 1000;
        const day = parseInt(sec / 60 / 60 / 24);
        sec = sec - day * 60 * 60 * 24;
        const hour = parseInt(sec / 60 / 60);
        sec = sec - hour * 60 * 60;
        const min = parseInt(sec / 60);
        sec = parseInt(sec - min * 60);
        if (day > 0) {
            return day + '일 전';
        } else if (hour > 0) {
            return hour + '시간 전';
        } else if (min > 0) {
            return min + '분 전';
        } else if (sec > 0) {
            return sec + '초 전';
        }
    }
    return '1초 전';
};

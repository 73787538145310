<template>
    <div>
        <header id="header" class="header group">
            <div class="in-section blue">
                <ul>
                    <li class="back">
                        <button type="button" class="btn-back" @click="goBack()">
                            <span class="blind">뒤로</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>그룹방명 변경</span>
                    </li>
                    <li class="etc">
                        <button type="button" class="btn-etc" @click="modifyGroup()">
                            <span>확인</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>

        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body blueFull">
                    <div class="group-form-wrap">
                        <input
                            v-model="room.name"
                            type="text"
                            class="txt"
                            placeholder="그룹명을 입력하세요."
                            autofocus
                            maxlength="10"
                        />
                    </div>
                    <div class="alarm">
                        <p>
                            그룹방명을 수정하면 그룹멤버도 동일하게 적용됩니다.
                        </p>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import WorkRoomApi from '../../api/WorkRoomApi';
import MenuHelper from '../../helper/MenuHelper';
import { alertDialog } from '../../helper/DialogHelper';
export default {
    name: 'RegistRoom',
    components: {},
    data: () => {
        return {
            room: { id: 0, name: '' }
        };
    },
    computed: {},
    created() {
        this.room.id = Number(this.$route.params.roomId);
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        goBack() {
            //this.$router.push({ path: '/main' });
            this.$router.go(-1);
        },
        async modifyGroup() {
            if (this.room.name) {
                const res = await WorkRoomApi.modify(this.room.id, this.room);
                if (res) {
                    //MenuHelper.room(this.$router, res.id);
                    MenuHelper.workerOfroomNoHistory(this.$router, this.room.id);
                }
            } else {
                alertDialog('그룹명을 입력하세요.');
            }
        }
    }
};
</script>

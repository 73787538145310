const htmlFixedSetting = () => {
    var scroll_v = $(window).scrollTop();
    $('body').addClass('off');
    $('body').css({ 'margin-top': scroll_v * -1 });
};

const scrollATop = () => {
    var scroll_v = $('#header').scrollTop();
    $('html, body')
        .stop()
        .animate({ scrollTop: scroll_v }, 300);
};

// HTML FIXED 해제시
const htmlUnfixedSetting = () => {
    $('body').removeClass('off');
    var scroll_v = $('body').css('margin-top') || 0;
    scroll_v = parseInt(scroll_v, 10) * -1;
    $('body').css({ 'margin-top': 0 });
    $('html, body')
        .stop()
        .animate({ scrollTop: scroll_v }, 1);
};

export default {
    onScrollATop: () => {
        scrollATop();
    },
    onNavMenu: () => {
        $('#ui-draw-nav-wrapper').addClass('on');
    },
    offNavMenu: () => {
        $('#ui-draw-nav-wrapper').removeClass('on');
    },
    onFilter: () => {
        htmlFixedSetting();
        $('.filter-option')
            .addClass('on')
            .find('.close-filter .btn-close-filter')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.filter-option').removeClass('on');
                htmlUnfixedSetting();
            });
    },
    offFilter: () => {
        $('.filter-option').removeClass('on');
        htmlUnfixedSetting();
    },
    onRegister: () => {
        $('.filter-register-wrap')
            .addClass('on')
            .find('header .close .btn-close')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.filter-register-wrap').removeClass('on');
            });
    },
    onManager: tpId => {
        $('.filter-people-wrap.' + tpId)
            .addClass('on')
            .find('header .close .btn-close')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.filter-people-wrap.' + tpId).removeClass('on');
            });
    },
    onTag: tpId => {
        $('.filter-tags-wrap.' + tpId)
            .addClass('on')
            .find('header .close .btn-close')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.filter-tags-wrap.' + tpId).removeClass('on');
            });
    },
    onRoom: () => {
        $('.filter-room-wrap')
            .addClass('on')
            .find('header .close .btn-close')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.filter-room-wrap').removeClass('on');
            });
    },
    onEquip: () => {
        $('.filter-equips-wrap')
            .addClass('on')
            .find('header .close .btn-close')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.filter-equips-wrap').removeClass('on');
            });
    },
    onOccupation: () => {
        $('.filter-occupations-wrap')
            .addClass('on')
            .find('header .close .btn-close')
            .off('click')
            .on('click', function(e) {
                e.preventDefault();
                $('.filter-occupations-wrap').removeClass('on');
            });
    }
};

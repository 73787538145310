import MutationTypes from '../../MutationTypes';
/* api */
import WorkRoomApi from '../../../api/WorkRoomApi';

import WorkApi from '../../../api/WorkApi';
import WorkerApi from '../../../api/WorkerApi';

export default {
    getWorkOfSearch: async ({ commit }, condition) => {
        const works = await WorkApi.list(condition);
        commit(MutationTypes.SET_WORK_OF_SEARCH, works.content);
    },
    getMyRooms: async ({ commit }) => {
        const rooms = await WorkRoomApi.myList();
        if (rooms) {
            for (var room of rooms) {
                room.works = [];
                room.workers = '';
                const res = await WorkerApi.listOfroom(room.id);
                res.forEach(item => {
                    if (room.master.id !== item.id) room.workers += item.name + ',';
                });
                if (room.workers) room.workers = room.workers.slice(0, -1);
            }
            commit(MutationTypes.SET_MY_ROOMS, rooms);
        }
    },
    incRoomCnt: async ({ commit, state }, roomId) => {
        var roomIdx = state.roomList.findIndex(room => room.id === roomId);
        commit(MutationTypes.ADD_MY_ROOM_CNT, roomIdx);
    },
    incAlarmCnt: async ({ commit, state }) => {
        commit(MutationTypes.ADD_MY_ALARM_CNT);
        console.log('alarmCnt::' + state.alarmCnt);
    },
    rstRoomCnt: async ({ commit, state }, roomId) => {
        var roomIdx = state.roomList.findIndex(room => room.id === roomId);
        commit(MutationTypes.RESET_MY_ROOM_CNT, roomIdx);
    },
    getWorkOfRoom: async ({ state, commit }, condition) => {
        console.log(condition.page);
        if (state.workOfRoom.works && condition.page > 1) {
            //console.log(state.workOfRoom.works.length, state.workOfRoom.total);
            if (state.workOfRoom.works.length === state.workOfRoom.total && state.workOfRoom.total > 0) return;
        }
        const works = await WorkApi.list(condition);
        const workOfRoom = {
            roomId: condition.roomId,
            //works: works.content.reverse(),
            works: works.content,
            page: works.pageable.page + 1,
            total: works.pageable.total
        };
        commit(MutationTypes.SET_WORK_OF_ROOM, workOfRoom);
    },
    addWork: async ({ commit }, work) => {
        const response = await WorkApi.regist(work);
        if (response) {
            commit(MutationTypes.ADD_WORK_OF_ROOM, response.content);
            return response;
        }
    },
    modifyWork: async ({ commit }, work) => {
        const response = await WorkApi.modify(work.id, work);
        if (response) {
            commit(MutationTypes.ADD_WORK_OF_ROOM, response.content);
            return response;
        }
    },
    removeWork: async ({ commit }, work) => {
        await WorkApi.remove(work.id);
        commit(MutationTypes.REMOVE_WORK_OF_ROOM, work);
    },
    addWorkOfRoom: async ({ commit }, work) => {
        commit(MutationTypes.ADD_WORK_OF_ROOM, work);
    },
    setPicture: async ({ commit }, imgObj) => {
        commit(MutationTypes.SET_PICTURE, imgObj);
    },
    setTempWorkContent: async ({ commit }, content) => {
        commit(MutationTypes.SET_TEMP_WORK_CONTENT, content);
    },
    setTempWorkDocFile: async ({ commit }, content) => {
        commit(MutationTypes.SET_TEMP_WORK_DOCFILE, content);
    }
};

import Vue from 'vue';
import Vuex from 'vuex';

import code from './modules/code';
import main from './modules/main';
import work from './modules/work';
import join from './modules/join';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: { main, work, join, code },
    strict: process.env.NODE_ENV !== 'production'
    // plugins: debug ? [createLogger()] : []
});

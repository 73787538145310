<template>
    <div>
        <Header loc-nm="마이페이지" loc-type="regist" :fn-confirm="modify" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <div class="form-wrap">
                        <div class="mypage">
                            <div class="profile">
                                <div class="profile_thumb">
                                    <img
                                        :src="worker.imgUrl | profileUrl"
                                        onerror="javascript:this.src='/images/common/default.png"
                                    />
                                </div>
                                <div class="photoedit">
                                    <img src="@static/images/main/icon-main-footer-2@2x.png" @click="imageEditor()" />
                                </div>
                            </div>
                        </div>
                        <div class="field-box">
                            <div class="label">
                                휴대폰 ID
                            </div>
                            <div class="field">
                                <input v-model="worker.asteriskPhone" type="text" class="txt" readonly />
                            </div>
                        </div>
                        <div class="field-box">
                            <div class="label">
                                비밀번호
                            </div>
                            <div class="field">
                                <div class="password-box">
                                    <input type="password" readonly value="1234" />
                                    <a @click="menuPasswd"><span>변경</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="field-box">
                            <div class="label">
                                소속업체
                            </div>
                            <div class="field">
                                <div class="select-box">
                                    <div class="select readonly">
                                        <select v-model="worker.company.id" disabled>
                                            <option value="0">
                                                선택
                                            </option>
                                            <option v-for="(code, index) in company" :key="index" :value="code.id">
                                                {{ code.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field-box">
                            <div class="label">
                                성명
                            </div>
                            <div class="field">
                                <input v-model="worker.name" type="text" class="txt" readonly value="홍길동" />
                            </div>
                        </div>
                        <div class="field-box">
                            <div class="label">
                                직위
                            </div>
                            <div class="field">
                                <div class="select-box">
                                    <div class="select">
                                        <select v-model="worker.positionCode">
                                            <option value="">
                                                선택
                                            </option>
                                            <option v-for="(code, index) in position" :key="index" :value="code.code">
                                                {{ code.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field-box">
                            <div class="label">
                                직종
                            </div>
                            <div class="field">
                                <div class="select-box">
                                    <div class="select">
                                        <select v-model="worker.occupationsCode" @change="onChageOccupation">
                                            <option value="">
                                                선택
                                            </option>
                                            <option v-for="(code, index) in occupation" :key="index" :value="code.code">
                                                {{ code.name }}
                                            </option>
                                            <option value="etc">
                                                기타 직접 입력
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onloadedmetadata="" class="field-box">
                            <div class="label" />
                            <div class="field">
                                <input
                                    v-if="isEtc || worker.occupationsEtc"
                                    v-model="worker.occupationsEtc"
                                    type="text"
                                    class="txt"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkerApi from '../../api/WorkerApi';
import Header from '../header/RegistWhiteHeader.vue';

import MenuHelper from '../../helper/MenuHelper';
import MutationTypes from '../../store/MutationTypes';

import { alertDialog } from '../../helper/DialogHelper';

export default {
    name: 'MyPage',
    components: { Header },
    data: () => {
        return {
            worker: {
                company: { id: 0 },
                positionCode: '',
                occupationsCode: ''
            },
            isEtc: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic,
            position: state => state.code.position,
            occupation: state => state.code.occupation,
            company: state => state.code.company
        })
    },
    created() {},
    mounted() {
        this.getPositionCodes();
        this.getOccupationCodes();
        this.getCompany();
        this.myInfo();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getPositionCodes', 'getOccupationCodes', 'getCompany']),
        menuPasswd() {
            MenuHelper.passwd(this.$router);
        },
        async myInfo() {
            this.worker = await WorkerApi.detail(this.basic.id);
            if (!this.worker.occupationsCode) this.worker.occupationsCode = '';
        },
        async modify() {
            //if (this.worker.occupationsCode === 'etc') this.worker.occupationsCode = null;
            const response = await WorkerApi.modify(this.basic.id, this.worker);
            if (response) {
                alertDialog('수정하였습나다');
                //this.worker.occupationsCode = 'etc';
                const basic = {
                    csId: this.basic.csId,
                    name: response.name,
                    position: response.positionName,
                    company: response.company.name,
                    companyId: response.compnay.id,
                    id: response.id,
                    hp: response.hp,
                    site: this.basic.name,
                    asteriskPhone: response.asteriskPhone,
                    imgUrl: response.imgUrl
                };
                this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
            }

            //console.log(response, 'response확인하고 성공여부처리, 실패는 공통처리');
        },
        imageEditor() {
            this.$router.push('/profileEditor');
        },
        onChageOccupation() {
            if (this.worker.occupationsCode === 'etc') {
                //this.worker.occupationsCode = null;
                this.isEtc = true;
            } else {
                this.isEtc = false;
            }
        }
    }
};
</script>

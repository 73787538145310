import MutationTypes from '../../MutationTypes';
import { MAIN_LIST_SIZE } from '../../../const';
/* api */
import WeatherApi from '../../../api/WeatherApi';
import WorkApi from '../../../api/WorkApi';
import AlarmApi from '../../../api/AlarmApi';
import BoardApi from '../../../api/BoardApi';
import AffilationSiteApi from '../../../api/AffilationSiteApi';

/* model */
import WeatherModel from '../../../model/WeatherModel';
import CountModel from '../../../model/CountModel';
import { toDayFormat } from '../../../utils/date';

import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID } from '../../../const';

/*
const setInterval = () => {
    const load = document.getElementById('coco-loding');
    var p = 0;
    window.setInterval(() => {
        if (window.Loadgo.getprogress(load) >= 90) {
            const prog = p * 20;
            window.Loadgo.setprogress(load, prog);
            p++;
        } else {
            const prog = p * 20;
            window.Loadgo.setprogress(load, prog);
            p++;
        }
    }, 20);
};
*{/}
//import router from '../../../router';
/* Action은 별도의 namespace없이 바로 구분이 가능하도록 사용한다.*/

export default {
    login: async () => {
        //router.push({ path: '/login' });
    },
    logout: async ({ commit }) => {
        commit(MutationTypes.SET_MAIN_WORK, { my: [], work: [] });
        commit(MutationTypes.SET_MAIN_WEATHER, new WeatherModel({}));
        commit(MutationTypes.SET_MAIN_COUNT, new CountModel({}));
        commit(MutationTypes.SET_MAIN_BOARD, { notice: [], daily: [] });
        const basic = {
            csId: 0,
            name: '',
            position: '',
            company: '',
            companyId: 0,
            id: 0,
            hp: '',
            imgUrl: '',
            csAdminYn: 'N',
            csCompanyAdminYn: 'N'
        };

        window.localStorage.removeItem(CS_ID);
        window.localStorage.removeItem(WORKER_ID);
        window.localStorage.removeItem(JWT_TOKEN);
        window.localStorage.removeItem(JWT_REFRESH_TOKEN);

        commit(MutationTypes.SET_MAIN_BASIC, basic);

        if (window.stompClient) {
            window.stompClient.disconnect();
            window.stompClient = null;
        }
    },
    getWork: async ({ commit }) => {
        const my = await WorkApi.myList(MAIN_LIST_SIZE);
        const todo = await WorkApi.todoList(MAIN_LIST_SIZE);
        const work = { my, todo };
        commit(MutationTypes.SET_MAIN_WORK, work || { my: [], todo: [] });
    },
    getWeather: async ({ state, commit }) => {
        const weatherList = await WeatherApi.list();
        const airWeather = await WeatherApi.airList();
        let csId = 0;
        if (state.basic && state.basic > 0) {
            csId = state.basic.csId;
        } else csId = localStorage.getItem(CS_ID);
        const minMax = await WeatherApi.minMax(csId, toDayFormat());

        const weather = {};
        if (weatherList) {
            if (weatherList.content.length > 0) {
                //weather.sky = weatherList.content.find(item => item.type === 'SKY').value;
                //weather.reh = weatherList.content.find(item => item.type === 'REH').value;
                //weather.pop = weatherList.content.find(item => item.type === 'POP').value;
                //weather.t1h = weatherList.content.find(item => item.type === 'T1H').value;
                weather.pty = weatherList.content.find(item => item.type === 'PTY').value;
                //weather.rn1 = weatherList.content.find(item => item.type === 'RN1').value;
                //weather.lgt = weatherList.content.find(item => item.type === 'LGT').value;

                //weather.tmx = weatherList.content.find(item => item.type === 'TMX').value;
                //weather.tmn = weatherList.content.find(item => item.type === 'TMN').value;
                if (minMax.content) {
                    weather.tmx = minMax.content.maxVal;
                    weather.tmn = minMax.content.minVal;
                } else {
                    weather.tmx = weatherList.content.find(item => item.type === 'TMX').value;
                    weather.tmn = weatherList.content.find(item => item.type === 'TMN').value;
                }

                if (weatherList.content.find(item => item.type === 'SKY'))
                    weather.sky = weatherList.content.find(item => item.type === 'SKY').value;

                weather.pmv = airWeather.content.pm10Value || airWeather.content.pm25Value24;
            }
        }
        commit(MutationTypes.SET_MAIN_WEATHER, new WeatherModel(weather || {}));
    },
    getCount: async ({ commit }) => {
        const myWork = await WorkApi.myCount();
        const todoWork = await WorkApi.todoCount();
        const alarm = await AlarmApi.counter();
        const siteRequest = await AffilationSiteApi.siteOfrequest();
        const approveWorker = siteRequest.content.length;

        const counter = {
            myWork,
            todoWork,
            alarm,
            approveWorker
        };
        commit(MutationTypes.SET_MAIN_COUNT, new CountModel(counter || {}));
    },
    getBoard: async ({ commit }) => {
        const notice = await BoardApi.main('notice');
        const daily = []; //await BoardApi.main('daily');
        const board = { notice, daily };
        commit(MutationTypes.SET_MAIN_BOARD, board);
    },
    hideBoard: async ({ commit, state }, id) => {
        await BoardApi.hide(id);

        const notice = state.board.notice.filter(board => board.id !== id);
        const daily = state.board.daily.filter(board => board.id !== id);

        commit(MutationTypes.SET_MAIN_BOARD, { notice, daily });
    },
    showLoading: async ({ commit }) => {
        /*
        const load = document.getElementById('coco-loding');
        window.Loadgo.init(load, {
            image: '/images/common/loading_coco.png',
            bgcolor: 'transparent'
        });
        window.Loadgo.resetprogress(load);
        setInterval();
        */
        commit(MutationTypes.SET_IS_LOADING, true);
    },
    hideLoading: async ({ commit }) => {
        //window.clearInterval(setInterval);
        commit(MutationTypes.SET_IS_LOADING, false);
    }
};

import { JWT_TOKEN, JWT_REFRESH_TOKEN } from '../const';
//import router from '../router';
import LoginApi from '../api/LoginApi';
//vue router를 import해서 refresh token에서.. 401이 발생하면 login으로 전달
/*
 * 메세지를 입력받아서 값에 넣는다
 */
const handleApiError = async (preFn, params, error) => {
    const status = error ? error.status : 401;

    if (status === 401) {
        try {
            const result = await LoginApi.refreshToken(window.localStorage.getItem(JWT_REFRESH_TOKEN));
            //console.log(result);
            //router.push()
            if (result.content) {
                localStorage.setItem(JWT_TOKEN, result.content.token.token);
                localStorage.setItem(JWT_REFRESH_TOKEN, result.content.token.refreshToken);
                return preFn(params).then(res => res);
            }
        } catch (error) {
            console.log(error);
            //return false;
            //store.dispatch('getWork');
            window.location.href = '/login';
        }
    }

    const message = error.data ? error.data.msg : httpErrorMessage(status);
    //return { message, status };
    if (message && status !== 401) alert(message);
    return false;
    //alert(message);
    //store.dispatch('addApiError', message);
};

/* http 연동관련 에러메세지 */
const httpErrorMessage = status => {
    switch (status) {
        case 400:
            return 'Bad Request';
        case 401:
            return 'Unauthorized';
        case 404:
            return 'Not Found';
        case 405:
            return 'Method Not Allowed';
        case 500:
            return 'Inter Server Error';
        default:
            return false;
        //return 'Error, please try again later';
    }
};

/*
 * api 호출시 아래의 wrapHttpClient 래핑한다. (api 공통처리를 위해서)
 */
export const wrapHttpClient = fn => (...params) => {
    return fn(...params)
        .then(res => res)
        .catch(error => {
            return handleApiError(fn, ...params, error.response || error);
        });
};

<template>
    <div class="util-box">
        <ul>
            <li v-if="item.type !== 'work' && moreType === 'work'">
                <button class="btn-share-kakao" @click="shareRoom">
                    <span>그룹 공유</span>
                </button>
            </li>
            <li>
                <button class="btn-share-kakao" @click="shareKakao">
                    <span>카톡 공유</span>
                </button>
            </li>
            <li>
                <a class="btn-phone" @click="callhp()"><span>전화연결</span></a>
            </li>
            <li v-show="isDelShow()">
                <button class="btn-delete" @click="goModify">
                    <span>수정</span>
                </button>
            </li>
            <li v-show="isDelShow()">
                <button class="btn-delete" @click="remove">
                    <span>삭제</span>
                </button>
            </li>
            <li>
                <button class="btn-delete" @click="$emit('moreClose')">
                    <span>메뉴닫기</span>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
//import WorkApi from '../../api/WorkApi';import { mapState, mapActions } from 'vuex';
import WorkActionApi from '../../api/WorkActionApi';
import KakaoHelper from '../../helper/KakaoHelper';
import MenuHelper from '../../helper/MenuHelper';

import { mapState, mapActions } from 'vuex';

import { confirmDialog } from '../../helper/DialogHelper';

export default {
    name: 'MoreUtil',
    components: {},
    props: {
        item: {
            type: Object,
            required: true
        },
        imgs: {
            type: Array,
            required: true
        },
        moreType: {
            type: String,
            required: false,
            default: 'work'
        },
        workId: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['removeWork']),
        shareKakao() {
            let imgUrl = '';
            if (this.imgs.length > 0) imgUrl = this.imgs[0].url;
            KakaoHelper.share('work', this.item.id, 'COCO 등록 내용을 확인하세요.', imgUrl, this.item.content);
        },
        callhp() {
            if (this.tel()) window.open('tel:' + this.tel(), '_system');
        },
        isDelShow() {
            //if (this.basic.csAdminYn === 'Y') return true;

            if (this.item.register) {
                return this.item.register.id === this.basic.id;
            } else {
                return this.item.regId === this.basic.id;
            }
        },
        async remove() {
            if (confirm('삭제하시겠습니까?')) {
                let res;
                if (this.item.type) {
                    res = this.removeWork(this.item);
                    if (res) {
                        this.$router.go(-1);
                    }
                } else {
                    res = await WorkActionApi.remove(this.item.id);
                    if (res) this.$emit('removeAction', this.item.id);
                }
            }
        },
        tel() {
            if (this.item.register) return this.item.register.hp;
            else return this.item.hp;
        },
        goModify() {
            //console.log(this.item);
            if (this.moreType === 'work')
                this.$router.push('/modifyWork/' + this.item.payload.room.id + '/' + this.item.id);
            else if (this.moreType === 'action')
                //console.log(this.item);
                this.$router.push('/modifyWorkAction/' + this.workId + '/' + this.item.id);
        },
        shareRoom() {
            //alert('popup');
            if (this.item.type !== 'work') {
                confirmDialog('다른 그룹에 공유하시겠습니까?', async () => {
                    MenuHelper.shareRoom(this.$router, this.item.id);
                });
            }
        }
    }
};
</script>

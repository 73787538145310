<template>
    <div>
        <Header
            v-if="auth"
            :loc-nm="boardName + ' 상세'"
            loc-type="detail"
            :fn-remove="doRemove"
            :fn-modify="doModify"
            :fn-list="doList"
        />
        <Header v-else :loc-nm="boardName + ' 상세'" loc-type="detail" :fn-list="doList" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="board-list detail">
                        <li>
                            <div class="board-header">
                                <a class="title">
                                    <div class="board-box">
                                        <p>{{ board.title }}</p>
                                        <div class="meta-info">
                                            <span class="datetime">
                                                {{ board.regDt | longFormatTime }}
                                            </span>
                                            <span> {{ board.author }}&middot;{{ board.register.company.name }} </span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <!-- 20200527 첨부파일 추가내용 -->
                            <div v-if="fileList.length > 0" class="board-file-box">
                                <dl>
                                    <dt><span class="tit-files">첨부</span></dt>
                                    <dd>
                                        <div>
                                            <a v-for="(file, index) in fileList" :key="index" @click="download(file)">
                                                <span class="files">{{ file.name }}</span>
                                            </a>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                            <!-- 20200527 첨부파일 추가내용 //-->
                            <div class="board-memo" v-html="board.content" />
                            <div class="board-memo-reply">
                                <div class="title">
                                    기술문의 답변
                                </div>
                                <p v-html="board.answer || '답변전'" />
                            </div>
                        </li>
                    </ul>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/RegistWhiteHeader.vue';
import BoardApi from '../../api/BoardApi';
import FileApi from '../../api/FileApi';

/* Helper */
import ArrayHelper from '../../helper/ArrayHelper';
import EventHelper from '../../helper/EventHelper';
import { isAuth } from '../../helper/AuthHelper';
import MenuHelper from '../../helper/MenuHelper';
import MobileHelper from '../../helper/MobileHelper';

import { confirmDialog } from '../../helper/DialogHelper';

//import { convertCrToBr } from '../../utils/string';

export default {
    name: 'BoardDetail',
    components: { Header },
    data: () => {
        return {
            bid: null,
            auth: false,
            board: { register: { company: '' } },
            boardName: '기술문의',
            boardType: 'qna',
            fileList: []
        };
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {
        this.bid = this.$route.params.id;
        this.board.type = this.boardType;
        this.board.noticeYn = 'N';
        EventHelper.onNavMenu();
        EventHelper.offNavMenu();
        this.detail();
    },
    beforeDestroy() {
        //console.log('event제거');
    },
    methods: {
        ...mapActions([]),
        hide(board) {
            BoardApi.hide(board.id).then(() => {
                const condition = item => item.id === board.id;
                ArrayHelper.oneRemove(this.board, condition);
            });
        },
        doList() {
            //MenuHelper.
            this.$router.replace({ path: `/qna` });
        },
        doModify() {
            //console.log('modify 페이지로 이동함', this.board);
            MenuHelper.qnaRegist(this.$router, this.board.id);
        },
        async doRemove() {
            confirmDialog('삭제 하시겠습니까?', async () => {
                await BoardApi.remove(this.board.id);
                this.$router.go(-1);
            });
        },
        async detail() {
            if (this.bid) {
                this.board = await BoardApi.detail(this.$route.params.id);
                this.auth = isAuth(this.board.register.id);
                if (this.board.fileId) this.fileList = await FileApi.list(this.board.fileId);

                if (!this.board.answer) this.board.answer = '답변전입니다.';
            }
        },
        download(file) {
            if (MobileHelper.isWebView()) {
                FileApi.downloadData(file.id);
            } else FileApi.fileDownload(file.id);
            //console.log(file);
        },
        textStatus(board) {
            if (board.answer) return '답변완료';
            else return '답변전';
        }
    }
};
</script>

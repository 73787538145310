<template>
    <div>
        <header id="header" class="header primary">
            <div class="in-section">
                <ul>
                    <li class="back">
                        <button type="button" class="btn-back" @click="back()">
                            <span class="blind">뒤로</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>작업일보</span>
                    </li>
                    <li v-if="isWorkDailyAuth" class="etc">
                        <button type="button" class="btn-etc" @click="goRegist()">
                            <span>작성</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>
        <!-- // header -->
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="board-list">
                        <li v-for="(equip, index) in equips.content" :key="index">
                            <div class="board-header">
                                <a @click="goEquipDetail(equip)">
                                    <div class="board-box">
                                        <p>
                                            {{ equip.today | formatTime }} 작업일보
                                            {{ basic.csAdminYn === 'Y' ? equip.company.name : '' }}
                                            {{ statusText(equip.status) }}
                                        </p>
                                        <div class="meta-info new_meta">
                                            <span class="charger new_charge">
                                                {{ equip.register.displayName }}
                                                &middot;
                                                {{ equip.register.company.name }}
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                    <div class="view-more-wrap">
                        <a v-if="equips.isMore" class="view-more" @click="more()"><span>더보기</span></a>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkDailyApi from '../../api/WorkDailyApi';
import { isWorkDailyAuth } from '../../helper/AuthHelper';
//import EventHelper from '../../helper/EventHelper';
import MenuHelper from '../../helper/MenuHelper';
import PageModel from '../../model/PageModel';

export default {
    name: 'WorkDailyList',
    components: {},
    data: () => {
        return {
            equips: new PageModel()
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            this.$router.go(-1);
        },
        isWorkDailyAuth,
        goRegist() {
            MenuHelper.workDailyRegist(this.$router);
        },
        goEquipDetail(equip) {
            MenuHelper.workDaily(this.$router, equip.id);
        },
        async doSearch() {
            const query = {
                ...this.equips.query()
            };
            const response = await WorkDailyApi.list(query);
            if (response) {
                //this.boards = new PageModel(response);
                this.equips.bind(response, true);
            }
        },
        more() {
            ++this.equips.page;
            this.doSearch();
        },
        statusText(status) {
            if (status === 'submit') return '';
            else if (status === 'temp') return ' (작성중)';
        }
    }
};
</script>

<template>
    <div>
        <header id="header" class="header primary">
            <div class="in-section">
                <ul>
                    <li class="back">
                        <button type="button" class="btn-back" @click="back()">
                            <span class="blind">뒤로</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>작업일보 작성</span>
                    </li>
                    <li class="etc auto">
                        <button type="button" class="btn-etc" @click="onSave('submit')">
                            <span class="submmit">{{ sumitText }}</span>
                            <span>제출</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body report">
                    <div class="write-form-wrap">
                        <div class="form-field">
                            <div class="label">
                                작업일보 일자
                            </div>
                            <div class="field">
                                <input v-model="workDaily.today" type="date" class="txt" @input="onChangeDate" />
                            </div>
                        </div>
                    </div>
                    <section class="tab-wrap">
                        <div class="report-nav tab-nav">
                            <ul>
                                <li :class="showTab('content') ? 'on' : ''">
                                    <a @click="onClickTab('content')">작업내용</a>
                                </li>
                                <li :class="showTab('occupation') ? 'on' : ''">
                                    <a @click="onClickTab('occupation')">출역현황</a>
                                </li>
                                <li :class="showTab('equip') ? 'on' : ''">
                                    <a @click="onClickTab('equip')">장비현황</a>
                                </li>
                            </ul>
                        </div>
                        <!-- 작업내용 -->
                        <div class="report-tab-contents tab-contents" :class="showTab('content') ? '' : 'hide'">
                            <div class="list-area">
                                <div class="list-header">
                                    <ul>
                                        <li>
                                            <a class="btn left" @click="onCopy()">
                                                <i class="ionicons ion-android-archive" />
                                                <span>이전 복사</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="btn" @click="bindDetail">
                                                <i class="ionicons ion-refresh" />
                                                <span>검색</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="btn blue" @click="onSave()">
                                                <span>저장</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="list-box">
                                    <div id="how" class="wbox">
                                        <div class="wbox-info">
                                            <dl>
                                                <dt
                                                    :class="showContent('pre') ? 'on' : ''"
                                                    @click="onClickContent('pre')"
                                                >
                                                    <div class="bar">
                                                        <div class="off bar-title">
                                                            전일 작업내용<i class="ionicons ion-arrow-down-b" />
                                                        </div>
                                                        <div class="on bar-title">
                                                            전일 작업내용<i class="ionicons ion-arrow-up-b" />
                                                        </div>
                                                    </div>
                                                </dt>
                                                <dd :class="showContent('pre') ? 'box-block' : 'box-none'">
                                                    <textarea
                                                        v-model="workDaily.preContent"
                                                        class="write-report-box"
                                                        readonly
                                                    />
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt
                                                    :class="showContent('today') ? 'on' : ''"
                                                    @click="onClickContent('today')"
                                                >
                                                    <div class="bar">
                                                        <div class="off bar-title gray1">
                                                            금일 작업내용<i class="ionicons ion-arrow-down-b" />
                                                        </div>
                                                        <div class="on bar-title gray1">
                                                            금일 작업내용<i class="ionicons ion-arrow-up-b" />
                                                        </div>
                                                    </div>
                                                </dt>
                                                <dd :class="showContent('today') ? 'box-block' : 'box-none'">
                                                    <textarea
                                                        v-model="workDaily.todayContent"
                                                        class="write-report-box"
                                                        placeholder="금일 작업일보 내용을 입력하세요."
                                                    />
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt
                                                    :class="showContent('next') ? 'on' : ''"
                                                    @click="onClickContent('next')"
                                                >
                                                    <div class="bar">
                                                        <div class="off bar-title gray2">
                                                            명일 작업내용<i class="ionicons ion-arrow-down-b" />
                                                        </div>
                                                        <div class="on bar-title gray2">
                                                            명일 작업내용<i class="ionicons ion-arrow-up-b" />
                                                        </div>
                                                    </div>
                                                </dt>
                                                <dd :class="showContent('next') ? 'box-block' : 'box-none'">
                                                    <textarea
                                                        v-model="workDaily.nextContent"
                                                        class="write-report-box"
                                                        placeholder="명일 작업일보 내용을 입력하세요."
                                                    />
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- // 작업내용 -->
                        <!-- 출역현황 -->
                        <div class="report-tab-contents tab-contents" :class="showTab('occupation') ? '' : 'hide'">
                            <div class="list-area">
                                <div class="list-header">
                                    <ul>
                                        <li>
                                            <a class="btn left" @click="onOccupation">
                                                <i class="ionicons ion-android-add-circle" /><span>행 추가</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="btn" @click="onClickDelOccupation">
                                                <i class="ionicons ion-android-remove-circle" /><span>행 삭제</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="btn blue" @click="onSave()"><span>저장</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="list-box">
                                    <div class="dre-header">
                                        <ul>
                                            <li>선택</li>
                                            <li>직종</li>
                                            <li>전일</li>
                                            <li>금일</li>
                                            <li>누계</li>
                                        </ul>
                                    </div>
                                    <ul class="dre-list">
                                        <li v-for="(occupation, index) in workDaily.occupations" :key="index">
                                            <div class="report-info-box">
                                                <div class="chk">
                                                    <input
                                                        :id="'report-chk-occupation-' + index"
                                                        v-model="checkOccupations"
                                                        type="checkbox"
                                                        :value="occupation.id.code.code"
                                                    />
                                                    <label :for="'report-chk-occupation-' + index">
                                                        <span class="blind">선택</span>
                                                    </label>
                                                </div>
                                                <div class="item-info">
                                                    <p class="nm">
                                                        <label :for="'report-chk-occupation-' + index">
                                                            {{ occupation.id.code.name }}
                                                        </label>
                                                    </p>
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        v-model="occupation.preCount"
                                                        type="text"
                                                        class="txt green"
                                                        readonly
                                                    />
                                                </div>
                                                <div class="num_data">
                                                    <input v-model="occupation.putCount" type="text" class="txt" />
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        v-model="occupation.preCountSum"
                                                        type="text"
                                                        class="txt yellow"
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="report-info-box total">
                                                <div class="item-info">
                                                    <p class="nm">
                                                        총계
                                                    </p>
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        type="text"
                                                        class="txt green border"
                                                        readonly
                                                        :value="calcOccupationTotal('pre')"
                                                    />
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        type="text"
                                                        class="txt border"
                                                        readonly
                                                        :value="calcOccupationTotal('put')"
                                                    />
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        type="text"
                                                        class="txt yellow border"
                                                        readonly
                                                        :value="calcOccupationTotal('sum')"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- // 출역현황 -->
                        <!-- 장비현황 -->
                        <div class="report-tab-contents tab-contents" :class="showTab('equip') ? '' : 'hide'">
                            <div class="list-area">
                                <div class="list-header">
                                    <ul>
                                        <li>
                                            <a class="btn left" @click="onEquip">
                                                <i class="ionicons ion-android-add-circle" /><span>행 추가</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="btn" @click="onClickDelEquip">
                                                <i class="ionicons ion-android-remove-circle" /><span>행 삭제</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="btn blue" @click="onSave()"><span>저장</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="list-box">
                                    <div class="dre-header">
                                        <ul>
                                            <li>선택</li>
                                            <li>장비(규격)</li>
                                            <li>전일</li>
                                            <li>금일</li>
                                            <li>누계</li>
                                        </ul>
                                    </div>
                                    <ul class="dre-list">
                                        <li v-for="(equip, index) in workDaily.equips" :key="index">
                                            <div class="report-info-box">
                                                <div class="chk">
                                                    <input
                                                        :id="'report-chk-equip-' + index"
                                                        v-model="checkEquips"
                                                        type="checkbox"
                                                        :value="equip.id.code.code"
                                                    />
                                                    <label :for="'report-chk-equip-' + index">
                                                        <span class="blind">선택</span>
                                                    </label>
                                                </div>
                                                <div class="item-info">
                                                    <p class="nm">
                                                        <label :for="'report-chk-equip-' + index">
                                                            {{ equip.id.code.name }}
                                                        </label>
                                                    </p>
                                                    <div class="meta-info">
                                                        <span>규격 :</span><span>{{ equip.id.code.spec }}</span>
                                                    </div>
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        v-model="equip.preCount"
                                                        type="text"
                                                        class="txt green"
                                                        readonly
                                                    />
                                                </div>
                                                <div class="num_data">
                                                    <input v-model="equip.putCount" type="text" class="txt" />
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        v-model="equip.preCountSum"
                                                        type="text"
                                                        class="txt yellow"
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="report-info-box total">
                                                <div class="item-info">
                                                    <p class="nm">
                                                        총계
                                                    </p>
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        type="text"
                                                        class="txt green border"
                                                        :value="calcEquipTotal('pre')"
                                                        readonly
                                                    />
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        type="text"
                                                        class="txt border"
                                                        :value="calcEquipTotal('put')"
                                                        readonly
                                                    />
                                                </div>
                                                <div class="num_data">
                                                    <input
                                                        type="text"
                                                        class="txt yellow border"
                                                        :value="calcEquipTotal('sum')"
                                                        readonly
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- // 장비현황 -->
                    </section>
                </div>
            </article>
        </main>
        <filter-equip ref="filterEquip" @setEquips="setEquips" />
        <filter-occupation ref="filterOccupation" @setOccupations="setOccupations" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkDailyApi from '../../api/WorkDailyApi';
import { isWorkDailyAuth } from '../../helper/AuthHelper';
import EventHelper from '../../helper/EventHelper';
import { toDayFormat, formatDate } from '../../utils/date';
import MenuHelper from '../../helper/MenuHelper';
import { confirmDialog } from '../../helper/DialogHelper';

import FilterEquip from './FilterEquip.vue';
import FilterOccupation from './FilterOccupation.vue';

export default {
    name: 'WorkDailyRegist',
    components: { FilterEquip, FilterOccupation },
    data: () => {
        return {
            wid: null,
            checkOccupations: [],
            checkEquips: [],
            totalEquip: { putCount: 0, preCountSum: 0, preCount: 0 },
            workDaily: { equips: [], occupations: [], today: toDayFormat(), status: null },
            selectedTab: 'content',
            selectedContent: { pre: false, today: true, next: false },
            sumitText: '[작성전입니다]'
        };
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {
        this.wid = this.$route.params.id;
        this.bindDetail();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            this.$router.go(-1);
        },
        isWorkDailyAuth,
        bindSubmitText() {
            if (this.workDaily.status === 'temp') this.sumitText = '[임시저장중입니다]';
            else if (this.workDaily.status === 'submit') this.sumitText = '';
            else this.sumitText = '[작성전입니다]';
        },
        async bindDetail() {
            if (this.wid) {
                this.workDaily = await WorkDailyApi.detail(this.wid);
            } else {
                this.workDaily = (await WorkDailyApi.detailByDay(this.workDaily.today)) || {
                    equips: [],
                    occupations: [],
                    today: this.workDaily.today,
                    status: null
                };
            }
            this.workDaily.today = formatDate(this.workDaily.today);
            this.bindSubmitText();

            this.$refs.filterOccupation.bind(this.workDaily.today);
            this.$refs.filterEquip.bind(this.workDaily.today);
        },
        onClickTab(value) {
            this.selectedTab = value;
        },
        showTab(value) {
            return this.selectedTab === value;
        },
        onClickContent(value) {
            this.selectedContent[value] = !this.selectedContent[value];
        },
        showContent(value) {
            return this.selectedContent[value];
        },
        setEquips(equips) {
            const targetRemove = this.workDaily.equips;
            const newItems = equips.filter(item => {
                if (targetRemove.findIndex(remove => remove.id.code.code === item.id.code.code) < 0) return item;
            });
            this.workDaily.equips.push(...newItems);
            equips = [];
        },
        setOccupations(occupations) {
            //이미 여기서 데이서 이전, 누적데이터가 필요함.
            const targetRemove = this.workDaily.occupations;
            const newItems = occupations.filter(item => {
                if (targetRemove.findIndex(remove => remove.id.code.code === item.id.code.code) < 0) return item;
            });
            this.workDaily.occupations.push(...newItems);
            occupations = [];
        },
        onEquip() {
            EventHelper.onEquip();
        },
        onOccupation() {
            EventHelper.onOccupation();
        },
        onChangeDate() {
            this.wid = null;
            this.bindDetail();
        },
        calcOccupationTotal(type) {
            if (type === 'pre') {
                return this.workDaily.occupations.reduce((sum, item) => sum + Number(item.preCount), 0);
            } else if (type === 'put') {
                return this.workDaily.occupations.reduce((sum, item) => sum + Number(item.putCount), 0);
            } else if (type === 'sum') {
                return this.workDaily.occupations.reduce((sum, item) => sum + Number(item.preCountSum), 0);
            }
        },
        calcEquipTotal(type) {
            if (type === 'pre') {
                return this.workDaily.equips.reduce((sum, item) => sum + Number(item.preCount), 0);
            } else if (type === 'put') {
                return this.workDaily.equips.reduce((sum, item) => sum + Number(item.putCount), 0);
            } else if (type === 'sum') {
                return this.workDaily.equips.reduce((sum, item) => sum + Number(item.preCountSum), 0);
            }
        },
        onClickDelOccupation() {
            if (this.checkOccupations.length === 0) {
                alert('삭제할 직종을 선택하세요.');
                return;
            }
            const targetRemove = this.workDaily.occupations;
            const newItems = targetRemove.filter(item => {
                if (this.checkOccupations.findIndex(remove => item.id.code.code === remove) < 0) return item;
            });
            this.workDaily.occupations = newItems;
            this.checkOccupations = [];
        },
        onClickDelEquip() {
            if (this.checkEquips.length === 0) {
                alert('삭제할 장비를 선택하세요.');
                return;
            }
            const targetRemove = this.workDaily.equips;
            const newItems = targetRemove.filter(item => {
                if (this.checkEquips.findIndex(remove => item.id.code.code === remove) < 0) return item;
            });
            this.workDaily.equips = newItems;
            this.checkEquips = [];
        },
        async onSave(status) {
            //console.log(this.workDaily);

            if (status === 'submit') {
                confirmDialog('작업일보를 제출하시겠습니까?', async () => {
                    this.workDaily.status = status;
                    const res = await WorkDailyApi.regist(this.workDaily);
                    if (res) {
                        MenuHelper.workDaily(this.$router);
                    }
                });
            } else {
                status = status || 'temp';
                this.workDaily.status = status;
                const response = await WorkDailyApi.regist(this.workDaily);
                this.workDaily.id = response.content.id;
                this.workDaily.status = response.content.status;
                //this.bindSubmitText();
                await this.bindDetail();
            }
        },
        async onCopy() {
            confirmDialog(
                '이전 작업일보를 복사를 하실 경우 만약 작성하신 작업내용이 있으면 삭제됩니다. 복사하시겠습니까?',
                async () => {
                    const res = await WorkDailyApi.copyPrevData(this.workDaily.today);
                    if (res) {
                        await this.bindDetail();
                    }
                }
            );
        }
    }
};
</script>

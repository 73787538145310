import JoinStep1 from '../components/join/JoinStep1';
import JoinStep2 from '../components/join/JoinStep2';
import JoinStep3 from '../components/join/JoinStep3';
import JoinDone from '../components/join/JoinDone';
import JoinSite from '../components/join/JoinSite';
import JoinLogin from '../components/join/JoinLogin';
import JoinSelectSite from '../components/join/JoinSelectSite';

export default [
    {
        path: '/join/step1/:csId',
        name: 'JoinStep1',
        component: JoinStep1,
        meta: {
            bodyClass: 'signup'
        }
    },
    {
        path: '/join/step2',
        name: 'JoinStep2',
        component: JoinStep2,
        meta: {
            bodyClass: 'signup'
        }
    },
    {
        path: '/join/step3',
        name: 'JoinStep3',
        component: JoinStep3,
        meta: {
            bodyClass: 'signup'
        }
    },
    {
        path: '/join/done',
        name: 'JoinDone',
        component: JoinDone,
        meta: {
            bodyClass: 'signup'
        }
    },
    {
        path: '/join/site/:csId',
        name: 'JoinSite',
        component: JoinSite,
        meta: {
            bodyClass: 'signup'
        }
    },
    {
        path: '/join/login/:csId',
        name: 'JoinLogin',
        component: JoinLogin,
        meta: {
            bodyClass: 'signup'
        }
    },
    {
        path: '/join/select',
        name: 'JoinSelectSite',
        component: JoinSelectSite,
        meta: {
            bodyClass: 'signup'
        }
    }
];

<template>
    <div class="filter-option">
        <div class="filter-option-container">
            <div class="filter-header">
                <div class="filter-title">
                    필터링 옵션
                </div>
                <div class="close-filter">
                    <button class="btn-close-filter">
                        <span class="blind">필터 닫기</span>
                    </button>
                </div>
            </div>

            <div class="filter-body">
                <div class="form-wrap">
                    <div class="field-box">
                        <div class="label">
                            검색어
                        </div>
                        <div class="field">
                            <input v-model="condition.content" type="text" class="txt" placeholder="내용검색" />
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            업무 상태
                        </div>
                        <div class="field">
                            <ul class="cnt3">
                                <li class="on">
                                    <a @click="setCondition('finishYn', '', $event)"><span>전체</span></a>
                                </li>
                                <li>
                                    <a @click="setCondition('finishYn', 'N', $event)"><span>조치중</span></a>
                                </li>
                                <li>
                                    <a @click="setCondition('finishYn', 'Y', $event)"><span>조치완료</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="divider" />

                    <div class="field-box period-field-box">
                        <div class="label">
                            조회기간
                        </div>
                        <div class="field">
                            <ul class="cnt4">
                                <li>
                                    <a @click="setPeriod('1week', $event)"><span>1주</span></a>
                                </li>
                                <li>
                                    <a @click="setPeriod('1month', $event)"><span>1개월</span></a>
                                </li>
                                <li>
                                    <a @click="setPeriod('3month', $event)">
                                        <span>3개월</span>
                                    </a>
                                </li>
                                <li>
                                    <a @click="setPeriod('', $event)"><span>선택</span></a>
                                </li>
                            </ul>

                            <div class="field-period">
                                <input ref="startData" v-model="condition.beginDay" type="date" class="start-data" />
                                <span>~</span>
                                <input ref="endData" v-model="condition.endDay" type="date" class="end-data" />
                            </div>
                        </div>
                    </div>

                    <div class="divider" />

                    <div class="field-box">
                        <div class="label">
                            등록자
                        </div>
                        <div class="field">
                            <div class="select-box">
                                <a class="select" @click="onRegister()">
                                    {{ register.name }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            담당자
                        </div>
                        <div class="field">
                            <div class="select-box">
                                <a class="select select-charger" @click="onManager">
                                    선택
                                </a>
                            </div>
                            <div v-if="managers.length > 0" class="selected-list">
                                <span v-for="(manager, index) in managers" :key="index">
                                    {{ manager.name }}·{{ manager.companyName }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="field-box">
                        <div class="label">
                            공종 태그
                        </div>
                        <div class="field">
                            <div class="select-box">
                                <a class="select select-tags" @click="onTag">
                                    선택
                                </a>
                            </div>
                            <div v-if="tags.length > 0" class="selected-list">
                                <span v-for="(tag, index) in tags" :key="index">#{{ tag }}</span>
                            </div>

                            <div class="btn-done-wrap">
                                <a @click="doSearch()"><span>적용하기</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toDayFormat, toDayFormatAddMonth, toDayFormatAddDay } from '../../utils/date';
import EventHelper from '../../helper/EventHelper';
export default {
    name: 'FilterOption',
    components: {},
    props: {
        condition: {
            type: Object,
            required: true
        },
        managers: {
            type: Array,
            required: true
        },
        tags: {
            type: Array,
            required: false,
            default: null
        },
        register: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {
        //console.log(toDayFormatAddMonth(-3));
    },
    beforeDestroy() {},
    methods: {
        doSearch() {
            this.condition.page = 1;
            this.$emit('doSearch');
            EventHelper.offFilter();
        },
        onRegister() {
            EventHelper.onRegister();
        },
        onManager() {
            EventHelper.onManager('search');
        },
        onTag() {
            EventHelper.onTag('search');
        },
        setPeriod(period, event) {
            this.classReset($(event.target));

            if (period === '') {
                this.condition.beginDay = '';
                this.condition.endDay = '';
            } else if (period === '1week') {
                this.condition.beginDay = toDayFormatAddDay(-7);
                this.condition.endDay = toDayFormat();
            } else if (period === '1month') {
                this.condition.beginDay = toDayFormatAddMonth(-1);
                this.condition.endDay = toDayFormat();
            } else if (period === '3month') {
                this.condition.beginDay = toDayFormatAddMonth(-3);
                this.condition.endDay = toDayFormat();
            }
            this.$refs.startData.value = this.condition.beginDay;
            this.$refs.endData.value = this.condition.endDay;
        },
        setCondition(attr, value, event) {
            this.classReset($(event.target));
            this.condition.payload[attr] = value;
            $('.subheader-list-box.filter ul li').removeClass('on');
        },
        classReset($target) {
            const $ahref = $target
                .parent()
                .parent()
                .find('li');

            const $span = $target
                .parent()
                .parent()
                .parent()
                .find('li');

            if ($ahref.length > 0) {
                $ahref.removeClass('on');
                $target.parent().addClass('on');
            } else {
                $span.removeClass('on');
                $target
                    .parent()
                    .parent()
                    .addClass('on');
            }
        }
    }
};
</script>

<template>
    <div id="ui-draw-nav-wrapper" v-touch:swipe.left="close" class="draw-nav-wrapper">
        <div class="draw-nav-box">
            <div class="logout">
                <a @click="goLogout"><span class="blind">로그아웃</span></a>
            </div>
            <!-- draw-nav-inbox -->
            <section class="draw-nav-inbox">
                <div class="area-info">
                    <a @click="showSiteToolTip">
                        <span class="nm">{{ basic.site }}</span>
                        <span ref="siteToolTip" class="tooltip">
                            현장을 변경할 경우 다시 메인에서 원하는 소속 현장을 선택하세요.
                        </span>
                    </a>
                </div>
                <div class="user-info">
                    <a @click="goMypage">
                        <span class="nm">
                            <strong>{{ basic.name }}</strong> {{ basic.position }}&middot;{{ basic.company }}
                        </span>
                        <span class="last-connected">마지막 접속 {{ lastAccess }}</span>
                    </a>
                </div>
            </section>
            <!-- // draw-nav-inbox -->
            <!-- draw-nav-inbox -->
            <section class="draw-nav-inbox">
                <ul class="quick-link">
                    <li>
                        <a class="myfolder" @click="goPictureRoom"><span>사진함</span></a>
                    </li>
                    <li>
                        <a class="notice" @click="goNotice"><span>공지사항</span></a>
                    </li>
                    <li>
                        <a class="report" @click="goWorkDaily"><span>작업일보</span></a>
                    </li>
                    <li>
                        <a class="alarm" @click="goAlarm"><span>알림</span></a>
                    </li>
                    <li>
                        <a class="setting" @click="goSetting"><span>설정</span></a>
                    </li>
                </ul>
            </section>
            <!-- // draw-nav-inbox -->

            <section v-if="basic.csAdminYn === 'Y'" class="draw-nav-inbox">
                <div class="draw-nav-inbox-title">
                    <p>회원요청 현황</p>
                </div>
                <ul class="my-task-list">
                    <li>
                        <a class="send-task" @click="goApproveWorker">
                            <span class="tit">가입승인</span>
                            <span class="cnt">
                                <strong>
                                    {{ count.approveWorker }}
                                </strong>
                                건 요청
                            </span>
                        </a>
                    </li>
                </ul>
            </section>

            <!-- draw-nav-inbox -->
            <section class="draw-nav-inbox">
                <div class="draw-nav-inbox-title">
                    <p>MY 그룹</p>
                    <span class="add-info">{{ rooms.length }}</span>
                </div>
                <ul class="mygroup-list">
                    <li v-for="(room, index) in rooms" :key="index">
                        <a @click="goRoom(room)">
                            <span class="title">{{ room.name }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="btn-add-group" @click="goRegistGroup()"><span>새로운 그룹 생성</span></a>
                    </li>
                </ul>
            </section>
            <!-- // draw-nav-inbox -->
            <!-- draw-nav-inbox -->
            <section class="draw-nav-inbox">
                <div class="draw-nav-inbox-title">
                    <p>업무 현황</p>
                    <span class="add-info">최근 1달 기준</span>
                </div>
                <ul class="my-task-list">
                    <li>
                        <a href="javascript:;" class="send-task">
                            <span class="tit">보낸 업무 중</span>
                            <span class="cnt">
                                <strong>
                                    {{ count.myWork.noActionCount }}
                                </strong>
                                건 미조치
                            </span>
                            <span class="cnt_done">
                                <strong>
                                    {{ count.myWork.finishCount }}
                                </strong>
                                건 완료
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" class="rcv-task">
                            <span class="tit">받은 업무 중</span>
                            <span class="cnt">
                                <strong>
                                    {{ count.todoWork.noActionCount }}
                                </strong>
                                건 미조치
                            </span>
                            <span class="cnt_done">
                                <strong>
                                    {{ count.todoWork.finishCount }}
                                </strong>
                                건 완료
                            </span>
                        </a>
                    </li>
                </ul>
            </section>
            <section class="draw-nav-inbox">
                <div class="draw-nav-inbox-title">
                    <p>앱 공유 및 기술문의</p>
                </div>
                <ul class="mygroup-list">
                    <li>
                        <a class="send-task" @click="shareJoinLink">
                            <span class="tit">APP 다운로드 URL 카톡 공유</span>
                        </a>
                    </li>
                    <li>
                        <a @click="goQna">
                            <span class="tit">기술문의</span>
                        </a>
                    </li>
                </ul>
            </section>
            <!-- // draw-nav-inbox -->
            <!-- draw-footer -->
            <div class="draw-footer">
                <p>Copyright © DAEWOO ENGINEERING & CONSTRUCTION Co., Ltd. All rights reserved.</p>
            </div>
            <!-- // draw-footer -->
            <div v-touch:tap="close" class="draw-nav-close">
                <a @click="close()"><span class="blind">닫기</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EventHelper from '../../helper/EventHelper';
import MenuHelper from '../../helper/MenuHelper';
import KakaoHelper from '../../helper/KakaoHelper';

export default {
    name: 'Nav',
    components: {},
    data: () => {
        return {};
    },
    computed: {
        ...mapState({
            count: state => state.main.count,
            basic: state => state.main.basic,
            rooms: state => state.work.roomList,
            lastAccess: state => state.main.lastAccess
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['logout']),
        close() {
            EventHelper.offNavMenu();
        },
        goNotice() {
            MenuHelper.notice(this.$router);
        },
        goQna() {
            MenuHelper.qna(this.$router);
        },
        goWorkDaily() {
            MenuHelper.workDaily(this.$router);
        },
        goAlarm() {
            MenuHelper.alarm(this.$router);
        },
        goRegistGroup() {
            MenuHelper.registRoomNoHistory(this.$router);
        },
        goSetting() {
            MenuHelper.setting(this.$router);
        },
        goLogout() {
            this.logout().then(() => {
                this.$router.push({ path: '/login' });
            });
        },
        goHome() {
            this.$router.push({ path: '/' });
            this.close();
        },
        goMypage() {
            MenuHelper.mypage(this.$router);
        },
        goRoom(room) {
            MenuHelper.room(this.$router, room.id);
            this.close();
        },
        goApproveWorker() {
            MenuHelper.approveWorker(this.$router);
        },
        shareJoinLink() {
            //alert(this.basic.csId);
            KakaoHelper.appShareLink('사진으로 협업한다! COCO 모바일앱', 'COCO 모바일앱 설치 및 회원가입');
        },
        goPictureRoom() {
            MenuHelper.pictureRoom(this.$router);
        },
        showSiteToolTip() {
            this.$refs.siteToolTip.style.display = 'block';
            setTimeout(() => {
                this.$refs.siteToolTip.style.display = 'none';
            }, 1500);
        }
        //메뉴이동은 메뉴helper를 이용하여
    }
};
</script>

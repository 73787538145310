<template>
    <div>
        <Header loc-nm="설정" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="setting-list">
                        <li>
                            <div class="setting-item">
                                <p>{{ basic.name }} {{ basic.position }}</p>
                                <div class="setting-sub">
                                    <span>{{ basic.company }}</span>
                                    <span>{{ basic.asteriskPhone }}</span>
                                </div>
                            </div>
                            <div class="action">
                                <a @click="goLogout()"><span>로그아웃</span></a>
                            </div>
                        </li>
                        <li>
                            <div class="setting-item">
                                <p>자동 로그인 설정</p>
                                <div class="setting-sub">
                                    <span>COCO 모바일앱의 간편 로그인을 설정하세요.</span>
                                </div>
                            </div>
                            <div class="action">
                                <div class="switch">
                                    <input
                                        id="chk-1-1"
                                        v-model="appsetting.autoYn"
                                        type="checkbox"
                                        class="blind"
                                        @change="saveSetting()"
                                    />
                                    <label for="chk-1-1"><span class="blind">On</span></label>
                                    <div class="radius-select" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="setting-item">
                                <p>업무 처리 알림 설정</p>
                                <div class="setting-sub">
                                    <span>업무 요청/조치 처리/종료 알림을 받아보세요.</span>
                                </div>
                            </div>
                            <div class="action">
                                <div class="switch">
                                    <input
                                        id="chk-1-2"
                                        v-model="appsetting.workYn"
                                        type="checkbox"
                                        class="blind"
                                        @change="saveSetting()"
                                    />
                                    <label for="chk-1-2"><span class="blind">On</span></label>
                                    <div class="radius-select" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="setting-item">
                                <p>공지 알림 설정</p>
                                <div class="setting-sub">
                                    <span>공지 및 그룹 생성/초대 알림을 받아보세요.</span>
                                </div>
                            </div>
                            <div class="action">
                                <div class="switch">
                                    <input
                                        id="chk-1-3"
                                        v-model="appsetting.noticeYn"
                                        type="checkbox"
                                        class="blind"
                                        @change="saveSetting()"
                                    />
                                    <label for="chk-1-3"><span class="blind">On</span></label>
                                    <div class="radius-select" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="setting-item">
                                <p>이용약관</p>
                                <div class="setting-sub">
                                    <span>COCO 모바일앱 이용약관을 확인하세요.</span>
                                </div>
                            </div>
                            <div class="action">
                                <a @click="menuPolicy()"><span>보기</span></a>
                            </div>
                        </li>
                        <li>
                            <div class="setting-item">
                                <p>개인정보처리방침</p>
                                <div class="setting-sub">
                                    <span>COCO 모바일앱 개인정보처리방침을 확인하세요.</span>
                                </div>
                            </div>
                            <div class="action">
                                <a @click="menuPrivacy()"><span>보기</span></a>
                            </div>
                        </li>

                        <li>
                            <div class="setting-item">
                                <p>[{{ basic.site }}] 현장탈퇴</p>
                                <div class="setting-sub">
                                    <span>잘못 가입한 경우 해당 현장을 탈퇴할 수 있습니다.</span>
                                </div>
                            </div>
                            <div class="action">
                                <a @click="siteOut()"><span>탈퇴</span></a>
                            </div>
                        </li>
                        <li>
                            <div class="setting-item">
                                <p>앱 버전 v{{ version }}</p>
                                <div class="setting-sub">
                                    <span>현재 사용중인 COCO 모바일앱 버전입니다.</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/SimpleHeader.vue';
import MobileHelper from '../../helper/MobileHelper';
import MenuHelper from '../../helper/MenuHelper';

import AppSetting from '../../api/AppSetting';
import WorkerApi from '../../api/WorkerApi';

import { WORKER_ID } from '../../const';

import { confirmDialog } from '../../helper/DialogHelper';

export default {
    name: 'SelectSite',
    components: { Header },
    data: () => {
        return {
            version: process.env.VERSION,
            appsetting: {
                autoYn: true,
                workYn: true,
                noticeYn: true
            }
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.bind();
        if (MobileHelper.isWebView()) {
            this.version = MobileHelper.getAppVersion();
        }
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['logout']),
        menuPolicy() {
            MenuHelper.policy(this.$router);
        },
        menuPrivacy() {
            MenuHelper.privacy(this.$router);
        },
        async bind() {
            const setting = await AppSetting.detail(this.basic.id || localStorage.getItem(WORKER_ID));
            this.appsetting = {
                autoYn: setting.autoYn === 'Y' ? true : false,
                workYn: setting.workYn === 'Y' ? true : false,
                noticeYn: setting.noticeYn === 'Y' ? true : false
            };
        },
        async saveSetting() {
            const setting = {
                worker: this.basic.id,
                autoYn: this.appsetting.autoYn ? 'Y' : 'N',
                workYn: this.appsetting.workYn ? 'Y' : 'N',
                noticeYn: this.appsetting.noticeYn ? 'Y' : 'N'
            };
            await AppSetting.upsert(setting);
        },
        goLogout() {
            this.logout().then(() => {
                this.$router.push({ path: '/login' });
            });
        },
        async siteOut() {
            confirmDialog(this.basic.site + '을 탈퇴 하시겠습니까?', async () => {
                const res = await WorkerApi.siteLeave(this.basic.id);
                if (res.content) {
                    this.logout().then(() => {
                        this.$router.push({ path: '/login' });
                    });
                }
            });
        }
    }
};
</script>

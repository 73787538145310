import AppDevice from '../api/AppDevice';
import LogApi from '../api/LogApi';
import router from '../router';
import store from '../store';

import { alertDialog } from './DialogHelper';

const PREFIX_APP_AGENT = 'CoCoDSCOSM';
const checkWebVersion = process.env.VERSION;

const isWebView = () => {
    const broswerInfo = navigator.userAgent;
    return broswerInfo.indexOf(PREFIX_APP_AGENT) > -1 ? true : false;
};

const getAppVersion = () => {
    const broswerInfo = navigator.userAgent;
    return broswerInfo.substring(broswerInfo.indexOf(PREFIX_APP_AGENT + '/') + PREFIX_APP_AGENT.length + 1);
};

const getWebViewPlatform = () => {
    if (isWebView()) {
        return getPlatform();
    }
    return false;
};

const getPlatform = () => {
    const broswerInfo = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(broswerInfo)) {
        return 'window';
    }
    if (/android/i.test(broswerInfo)) {
        return 'android';
    } // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(broswerInfo) && !window.MSStream) {
        return 'ios';
    }
    return 'unknown';
};

const onDeviceReady = () => {
    const platform = getPlatform();
    const version = getAppVersion();

    if (checkWebVersion !== window.webVersion) {
        window.CacheClear(() => {
            alertDialog('버전이 업데이트 되었습니다.\n앱을 재실행하십시오');
            window.cordova.plugins.exit();
        });
        return;
    }

    if (store.state.main.basic.id > 0) {
        if (platform === 'ios') {
            document.addEventListener(
                'screenshotDidTake',
                function() {
                    window.alert('Screenshot');
                },
                false
            );
        }

        const CoCoNotification = window.PushNotification;
        if (CoCoNotification) {
            const push = CoCoNotification.init({
                android: {
                    forceShow: false,
                    clearBadge: true
                },
                ios: {
                    badge: true,
                    clearBadge: true
                }
            });
            if (push) {
                push.on('registration', function(data) {
                    const pushParam = {
                        deviceId: window.device.uuid,
                        platform,
                        registrationId: data.registrationId,
                        version,
                        model: window.device.model
                    };
                    //registration 모든 알림에 대해서 다 읽은 것으로 표시한다.
                    AppDevice.upsert(pushParam);
                    //우리는 배지개수를
                    //PushApi.clearBadge(pushParam).catch(e => this.$store.commit(MutationTypes.ERROR, e));
                });
                push.on('notification', data => {
                    const pushData = data.additionalData;
                    if (platform === 'ios') {
                        if (pushData.link && pushData.foreground && (pushData.message || data.message)) {
                            alertDialog(pushData.message || data.message);
                            return;
                        }
                    }
                    //console.log(pushData);
                    if (pushData.link) {
                        router.push({ path: pushData.link });
                    }
                });
            }
        }
    }
};
const doLastAccess = () => {
    const param = {
        appVer: isWebView() ? getAppVersion() : process.env.VERSION,
        platform: getPlatform(),
        userAgent: navigator.userAgent
    };
    LogApi.accessLog(param);
};

const isWifi = () => {
    if (navigator.connection) {
        const networkState = navigator.connection.type;
        const Connection = window.Connection;
        return networkState === Connection.WIFI ? true : false;
    }
    return true;
};

const checkUpload = () => {
    if (isWifi()) {
        return true;
    }
    if (confirm('개인 데이터 사용으로 업로드하시겠습니까?')) return true;
    else return false;
};

const checkDownload = () => {
    if (isWifi()) {
        if (confirm('파일을 다운로드하시겠습니까?')) return true;
        return false;
    } else {
        if (confirm('개인 데이터 사용으로 다운로드하시겠습니까?')) return true;
        else return false;
    }
};

export default {
    isWebView,
    getAppVersion,
    getWebViewPlatform,
    getPlatform,
    onDeviceReady,
    doLastAccess,
    isWifi,
    checkUpload,
    checkDownload
};

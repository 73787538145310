<template>
    <div>
        <Header />
        <div id="pullrefresh">
            <section class="top-blue-box welcome-area">
                <div class="in-section left">
                    <div class="user-info-box">
                        <div class="profile">
                            <div class="profile_thumb">
                                <img
                                    :src="basic.imgUrl | profileThumbUrl"
                                    onerror="javascript:this.src='/images/main/profile.jpg'"
                                    @click="menuMypage"
                                />
                            </div>
                        </div>
                        <p>
                            <strong>
                                {{ basic.name }}
                            </strong>
                            {{ basic.position }}님, 안전한 하루 되세요!
                        </p>
                    </div>
                    <div class="top-blue-info-box">
                        <div :class="weatherIcon()">
                            <span class="blind">{{ weather.sky }}</span>
                        </div>
                        <div class="weather-info">
                            <span>{{ weather.sky }} · {{ weather.tmn }}℃ / {{ weather.tmx }}℃</span>
                            <span>미세먼지·{{ weather.pmvText }} {{ weather.pmv }}㎍/㎥</span>
                        </div>
                    </div>
                </div>
            </section>
            <!-- // welcome-area -->
            <main id="content-wrap">
                <article id="contents">
                    <div class="contents-body">
                        <!-- 공지사항 -->
                        <notice-ticker :boards="board.notice" />
                        <!-- // 공지사항 -->

                        <work-list
                            wtitle="보낸 업무"
                            wtype="my"
                            :count="count.myWork.noActionCount"
                            :works="work.my || []"
                        />
                        <work-list
                            wtitle="받은 업무"
                            wtype="todo"
                            :count="count.todoWork.noActionCount"
                            :works="work.todo || []"
                        />
                        <room-list />
                    </div>
                </article>
            </main>
        </div>

        <input id="gallery" ref="uploadGallery" type="file" class="blind" accept="image/*" @change="doGalleryAttach" />
        <footer id="main-footer">
            <ul>
                <li>
                    <a @click="menuRegistRoom"><span class="blind">그룹 생성</span></a>
                </li>
                <li>
                    <a @click="cameraPicker"><span class="blind">사진 촬영</span></a>
                </li>
                <li>
                    <a @click="imagePicker"><span class="blind">앨범 선택</span></a>
                </li>
                <li>
                    <a @click="goPictureRoom"><span class="blind">사진함</span></a>
                </li>
            </ul>
        </footer>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Header from './header/MainHeader.vue';
import ImagePickerHelper from '../helper/ImagePickerHelper';

import NoticeTicker from './main/NoticeTicker.vue';
import WorkList from './main/WorkList.vue';
import RoomList from './main/RoomList.vue';

//import AlertModal from './modal/AlertModal.vue';
import MobileHelper from '../helper/MobileHelper';
import MenuHelper from '../helper/MenuHelper';
import { exitDialog } from '../helper/DialogHelper';

import { WEATHER_SKY, TOP_DEFAULT_CONFIG } from '../const';

import FileApi from '../api/FileApi';
import PictureRoomApi from '../api/PictureRoomApi';

import PullToRefresh from 'pulltorefreshjs';

import CameraHelper from '../helper/CameraHelper';
//import GpsHelper from '../helper/GpsHelper';
import { PICTURE_SORCE_TYPE, MAX_IMG_COUNT } from '../const';

export default {
    name: 'Main',
    components: { Header, NoticeTicker, WorkList, RoomList },
    data: () => {
        return {
            workId: 0,
            isMain: true,
            isWorkDetail: false,
            rooms: [],
            topConfig: TOP_DEFAULT_CONFIG,
            isTopBounce: false
        };
    },
    computed: {
        ...mapState({
            count: state => state.main.count,
            board: state => state.main.board,
            work: state => state.main.work || { my: [], todo: [] },
            weather: state => state.main.weather,
            roomList: state => state.work.roomList,
            basic: state => state.main.basic
        })
    },
    beforeCreate() {
        ///////////////////////
        // Initializer Area
        ///////////////////////
        //this.showLoading();
        //this.$store.dispatch('showLoading');
    },
    created() {},
    mounted() {
        //this.$refs.alert.showModal();

        //alert(MobileHelper.getAppVersion());

        this.getWork();

        this.getWeather();
        this.getCount();
        this.getBoard();
        //this.$refs.alert.setConfirm(this.testConfirmB);

        setTimeout(() => {
            this.$store.dispatch('hideLoading');
            PullToRefresh.init({
                mainElement: '#pullrefresh',
                onRefresh: () => {
                    //window.location.reload();
                    this.refresh();
                }
            });
        }, 500);
        document.addEventListener('backbutton', this.backbtn, false);
        window.addEventListener('scroll', this.scrollPosition);
    },
    beforeDestroy() {
        document.removeEventListener('backbutton', this.backbtn, false);
        window.removeEventListener('scroll', this.scrollPosition);
        PullToRefresh.destroyAll();
    },
    methods: {
        ...mapActions(['getWork', 'getWeather', 'getCount', 'getBoard', 'showLoading']),
        scrollPosition() {
            const _documentY = document.documentElement.scrollTop;
            if (_documentY <= 30) {
                this.isTopBounce = true;
            } else this.isTopBounce = false;
        },
        refresh() {
            this.getWork();
            this.getWeather();
            this.getCount();
            this.getBoard();
        },
        backbtn() {
            if (this.$route.name === 'Login' || this.$route.name === 'Main') {
                exitDialog();
            } else this.$router.go(-1);
        },
        weatherIcon() {
            return WEATHER_SKY[this.weather.sky];
        },
        menuRegistRoom() {
            MenuHelper.registRoom(this.$router);
        },
        /*
        doGalleryAttach() {
            //this.showLoading();
            const fileObject = this.$refs.uploadGallery.files[0];
            this.fileName = fileObject.name;
            if (fileObject) {
                const formData = new FormData();
                formData.append('file_id', '');
                formData.append('file', fileObject);
                FileApi.imgUpload(formData).then();
            }
        },
        */
        imageEditor() {
            this.$router.push('/imageEditor/' + 0);
        },
        imagePicker() {
            if (MobileHelper.isWebView()) {
                ImagePickerHelper.getPictures(this.doImagePickerAttach, MAX_IMG_COUNT);
            } else {
                document.getElementById('gallery').click();
            }
            //document.getElementById('gallery').click();
        },
        async doGalleryAttach() {
            const fileObjectList = this.$refs.uploadGallery.files;

            let fileNo = 0;
            const imgList = [];

            for (var fileObject of fileObjectList) {
                const reader = new FileReader();

                reader.onload = e => {
                    ++fileNo;

                    const addFile = {
                        fileNo: fileNo,
                        name: fileObject.name,
                        type: 'file',
                        dataUrl: e.target.result,
                        data: fileObject
                    };
                    imgList.push(addFile);
                };
                reader.readAsDataURL(fileObject);
            }
            MenuHelper.addWork(this.$router, 0, imgList);
            /*
            const fileObject = this.$refs.uploadGallery.files[0];
            //console.log(fileObject)
            if (fileObject) {
                this.addImgFileList(fileObject);
            }
            */
        },
        async doImagePickerAttach(fileUrlList) {
            this.showLoading();
            let fileNo = 0;
            const imgList = [];
            for (var fileUrl of fileUrlList) {
                const blob = CameraHelper.base64ToBlob(fileUrl);
                const now = new Date();
                const imageName = now.getTime() + '.jpg';
                const addFile = {
                    fileNo: ++fileNo,
                    name: imageName,
                    type: 'blob',
                    dataUrl: fileUrl,
                    data: blob
                };
                imgList.push(addFile);
            }
            MenuHelper.addWork(this.$router, 0, imgList);
        },
        menuMypage() {
            MenuHelper.mypage(this.$router);
        },
        cameraPicker() {
            //document.getElementById('camera').click();
            if (MobileHelper.isWebView()) {
                CameraHelper.getPicture(PICTURE_SORCE_TYPE.CAMERA, this.cameraImage, () => {
                    //this.$router.go(-1);
                });
            } //else {
            //  document.getElementById('camera').click();
            //}
        },
        cameraImage(fileUrl) {
            const now = new Date();
            /* camera는 jpg라 별 상관이 없겠군 */
            const imageName = now.getTime() + '.jpg';
            const blob = CameraHelper.base64ToBlob(fileUrl);
            const addFile = {
                fileNo: 1,
                name: imageName,
                type: 'blob',
                dataUrl: fileUrl,
                data: blob
            };
            const imgList = [];
            imgList.push(addFile);
            MenuHelper.addWork(this.$router, 0, imgList);
        },
        async doDirImageAttach() {
            //this.showLoading();
            const fileObjectList = this.$refs.uploadGallery.files;
            for (var fileObject of fileObjectList) {
                this.fileName = fileObject.name;
                if (fileObject) {
                    const formData = new FormData();
                    formData.append('file_id', '');
                    formData.append('file', fileObject);
                    const res = await FileApi.imgUpload(formData);
                    const fileId = res.content.fileId;
                    await PictureRoomApi.regist({
                        workerId: this.basic.id,
                        fileId
                    });
                }
            }
        },
        goPictureRoom() {
            MenuHelper.pictureRoom(this.$router);
        }
    }
};
/*
#wrap > div {
    height: inherit;
}
*/
</script>

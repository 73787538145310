<template>
    <div>
        <Header :loc-nm="roomName" />
        <filter-header :condition="condition" @doSearch="doSearch" />
        <main id="content-wrap">
            <article id="contents" ref="mainContent">
                <room-item :works="workOfRoom.works" @more="more" />
            </article>
        </main>
        <div class="comment-write" :class="workWriteClass">
            <div v-if="show.photoUtil" class="photo-util">
                <input
                    id="gallery"
                    ref="uploadGallery"
                    type="file"
                    class="blind"
                    accept="image/*"
                    @change="doGalleryAttach"
                />

                <input
                    id="camera"
                    ref="uploadCamera"
                    type="file"
                    accept="image/*"
                    capture="camera"
                    class="blind"
                    @change="doCamera"
                />

                <button class="btn-camera" @click="cameraPicker">
                    <span class="blind">카메라</span>
                </button>
                <button class="btn-gallery" @click="imagePicker">
                    <span class="blind">갤러리</span>
                </button>
            </div>
            <div class="field-wrap">
                <label for="comment-memo" class="blind">요청업무 또는 토그</label>
                <textarea
                    id="comment-memo"
                    ref="commentMemo"
                    v-model="writeData.content"
                    placeholder="요청 업무나 토크를 입력하세요."
                    onkeyup="this.style.height='38px'; this.style.height = this.scrollHeight + 'px';"
                    @input="ingContent"
                />
                <button type="button" class="btn-comment-done" @click="registWork">
                    <span class="blind">완료</span>
                </button>

                <!-- 20200527 첨부파일 추가내용 v-if="show.mentionHashList" ref="commentfile" 는 참고로 넣었음 -->
                <a v-if="show.mentionHashList" ref="commentfile" class="comment-file" @click="pickerFile">
                    <span>+</span>
                </a>
                <!-- 20200527 첨부파일 추가내용 //-->

                <a v-if="show.mentionHashList" ref="commentMention" class="comment-mention" @click="popManager()">
                    <span>@</span>
                </a>
                <a v-if="show.mentionHashList" ref="commentHash" class="comment-hash" @click="popTag()">
                    <span>#</span>
                </a>
            </div>
            <div
                v-if="show.mentionHashList && (writeData.all.length > 0 || addFileList.length > 0)"
                class="mention-hash-list"
            >
                <ul>
                    <li v-for="(data, index) in writeData.all" :key="index" v-html="data" />
                </ul>
                <ul class="file">
                    <li v-for="(fileInfo, index) in addFileList" :key="index">
                        {{ fileInfo.name }}<button class="btn_remove" @click="onClickFileDel(fileInfo)" />
                    </li>
                </ul>
            </div>

            <input
                id="docFile"
                ref="docFile"
                type="file"
                :accept="DOC_ALLOW_FILE"
                class="blind"
                @change="doDocFileAttach"
            />
        </div>

        <filter-option
            :condition="condition"
            :managers="managers"
            :tags="tags"
            :register="register"
            @doSearch="doSearch"
        />
        <filter-register @setRegister="setRegister" />
        <filter-manager tp-id="search" @setManager="setManager" />
        <filter-tags tp-id="search" @setTags="setTags" />

        <filter-manager tp-id="write" @setManager="setWriteManager" />
        <filter-tags tp-id="write" @setTags="setWriteTags" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/RoomHeader.vue';

import FilterHeader from './FilterHeader.vue';
import FilterOption from './FilterOption.vue';
import FilterManager from './FilterManager.vue';
import FilterRegister from './FilterRegister.vue';
import FilterTags from './FilterTags.vue';

import RoomItem from './RoomItem.vue';

import WorkRoomApi from '../../api/WorkRoomApi';
import FileApi from '../../api/FileApi';
import WorkApi from '../../api/WorkApi';

import WorkCondition from '../../model/WorkCondition';
//import { toDayFormat, toDayFormatAddMonth } from '../../utils/date';
import EventHelper from '../../helper/EventHelper';
import MenuHelper from '../../helper/MenuHelper';

import MobileHelper from '../../helper/MobileHelper';
import ImagePickerHelper from '../../helper/ImagePickerHelper';

import PullToRefresh from 'pulltorefreshjs';

//import { DOC_ALLOW_FILE } from '../../const';
import ArrayHelper from '../../helper/ArrayHelper';
import FileHelper from '../../helper/FileHelper';

import CameraHelper from '../../helper/CameraHelper';

import { PICTURE_SORCE_TYPE, MAX_IMG_COUNT } from '../../const';
import MutationTypes from '../../store/MutationTypes';

export default {
    name: 'Room',
    components: { Header, FilterHeader, RoomItem, FilterOption, FilterRegister, FilterManager, FilterTags },
    data: () => {
        return {
            roomId: 0,
            roomName: '',
            works: [],
            condition: new WorkCondition(),
            tags: [],
            managers: [],
            register: {
                name: '선택'
            },
            writeData: {
                room: {},
                content: '',
                managers: [],
                tags: [],
                all: []
            },
            workWriteClass: '',
            show: {
                mentionHashList: false,
                photoUtil: true
            },
            addFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            docfileId: 0,
            docfileNo: 0
        };
    },
    computed: {
        ...mapState({
            workOfRoom: state => state.work.workOfRoom,
            basic: state => state.main.basic
            //rooms: state => state.work.roomList
        })
    },
    created() {},
    mounted() {
        //this.$refs.uploadGallery.focus();
        this.roomId = Number(this.$route.params.roomId);
        this.condition.roomId = this.roomId;
        //this.condition.beginDay = ''; //toDayFormatAddMonth(-3);
        //this.condition.endDay = ''; //toDayFormat();
        this.condition.page = this.workOfRoom.page;
        this.$refs.commentMemo.focus();
        this.$refs.commentMemo.blur();
        WorkRoomApi.detail(this.roomId).then(res => {
            this.roomName = res.name;
            this.room = res;
        });
        this.condition.roomId = this.roomId;
        this.doSearch();

        PullToRefresh.init({
            mainElement: '#content-wrap #contents',
            onRefresh: () => {
                //window.location.reload();
                this.doSearch();
                //alert('a');
            }
        });
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getWorkOfRoom', 'addWork', 'showLoading', 'hideLoading']),
        pickerFile() {
            if (FileHelper.isCount(this.addFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    ++this.docfileNo;
                    const formData = new FormData();
                    formData.append('file_id', '');
                    formData.append('file_no', this.docfileNo);
                    formData.append('file', fileObject);
                    const addFile = {
                        fileNo: this.docfileNo,
                        name: fileObject.name,
                        form: formData
                    };
                    this.addFileList.push(addFile);
                }
            }
        },
        onClickFileDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        async doSearch() {
            //console.log();
            this.$store.commit(MutationTypes.SET_SEARCH_CONDITION, this.condition.clonePayload());

            if (this.condition.page === 1) $('html').scrollTop(0);
            //console.log(this.condition.getPayload());

            await this.getWorkOfRoom(this.condition.getPayload());

            const joinId = { csId: this.basic.csId, workerId: this.basic.id, workRoomId: this.room.id };
            //console.log(this.workOfRoom.works[0]);
            if (this.workOfRoom.works[0])
                WorkApi.updateReadWork({ id: joinId, listWorkId: this.workOfRoom.works[0].id });

            this.hideLoading();
        },
        more() {
            this.condition.page = this.condition.page + 1;
            this.doSearch();
        },
        setManager(workers) {
            this.managers = workers;
            this.condition.managerIds = this.managers.map(worker => worker.id);
        },
        setTags(tags) {
            this.tags = tags;
            this.condition.tags = this.tags;
        },
        setRegister(register) {
            this.register = register;
            this.condition.registerId = this.register.id;
        },
        ingContent() {
            if (this.writeData.content > '') {
                this.show.mentionHashList = true;
                this.show.photoUtil = false;
                this.workWriteClass = 'mention-hash mention-hash-done';
            } else {
                this.show.mentionHashList = false;
                this.show.photoUtil = true;
                this.workWriteClass = '';
            }
            this.$refs.commentMemo.focus();
        },
        popManager() {
            EventHelper.onManager('write');
            this.$refs.commentMention.classList.add('on');
            this.$refs.commentHash.classList.remove('on');
        },
        popTag() {
            EventHelper.onTag('write');
            this.$refs.commentMention.classList.remove('on');
            this.$refs.commentHash.classList.add('on');
        },
        setWriteManager(workers) {
            this.writeData.managers = workers;
            this.writeData.manager = workers.map(worker => worker.id);
            const add = workers.map(worker => '@' + worker.name + '&middot;' + worker.companyName);
            this.writeData.all.push(...add);
            this.$refs.commentMemo.focus();
        },
        setWriteTags(tags) {
            this.writeData.tags = tags;
            this.writeData.tag = tags;
            const add = tags.map(tag => '#' + tag);
            this.writeData.all.push(...add);
            //this.$refs.commentMemo.focus();
        },
        isShowMention() {
            return this.writeData.managers.length > 0 || this.writeData.tags.tags > 0;
        },
        async registWork() {
            if (this.writeData.content && this.writeData.content.trim()) {
                this.docfileNo = 0;
                //if(!MobileHelper.isWifi() && )
                if (this.addFileList.length > 0) {
                    //  if(!MobileHelper.isWifi())
                    if (MobileHelper.checkUpload()) {
                        this.showLoading();
                        for (var docFile of this.addFileList) {
                            const docFileForm = docFile.form;
                            if (this.docFileId > 0) docFileForm.set('file_id', this.docFileId);
                            docFileForm.set('file_no', ++this.docfileNo);
                            const fileRes = await FileApi.fileUpload(docFileForm, 'doc');
                            if (fileRes.content) {
                                this.docFileId = fileRes.content[0].id.fileId;
                                this.writeData.docFileId = this.docFileId;
                            }
                        }
                        this.hideLoading();
                    } else return;
                }

                this.docFileId = 0;
                this.docfileNo = 0;
                this.addFileList = [];

                this.writeData.room.id = this.roomId;
                this.writeData.type = this.writeData.managers.length > 0 ? 'work' : 'talk';
                this.writeData.finishYn = 'N';
                await this.addWork(this.writeData);

                EventHelper.onScrollATop();

                this.writeData.content = '';
                this.writeData.room = {};
                this.writeData.type = '';
                this.writeData.managers = [];
                this.writeData.tags = [];
                this.writeData.all = [];
                this.writeData.docFileId = null;
                this.isShowMention();
                this.ingContent();
                window.stompClient.send(
                    '/app/chat.message/' + this.room.id,
                    JSON.stringify({ roomId: this.roomId }),
                    {}
                );
                const joinId = { csId: this.basic.csId, workerId: this.basic.id, workRoomId: this.room.id };
                WorkApi.updateReadWork({ id: joinId, listWorkId: this.workOfRoom.works[0].id });
            }
        },
        doGalleryAttach() {
            /*
            const fileObject = this.$refs.uploadGallery.files[0];
            this.fileName = fileObject.name;
            if (fileObject) {
                const formData = new FormData();
                formData.append('file_id', '');
                formData.append('file', fileObject);
                FileApi.imgUpload(formData).then(res => {
                    MenuHelper.addWork(this.$router, this.roomId, res.content.fileId);
                });
            }
            */
        },
        cameraPicker() {
            if (MobileHelper.isWebView()) {
                CameraHelper.getPicture(PICTURE_SORCE_TYPE.CAMERA, this.cameraImage, () => {
                    //this.$router.go(-1);
                });
            } else {
                document.getElementById('camera').click();
            }
        },
        cameraImage(fileUrl) {
            const now = new Date();
            /* camera는 jpg라 별 상관이 없겠군 */
            const imageName = now.getTime() + '.jpg';
            const blob = CameraHelper.base64ToBlob(fileUrl);
            const addFile = {
                fileNo: 1,
                name: imageName,
                type: 'blob',
                dataUrl: fileUrl,
                data: blob
            };
            const imgList = [];
            imgList.push(addFile);
            MenuHelper.addWork(this.$router, this.roomId, imgList);
        },
        doCamera() {
            this.selectImage();
        },
        imagePicker() {
            if (MobileHelper.isWebView()) {
                ImagePickerHelper.getPictures(this.doImagePickerAttach, MAX_IMG_COUNT);
            } else {
                document.getElementById('gallery').click();
            }
        },
        async doImagePickerAttach(fileUrlList) {
            this.showLoading();
            let fileNo = 0;
            const imgList = [];

            for (var fileUrl of fileUrlList) {
                const blob = CameraHelper.base64ToBlob(fileUrl);
                const now = new Date();
                const imageName = now.getTime() + '.jpg';

                const addFile = {
                    fileNo: ++fileNo,
                    name: imageName,
                    type: 'blob',
                    dataUrl: fileUrl,
                    data: blob
                };
                imgList.push(addFile);
            }
            MenuHelper.addWork(this.$router, this.roomId, imgList);
        }
    }
};
</script>

<template>
    <header id="header" class="header primary">
        <div class="in-section">
            <ul>
                <li class="back">
                    <button type="button" class="btn-back" @click="back()">
                        <span class="blind">뒤로</span>
                    </button>
                </li>
                <li class="select-box">
                    <div class="select-inbox">
                        <select v-model="roomId" @change="changeRoom($event)">
                            <option v-for="(room, index) in rooms" :key="index" :value="room.id">
                                {{ room.name }}
                            </option>
                        </select>
                    </div>
                </li>
                <!--
                <li class="loc-nm">
                    <span>{{ locNm }}</span>
                </li>
              -->
                <li class="search">
                    <button type="button" class="btn-search" @click="menuSearch">
                        <span class="blind">검색</span>
                    </button>
                </li>
                <li class="people">
                    <button type="button" class="btn-people on" @click="menuWorkerOfroom">
                        <span class="blind">그룹</span>
                    </button>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EventHelper from '../../helper/EventHelper';
import MenuHelper from '../../helper/MenuHelper';
export default {
    name: 'RoomHeader',
    components: {},
    props: {
        locNm: {
            type: String,
            required: true
        }
    },
    data: () => {
        return {
            roomId: 0
        };
    },
    computed: {
        ...mapState({
            rooms: state => state.work.roomList
        })
    },
    created() {},
    mounted() {
        if (!this.room) {
            this.getMyRooms();
        }
        this.roomId = Number(this.$route.params.roomId);
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['getMyRooms']),
        showNav() {
            EventHelper.onNavMenu();
        },
        menuWorkerOfroom() {
            MenuHelper.workerOfroom(this.$router, this.roomId);
        },
        menuSearch() {
            MenuHelper.search(this.$router);
        },
        back() {
            this.$router.go(-1);
        },
        changeRoom(event) {
            const id = event.target.value;
            MenuHelper.room(this.$router, id);
        }
    }
};
</script>

<template>
    <div class="subheader-list-box filter">
        <div class="scroll-wrap">
            <div class="inner">
                <ul>
                    <li :class="searchType === '' ? 'on' : ''">
                        <a @click="doSearchType('')"><span>전체</span></a>
                    </li>

                    <li :class="searchType === 'receive' ? 'on' : ''">
                        <a @click="doSearchWork('receive')"><span>받은업무</span></a>
                    </li>
                    <li :class="searchType === 'request' ? 'on' : ''">
                        <a @click="doSearchWork('request')"><span>보낸업무</span></a>
                    </li>

                    <li :class="searchType === 'work' ? 'on' : ''">
                        <a @click="doSearchType('work')"><span>작업</span></a>
                    </li>
                    <li :class="searchType === 'picture' ? 'on' : ''">
                        <a @click="doSearchType('picture')"><span>사진</span></a>
                    </li>
                    <li :class="searchType === 'talk' ? 'on' : ''">
                        <a @click="doSearchType('talk')"><span>토크</span></a>
                    </li>
                    <li v-for="(tag, index) in tags" :key="index" :class="searchTag === tag.tag ? 'on' : ''">
                        <a @click="doSearchTag(tag.tag)">
                            <span>#{{ tag.tag }}</span>
                        </a>
                    </li>
                </ul>
                <div class="filter-btn-box">
                    <button class="btn-filter" @click="onFilter">
                        <span class="blind">필터</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import EventHelper from '../../helper/EventHelper';
import WorkTagApi from '../../api/WorkTagApi';

export default {
    name: 'FilterHeader',
    components: {},
    props: {
        condition: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            searchType: '',
            searchTag: '',
            tags: [],
            roomId: 0
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
            //rooms: state => state.work.roomList
        })
    },
    created() {},
    mounted() {
        this.roomId = Number(this.$route.params.roomId);
        this.getRankTag();
    },
    beforeDestroy() {},
    methods: {
        onFilter() {
            EventHelper.onFilter();
        },
        async getRankTag() {
            this.tags = await WorkTagApi.rankOfroom(this.roomId);
        },
        doSearchWork(type) {
            this.searchType = type;
            this.searchTag = '';
            this.condition.reset();
            this.condition.roomId = this.roomId;
            this.condition.page = 1;
            this.condition.type = '';
            if (type === 'request') {
                this.condition.registerId = this.basic.id;
            } else {
                this.condition.managerIds = [this.basic.id];
            }
            this.$emit('doSearch');
        },
        doSearchType(type) {
            //this.condition;
            this.searchType = type;
            this.searchTag = '';
            this.condition.reset();
            this.condition.roomId = this.roomId;
            this.condition.page = 1;
            if (type) this.condition.type = type;
            this.$emit('doSearch');
        },
        doSearchTag(tag) {
            //this.condition;
            this.searchType = 'x';
            this.searchTag = tag;
            //this.classReset($(event.target));
            this.condition.reset();
            this.condition.roomId = this.roomId;
            this.condition.page = 1;
            if (tag) this.condition.tags = [tag];
            this.$emit('doSearch');
        },
        classReset($target) {
            $('.subheader-list-box.filter ul li').removeClass('on');
            $target
                .parent()
                .parent()
                .addClass('on');
        }
    }
};
</script>

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    filter: {},
    roomList: [],
    roomWorker: [],
    tagOfRoom: [],
    workId: null,
    showWork: false,
    workOfRoom: {
        roomId: 0,
        page: 1,
        total: 0,
        works: []
    },
    picture: {},
    searchList: [],
    searchCondition: null,
    tempWorkContent: '',
    tempDocFile: [],
    tempImageList: []
};

export default {
    state,
    getters,
    actions,
    mutations
    // plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
};

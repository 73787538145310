<template>
    <div>
        <header id="header" class="header ">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="back()">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>사진편집</span>
                    </li>
                    <li class="etc">
                        <button type="button" class="btn-etc" @click="cutCrop">
                            <span>다음</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>

        <main id="content-wrap">
            <article id="contents">
                <div class="photo-area">
                    <tui-image-editor
                        ref="editor"
                        :include-ui="useDefaultUI"
                        :options="options"
                        @undoStackChanged="undoStackChanged"
                        @redoStackChanged="redoStackChanged"
                    />
                </div>
                <div class="photo-toolbar-1">
                    <ul>
                        <li>
                            <button ref="btnUndo" type="button" class="btn-editor-undo" disabled @click="undo()">
                                <span class="blind">되돌리기</span>
                            </button>
                        </li>
                        <li>
                            <button ref="btnRedo" type="button" class="btn-editor-redo" disabled @click="redo()">
                                <span class="blind">되살리기</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn-editor-refresh" @click="rotate()">
                                <span class="blind">로테이션</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </article>
        </main>
        <input
            id="camera"
            ref="uploadCamera"
            type="file"
            accept="image/*"
            capture="camera"
            class="blind"
            @change="doCamera"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ImageEditor } from '@toast-ui/vue-image-editor';
require('webpack-jquery-ui/slider');
require('webpack-jquery-ui/css');
require('jquery-ui-touch-punch');

import MutationTypes from '../../store/MutationTypes';

import MenuHelper from '../../helper/MenuHelper';
import MobileHelper from '../../helper/MobileHelper';
import CameraHelper from '../../helper/CameraHelper';
import ImagePickerHelper from '../../helper/ImagePickerHelper';

import { photoDialog } from '../../helper/DialogHelper';

import WorkerApi from '../../api/WorkerApi';
import FileApi from '../../api/FileApi';

import { PICTURE_SORCE_TYPE } from '../../const';

//require('webpack-jquery-ui/touch-punch');

export default {
    name: 'ProfileEditor',
    components: { 'tui-image-editor': ImageEditor },
    data: () => {
        return {
            worker: {
                id: 0,
                fileId: 0
            },
            imgObj: {},
            useDefaultUI: false,
            options: {
                // for tui-image-editor component's "options" prop
                usageStatistics: false,
                cssMaxWidth: document.documentElement.clientWidth,
                cssMaxHeight: document.documentElement.clientHeight
            },
            MAX_RESOLUTION: 3264 * 2448 * 2,
            supportingFileAPI: !!(window.File && window.FileList && window.FileReader),
            rImageType: /data:(image\/.+);base64,/,
            shapeOpt: {
                fill: '#fff',
                stroke: '#000',
                strokeWidth: 30
            }
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.myInfo();
        this.resetImageEditor();
        if (MobileHelper.isWebView()) {
            /* photoDialog(
                CameraHelper.getPicture(PICTURE_SORCE_TYPE.CAMERA, this.cameraImage, () => {
                    this.$router.go(-1);
                }),
                ImagePickerHelper.getPictures(this.cameraImage)
            );
            */
            photoDialog(this.btnCamera, this.btnGallery);
        } else {
            document.getElementById('camera').click();
        }
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['setPicture']),
        btnCamera() {
            CameraHelper.getPicture(PICTURE_SORCE_TYPE.CAMERA, this.cameraImage, () => {
                this.$router.go(-1);
            });
        },
        btnGallery() {
            ImagePickerHelper.getPicture(this.cameraImage);
        },
        back() {
            this.$router.go(-1);
        },
        cameraImage(fileUrl) {
            this.$refs.editor.invoke('loadImageFromURL', fileUrl, 'FilterImage');
        },
        selectImage() {
            const file = this.$refs.uploadCamera.files[0];
            this.$refs.editor.invoke('loadImageFromFile', file);
        },
        hexToRGBa(hex, alpha) {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);
            const a = alpha || 1;
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';
        },
        base64ToBlob(data) {
            let mimeString = '';
            let raw, uInt8Array, i;

            raw = data.replace(this.rImageType, function(header, imageType) {
                mimeString = imageType;
                return '';
            });

            raw = atob(raw);
            const rawLength = raw.length;
            uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
            for (i = 0; i < rawLength; i += 1) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], { type: mimeString });
        },
        resetImageEditor() {
            this.$refs.editor.invoke('stopDrawingMode');
            this.$refs.editor.invoke('startDrawingMode', 'CROPPER');
        },
        cutCrop() {
            const rect = this.$refs.editor.invoke('getCropzoneRect');

            if (rect) {
                if (rect.width > 100) {
                    this.$refs.editor.invoke('crop', rect).then(() => {
                        this.$refs.editor.invoke('stopDrawingMode');
                        this.uploadAndNext();
                    });
                } else {
                    this.uploadAndNext();
                }
            } else {
                this.uploadAndNext();
            }

            /*
            this.$refs.editor.invoke('crop', this.$refs.editor.invoke('getCropzoneRect')).then(
                () => {
                    this.$refs.editor.invoke('stopDrawingMode');
                    this.uploadAndNext();
                },
                error => {
                    console.log(error);
                }
            );
            */
        },
        undo() {
            this.$refs.editor.invoke('undo');
        },
        redo() {
            this.$refs.editor.invoke('redo');
        },
        undoStackChanged(length) {
            if (length > 1) {
                this.$refs.btnUndo.disabled = false;
            } else {
                this.$refs.btnUndo.disabled = true;
            }
        },
        redoStackChanged(length) {
            if (length) {
                this.$refs.btnRedo.disabled = false;
            } else {
                this.$refs.btnRedo.disabled = true;
            }
        },
        rotate() {
            this.$refs.editor.invoke('rotate', 90);
        },
        doCamera() {
            this.selectImage();
        },
        uploadAndNext() {
            this.$store.dispatch('showLoading');

            let imageName = this.$refs.editor.invoke('getImageName');
            const dataURL = this.$refs.editor.invoke('toDataURL');
            const blob = this.base64ToBlob(dataURL);
            const type = blob.type.split('/')[1];
            if (imageName.split('.').pop() !== type) {
                imageName += '.' + type;
            }

            const formData = new FormData();
            formData.append('file_id', this.worker.fileId > 0 ? this.worker.fileId : '');
            formData.append('file_no', 1);
            formData.append('file_name', imageName);
            formData.append('file', blob);
            FileApi.blolImgUpload(formData).then(res => {
                //MenuHelper.addWork(this.$router, this.roomId, res.content.fileId);
                //MenuHelper.addWorkNohistory(this.$router, this.roomId, res.content.fileId);
                this.worker.fileId = res.content.fileId;
                WorkerApi.modify(this.worker.id, this.worker).then(response => {
                    if (response) {
                        const basic = {
                            csId: this.basic.csId,
                            name: response.name,
                            position: response.positionName,
                            company: response.company.name,
                            companyId: response.company.id,
                            id: response.id,
                            hp: response.hp,
                            site: this.basic.name,
                            asteriskPhone: response.asteriskPhone,
                            imgUrl: response.imgUrl
                        };
                        this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                    }
                    MenuHelper.mypageNoHistory(this.$router);
                });
            });
        },
        async myInfo() {
            this.worker = await WorkerApi.detail(this.basic.id);
        }
    }
};
</script>

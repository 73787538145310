<template>
    <div class="contents-body">
        <ul ref="roomList" class="list">
            <li v-for="(work, index) in works" :key="index">
                <section class="round-section" >
                    <div class="in-section task-box" :class="{ on: work.register.id === basic.id }">
                        <div class="task-inbox">
                            <div class="task-type">
                                <a @click="showUtil(work.id)">
                                    <span :class="taskType(work)">{{ taskName(work) }}</span>
                                </a>
                            </div>

                            <div v-if="work.type !== 'talk'" class="task-thumb">
                                <a @click="menuWork(work.id)">
                                    <div class="thumb">
                                        <div class="thumb-inner">
                                            <img
                                                :src="work.imgUrl | sThumbUrl"
                                                onerror="javascript:this.src='/images/common/default.png'"
                                            />
                                        </div>
                                        <div v-if="work.type === 'work'" class="count" :class="classCount(work)">
                                            {{ finishCount(work) }}
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="task-summary">
                                <a class="charger" @click="callhp(work.register.hp)">
                                    {{ work.register.displayName }}&middot;{{ work.register.company.name }}
                                </a>
                                <p class="summary">
                                    <a @click="menuWork(work.id)">{{ work.content }}</a>
                                </p>
                                <div class="meta-info">
                                    <a @click="menuWork(work.id)">
                                        <span class="datetime">{{ work.regDt | timeBefore }}</span>
                                        <span class="comment-cnt">댓글 {{ work.countOfComment }}</span>
                                        <!-- 20200527 첨부파일 추가내용 -->
                                        <span class="comment-file">
                                            첨부 {{ work.countOfDocFile + work.countOfActionDocFile }}
                                        </span>
                                        <!-- 20200527 첨부파일 추가내용 //-->
                                        <span v-if="work.type === 'work'" class="read-user">
                                            {{ work.countOfManager }} / {{ work.countOfView }} 명 읽음
                                        </span>
                                        <span v-else class="read-user">{{ work.views }} 명 읽음</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';
import MenuHelper from '../../helper/MenuHelper';
import KakaoHelper from '../../helper/KakaoHelper';

import { confirmDialog } from '../../helper/DialogHelper';

export default {
    name: 'RoomItem',
    components: {},
    props: {
        works: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {
            selectId: {}
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        window.addEventListener('scroll', this.morePage, { passive: true });
    },
    mounted() {},
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener('scroll', this.morePage);
        //if (LoginHelper.isLogin() && UiHelper.isWebView())
        //  document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
    },
    methods: {
        menuWork(id) {
            MenuHelper.work(this.$router, id);
        },
        taskName(work) {
            return WORK_TYPE[work.type];
        },
        taskType(work) {
            return WORK_TYPE_CSS[work.type];
        },
        getDetailClass(work) {
            if (work.finishYn === 'Y') return 'btn-repair-detail';
            if (work.type === 'work') return 'btn-reg-repair';
            else return 'btn-repair-detail';
        },
        getDetailText(work) {
            if (work.finishYn === 'Y') return '상세보기';
            if (work.type === 'work') return '조치 등록';
            else return '상세보기';
        },
        showUtil(id) {
            //MenuHelper.work(this.$router, id);
            $('#list-util-box-detail-' + id).focus();
            this.selectId = id;
        },
        morePage() {
            const scrollY = window.scrollY;
            const o = this.$refs.roomList;
            //console.log(scrollY + 550, o.scrollHeight);
            if (scrollY + 550 > o.scrollHeight && o.scrollHeight > 550) {
                this.$emit('more');
            }
        },
        shareKakao(work) {
            KakaoHelper.share('room', work.id, 'COCO 등록 내용을 확인하세요.', work.imgUrl, work.content);
        },
        classCount(work) {
            if (work.finishYn === 'Y') return 'donedone';
            else if (work.countOfAction === 0) return 'done';
            return '';
        },
        finishCount(work) {
            if (work.finishYn === 'Y') return '완료';
            return work.countOfAction;
        },
        callhp(hp) {
            if (hp) window.open('tel:' + hp, '_system');
        },
        onLongPressStart(work) {
            //alert('popup');
            if (work.type !== 'work') {
                confirmDialog('다른 그룹에 공유하시겠습니까?', async () => {
                    MenuHelper.shareRoom(this.$router, work.id);
                });
            }
        }
    }
};
</script>

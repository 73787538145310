export const APPLICATION_PHASE = process.env.APPLICATION_PHASE || 'REAL';
export const APPLICATION_VERSION = process.env.VERSION;

export const PAGINATION = {
    first: true,
    last: false,
    numberOfElements: 10,
    size: 10,
    number: 1,
    totalPages: 1,
    totalElements: 1
};

/** 너무 세분화하지 말고 HEADER 를 구분하는 정도
 *  default :: json string
 */
export const API_URL = '/api';
export const DEFAULT_HEADER = { 'Content-Type': 'application/json' };
/*
export const FIELD_PAGE = 'page';
export const FIELD_SIZE = 'size';
*/

/* component별 사이즈 정리 */
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_ROUTE = '/';

export const MAIN_LIST_SIZE = 10;

export const CS_ID = 'csId';
export const CS_IDS = 'csIds';
export const WORKER_ID = 'workerId';
export const JWT_TOKEN = 'token';
export const JWT_REFRESH_TOKEN = 'refreshtoken';

export const SESSION_USER_INFO = 'userInfo';
export const SUPER_USER = 'admin';

export const MAX_IMG_COUNT = 5;

export const WEATHER_SKY = {
    맑음: 'wea-01',
    //구름조금: 'wea-02',
    구름많음: 'wea-03',
    흐림: 'wea-04',
    비: 'wea-05',
    소나기: 'wea-06',
    눈: 'wea-07',
    //소낙눈: 'wea-08',
    '눈/비': 'wea-09',
    낙뢰: 'wea-10'
    //안개: 'wea-11',
    //황사: 'wea-12'
};

export const WORK_TYPE = {
    work: '작업',
    talk: '토크',
    picture: '사진'
};

export const WORK_TYPE_CSS = {
    work: 'type-1',
    talk: 'type-3',
    picture: 'type-2'
};

export const IMG_THUMB = '400X400';

export const PICTURE_SORCE_TYPE = {
    PHOTOLIBRARY: 0,
    CAMERA: 1
};

export const TOP_DEFAULT_CONFIG = {
    pullText: '아래로 당겨 새로 고침', // The text is displayed when you pull down
    triggerText: 'Update...', // The text that appears when the trigger distance is pulled down
    loadingText: 'Loading...', // The text in the load
    doneText: '(≖ ‿ ≖)✧', // Load the finished text
    failText: '(⊙ˍ⊙)', // Load failed text
    loadedStayTime: 400, // Time to stay after loading ms
    stayDistance: 50, // Trigger the distance after the refresh
    triggerDistance: 0 // Pull down the trigger to trigger the distance
};

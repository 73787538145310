<template>
    <div v-if="tags.length > 0" class="task-info-btm-box">
        <dl>
            <dt><span class="tit-tags">태그</span></dt>
            <dd>
                <div>
                    <span v-for="(tag, index) in tags" :key="index">#{{ tag.tag }}</span>
                </div>
            </dd>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'TagBox',
    components: {},
    props: {
        tags: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {}
};
</script>

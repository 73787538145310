<template>
    <div id="signup-wrap">
        <div id="signup-container">
            <div id="signup-header">
                <p class="single-tit">
                    {{ basic.site }}
                </p>
                <p class="subtit">
                    COCO 현장 회원가입 안내
                </p>
            </div>

            <div id="signup-body">
                <div class="signup-already">
                    <p class="signup-already-txt" v-html="$t('join_site1')" />
                    <p class="txt" v-html="$t('join_site2')" />
                    <div class="btn-wrap">
                        <a @click="joinSite()"><span>승인 요청하기</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ConstructionApi from '../../api/ConstructionApi';
import AffilationSiteApi from '../../api/AffilationSiteApi';
import MutationTypes from '../../store/MutationTypes';

export default {
    name: 'JoinSite',
    components: {},
    data: () => {
        return { csId: 0 };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.csId = Number(this.$route.params.csId);
        this.getCsInfo();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        getCsInfo() {
            ConstructionApi.detail(this.csId).then(res => {
                if (res.content) {
                    const basic = {
                        csId: res.content.id,
                        site: res.content.name
                    };
                    this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                }
            });
        },
        async joinSite() {
            const res = await AffilationSiteApi.joinWorker({ csId: this.csId });

            if (res) {
                alert('승인요청이 되었습니다.');
                //self.close();
            }
        }
    }
};
</script>

import mainTypes from './modules/main/types';
import workTypes from './modules/work/types';
import codeTypes from './modules/code/types';
import joinTypes from './modules/join/types';

export default {
    ...mainTypes,
    ...workTypes,
    ...codeTypes,
    ...joinTypes,
    /*******************************************************
     *** ERROR 정의
     *******************************************************/
    ERROR: 'ERROR'
};

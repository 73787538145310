import MutationTypes from '../../MutationTypes';

export default {
    [MutationTypes.SET_JOIN]: (state, payload) => {
        state.join = payload;
    },
    [MutationTypes.SET_SMS_CERT]: (state, payload) => {
        state.smsCert = payload;
    }
};

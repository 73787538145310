const ORIGIN_URL = location.origin;
const DEFAULT_IMG_URL = 'https://m-coco.dscosm.com/images/share.png';

import MobileHelper from './MobileHelper';

const shareLink = (link, title, content) => {
    if (!window.Kakao.isInitialized()) window.Kakao.init(process.env.KAKAO_ID);
    if (!title) title = 'COCO';
    var targetUrl = ORIGIN_URL + link;
    if (MobileHelper.isWebView()) {
        var reqUrl = ORIGIN_URL + '/share.html';
        window.cordova.InAppBrowser.open(
            reqUrl +
                '?title=' +
                encodeURIComponent(title) +
                '&content=' +
                encodeURIComponent(content) +
                '&imgUrl=' +
                encodeURIComponent(DEFAULT_IMG_URL) +
                '&redirectUrl=' +
                encodeURIComponent(targetUrl),
            '_system',
            'location=yes'
        );
    } else {
        const feedLink = {
            mobileWebUrl: targetUrl,
            webUrl: targetUrl
        };

        const feedContent = {
            title: title,
            description: content,
            link: feedLink,
            imageUrl: DEFAULT_IMG_URL,
            imageWidth: 800,
            imageHeight: 400
        };
        window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: feedContent
        });
    }
};

const share = (target, id, title, imgUrl, content) => {
    if (!window.Kakao.isInitialized()) window.Kakao.init(process.env.KAKAO_ID);
    if (imgUrl === '') imgUrl = DEFAULT_IMG_URL;
    if (!imgUrl) imgUrl = DEFAULT_IMG_URL;
    if (!title) title = '사진으로 협업한다! COCO';
    if (!content) content = '';

    var targetUrl = ORIGIN_URL + '/kakao/' + target + '/' + id;

    if (MobileHelper.isWebView()) {
        var reqUrl = ORIGIN_URL + '/share.html';
        window.cordova.InAppBrowser.open(
            reqUrl +
                '?title=' +
                encodeURIComponent(title) +
                '&content=' +
                encodeURIComponent(content) +
                '&imgUrl=' +
                encodeURIComponent(imgUrl) +
                '&redirectUrl=' +
                encodeURIComponent(targetUrl),
            '_system',
            'location=yes'
        );
    } else {
        const feedLink = {
            mobileWebUrl: targetUrl,
            webUrl: targetUrl
        };

        const feedContent = {
            title: title,
            description: content,
            link: feedLink,
            imageUrl: imgUrl,
            imageWidth: 800,
            imageHeight: 400
        };

        window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: feedContent
        });
    }
};

const appShareLink = (title, content) => {
    if (!window.Kakao.isInitialized()) window.Kakao.init(process.env.KAKAO_ID);
    if (!title) title = 'COCO';
    var targetUrl = ORIGIN_URL + '/app_redirect.html';
    if (MobileHelper.isWebView()) {
        var reqUrl = ORIGIN_URL + '/share.html';
        window.cordova.InAppBrowser.open(
            reqUrl +
                '?title=' +
                encodeURIComponent(title) +
                '&content=' +
                encodeURIComponent(content) +
                '&imgUrl=' +
                encodeURIComponent(DEFAULT_IMG_URL) +
                '&redirectUrl=' +
                encodeURIComponent(targetUrl),
            '_system',
            'location=yes'
        );
    } else {
        const feedLink = {
            mobileWebUrl: targetUrl,
            webUrl: targetUrl
        };

        const feedContent = {
            title: title,
            description: content,
            link: feedLink,
            imageUrl: DEFAULT_IMG_URL,
            imageWidth: 800,
            imageHeight: 400
        };
        window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: feedContent
        });
    }
};

export default {
    share,
    shareLink,
    appShareLink
};

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    join: {
        csId: 0,
        name: '',
        passwd: '',
        hp: '',
        joinNo: ''
    },
    smsCert: {
        hp: '',
        certNo: '',
        isRequest: false,
        agree1: false,
        agree2: false
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
};

<template>
    <div>
        <header id="header" class="header ">
            <div class="in-section">
                <ul>
                    <li class="loc-nm">
                        <span>COCO 작업상세</span>
                    </li>
                </ul>
            </div>
        </header>
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="list">
                        <li>
                            <section class="round-section">
                                <div class="in-section task-box">
                                    <div v-if="imgs.length > 0" class="task-thumb">
                                        <div class="swiper-container">
                                            <swiper :options="swiperOption">
                                                <swiper-slide
                                                    v-for="(img, index) in imgs"
                                                    :key="index"
                                                    class="swiper-slide"
                                                >
                                                    <a>
                                                        <div class="thumb-slider">
                                                            <div class="thumb-inner">
                                                                <img
                                                                    v-gallery="'workImage'"
                                                                    :data-index="index"
                                                                    :data-large="img.url"
                                                                    :src="img.url | thumbUrl"
                                                                />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </swiper-slide>
                                                <div slot="pagination" class="swiper-pagination" />
                                            </swiper>
                                        </div>
                                    </div>

                                    <div class="task-info-box">
                                        <div class="task-info-top-box">
                                            <div class="task-user-info">
                                                <div class="task-type">
                                                    <span :class="taskType()">{{ taskName() }}</span>
                                                </div>
                                                <a @click="callhp(work.hp)">
                                                    {{ work.name + ' ' + work.positionName }}&middot;
                                                    {{ work.companyName }}
                                                </a>
                                            </div>
                                            <p class="task-memo" v-html="convertCrToBr(work.content)" />
                                        </div>
                                        <charge-box v-if="work.type === 'work'" :users="manager" />
                                        <tag-box :tags="tags" />
                                    </div>
                                </div>
                                <div class="meta-info">
                                    <span class="datetime">{{ work.regDt | longFormatTime }}</span>
                                </div>
                            </section>
                        </li>
                        <template v-if="actions.length > 0">
                            <li v-for="(action, index) in actions" :key="index">
                                <action-item :item="action" :work="work" />
                            </li>
                        </template>
                    </ul>
                </div>
            </article>
        </main>
        <div class="app-download" @click="appDownload">
            <p>
                COCO 앱을 설치하시면 간편한 작업/공지 알림 서비스를<br />이용하실 수 있습니다.&nbsp;&nbsp;
                <span>COCO APP 설치하기</span>
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
/* components */
import TagBox from './TagBox.vue';
import ChargeBox from './ChargeBox.vue';
import ActionItem from './KakaoActionItem.vue';

/* API */
import WorkApi from '../../api/WorkApi';
import WorkTagApi from '../../api/WorkTagApi';
import WorkManagerApi from '../../api/WorkManagerApi';
import WorkActionApi from '../../api/WorkActionApi';
import FileApi from '../../api/FileApi';

import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';
import WorkModel from '../../model/WorkModel';

import { convertCrToBr } from '../../utils/string';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import { convertEntityHtml } from '../../utils/string';

export default {
    name: 'WorkDetail',
    components: { TagBox, ChargeBox, ActionItem, swiper, swiperSlide },
    props: {},
    data: () => {
        return {
            isMore: false,
            id: null,
            heaerTitle: '작업 상세',
            work: {
                id: 0,
                register: { hp: '', id: null },
                regId: null,
                finishYn: 'N'
            },
            tags: [],
            manager: [],
            actions: [],
            imgs: [],
            swiperOption: {
                direction: 'horizontal',
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                }
            }
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        $('html').scrollTop(0);
        this.id = this.$route.params.id;
        this.getDetail();
    },
    beforeDestroy() {
        //document.removeEventListener('touchend', this.listnerBody, true);
    },
    methods: {
        ...mapActions(['getWork', 'getCount', 'showLoading']),
        appDownload() {
            alert('app Store 연결');
        },
        callhp(hp) {
            if (hp) window.open('tel:' + hp, '_system');
        },
        convertEntityHtml,
        getDetail() {
            if (this.id) {
                WorkApi.kakaoDetail(this.id).then(res => {
                    if (!res) return;
                    this.work = new WorkModel(res.content);
                    if (this.work.type === 'work') {
                        WorkActionApi.kakaoActionOfworkId(this.id).then(result => {
                            this.actions = result.content;
                        });
                        WorkManagerApi.kakaoWorkOfManagerList(this.id).then(res => {
                            this.manager = res.content;
                        });
                    }
                    if (this.work.fileId) {
                        FileApi.list(this.work.fileId).then(res => {
                            this.imgs = res;
                        });
                    }
                });

                WorkTagApi.kakaoTagsOfwork(this.id).then(res => {
                    this.tags = res.content;
                });
            }
        },
        taskName() {
            return WORK_TYPE[this.work.type];
        },
        taskType() {
            return WORK_TYPE_CSS[this.work.type];
        },
        isDone() {
            return this.work.type === 'work' && this.work.finishYn === 'Y';
        },
        convertCrToBr
    }
};
</script>

import Setting from '../components/member/Setting';
import Policy from '../components/member/Policy';
import Privacy from '../components/member/Privacy';
import MyPage from '../components/member/MyPage';
import Passwd from '../components/member/Passwd';
import FindPasswd from '../components/member/FindPasswd';

import ApproveWorker from '../components/member/ApproveWorker';

export default [
    {
        path: '/setting',
        name: 'Setting',
        component: Setting
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/mypage',
        name: 'MyPage',
        component: MyPage
    },
    {
        path: '/passwd',
        name: 'Passwd',
        component: Passwd
    },
    {
        path: '/findpasswd',
        name: 'FindPasswd',
        component: FindPasswd,
        meta: {
            bodyClass: 'signup off'
        }
    },
    {
        path: '/approveWorker',
        name: 'ApproveWorker',
        component: ApproveWorker,
        meta: {
            bodyClass: 'fixed'
        }
    }
];

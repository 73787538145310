<template>
    <div id="wrap">
        <router-view :key="$route.fullPath" />
        <div v-if="goTop" id="go-top">
            <a href="#top"><span class="blind">위로가기</span></a>
        </div>
        <Nav v-if="isNav" />
        <Loading />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
//import Footer from './components/layouts/Footer.vue';
import Nav from './components/layouts/Nav.vue';
import Loading from './components/common/Loading.vue';
import WorkerApi from './api/WorkerApi';

import ConstructionApi from './api/ConstructionApi';
import { WORKER_ID } from './const';
import MutationTypes from './store/MutationTypes';
import MobileHelper from './helper/MobileHelper';

import { exitDialog } from './helper/DialogHelper';

//import EventHelper from './helper/EventHelper';

export default {
    name: 'App',
    components: { Nav, Loading },
    data() {
        return {
            bodyCls: 'main',
            isMain: false,
            isNav: false,
            goTop: false,
            goBottom: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    watch: {
        $route: 'fetchData'
    },
    created() {
        //document.cookie = 'SESSION=ZjIwNzRlM2MtNGU3NS00ZjMxLWJmYmYtZGQ4ZjA2M2YxYmQx';
        //const workerId = localStorage.getItem(WORKER_ID);
        //if (!workerId) this.$router.push({ path: '/login' });
        this.showLoading();
        if (this.$route.name === 'Main') {
            this.goTop = true;
            this.isMain = true;
            this.isNav = true;
        } else {
            this.goTop = false;
            this.isMain = false;
            this.isNav = false;
        }
        if (this.$route.meta.bodyClass) document.body.classList = this.$route.meta.bodyClass;
    },
    mounted() {
        this.showLoading();
        if (MobileHelper.isWebView()) {
            const platform = MobileHelper.getPlatform();
            if (platform) {
                var script = document.createElement('script');
                script.onload = () => {
                    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
                };
                script.onerror = () => {
                    console.error('script load error');
                };
                if (platform === 'ios') script.src = process.env.CORDOVA_IOS_DOMAIN;
                else script.src = process.env.CORDOVA_ANDROID_DOMAIN;
                document.head.appendChild(script);
            }
        }
        const workerId = localStorage.getItem(WORKER_ID);
        if (this.basic.csId === 0 && workerId) {
            ConstructionApi.mySite().then(csResponse => {
                if (csResponse.content.id > 0 && workerId) {
                    if (!MobileHelper.isWebView()) this.doLastAccess(process.env.VERSION);
                    WorkerApi.detail(workerId).then(res => {
                        const basic = {
                            csId: csResponse.content.id,
                            name: res.name,
                            position: res.positionName,
                            company: res.company.name,
                            companyId: res.company.id,
                            id: res.id,
                            hp: res.hp,
                            site: csResponse.content.name,
                            asteriskPhone: res.asteriskPhone,
                            imgUrl: res.imgUrl,
                            csAdminYn: res.adminYn,
                            csCompanyAdminYn: res.csCompanyAdminYn
                        };
                        this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);
                    });
                } else {
                    //이게 의미가 있나 어차피 소속현장이 없다면,,, 로그인이 안될 텐데
                    this.$router.push({ path: '/login' });
                }
            });
        } else {
            console.log(workerId);
        }

        setTimeout(() => {
            //EventHelper.hideLoading();
            this.hideLoading();
        }, 500);
    },
    destroyed() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading', 'setTempWorkContent', 'setTempWorkDocFile']),
        backbtn() {
            exitDialog();
        },
        doLastAccess() {
            MobileHelper.doLastAccess();
        },
        onDeviceReady() {
            const version = MobileHelper.getAppVersion();
            /*
            if (window.cordova.platformId === 'android') {
                window.StatusBar.overlaysWebView(false);
                //window.StatusBar.backgroundColorByName('white');
                window.StatusBar.show();
                window.StatusBar.backgroundColorByHexString('#00');
            }
            */

            if (this.basic.id > 0) this.doLastAccess(version);
            MobileHelper.onDeviceReady();
        },
        fetchData(route) {
            //EventHelper.showLoading();
            this.showLoading();
            if (route.name !== 'AddWork') {
                this.setTempWorkContent('');
                this.setTempWorkDocFile([]);
            }
            if (this.$route.name === 'Main') {
                this.goTop = true;
                this.isMain = true;
                this.isNav = true;
            } else {
                this.goTop = false;
                this.isMain = false;
                this.isNav = false;
            }

            if (route.meta.bodyClass) document.body.classList = route.meta.bodyClass;
            else document.body.classList = '';
            //this.hideLoading();
            let limitTime = 270;
            if (this.$route.name === 'Main') limitTime = 500;
            setTimeout(() => {
                //EventHelper.hideLoading();
                this.hideLoading();
            }, limitTime);
        }
    }
};
</script>

<template>
    <div>
        <div v-if="!showImageEditor">
            <Header loc-nm="등록" loc-type="regist" :fn-confirm="registWork" />
            <main id="content-wrap">
                <article id="contents">
                    <div class="contents-body blueFull">
                        <div class="repair-write-wrap">
                            <div class="write-photo-list">
                                <div class="scroll-wrap">
                                    <div class="inbox">
                                        <ul class="photo-roll">
                                            <li class="add-photo">
                                                <a class="add-photo" @click="imagePicker" />
                                                <div class="thumb-write">
                                                    <a @click="imagePicker">
                                                        <div class="thumb-inner" />
                                                    </a>
                                                </div>
                                            </li>
                                            <li v-for="(img, index) in imgFileList" :key="index">
                                                <a class="photo-delete" @click="onClickImgDel(img)" />
                                                <div class="thumb-write">
                                                    <a @click="openImgEditor(img)">
                                                        <div class="thumb-inner">
                                                            <img :src="img.dataUrl" loading="eager" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="repair-memo">
                                <textarea
                                    id="repair-memo-text"
                                    ref="commentMemo"
                                    v-model="writeData.content"
                                    placeholder="작업 또는 사진내용을 입력하세요."
                                />
                            </div>
                        </div>
                        <div class="alarm fixed">
                            <p>
                                담당자를 선택하면 [업무]로, 담당자를 선택하지 않으면 [사진] 카테고리의 글로 등록됩니다.
                            </p>
                        </div>
                        <div class="form-wrap">
                            <div class="field-box">
                                <div class="label">
                                    작업그룹
                                </div>
                                <div class="field">
                                    <div class="select-box">
                                        <a class="select" @click="onRoom">
                                            {{ writeData.room.name || '선택하세요' }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="divider" />

                            <div class="field-box">
                                <div class="label">
                                    담당자
                                </div>
                                <div class="field">
                                    <div class="select-box">
                                        <a class="select select-charger" @click="onManager">
                                            선택
                                        </a>
                                    </div>
                                    <div v-if="writeData.managers.length > 0" class="selected-list">
                                        <span v-for="(manager, index) in writeData.managers" :key="index">
                                            {{ manager.name }}·{{ manager.companyName }}
                                            <button class="btn_remove" @click="removeWriteManager(manager)">
                                                삭제
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="divider" />
                            <div class="field-box">
                                <div class="label">
                                    공종/작업태그
                                </div>
                                <div class="field">
                                    <div class="select-box">
                                        <a class="select select-tags" @click="onTag">
                                            선택
                                        </a>
                                    </div>
                                    <div class="tag-add-box">
                                        <input v-model="addTag" type="text" class="txt" />
                                        <a class="btn-tag-add" @click="addEtcTag">
                                            <span class="blind">추가</span>
                                        </a>
                                    </div>
                                    <div v-if="writeData.tags.length > 0" class="selected-list">
                                        <span v-for="(tag, index) in writeData.tags" :key="index">
                                            #{{ tag }}
                                            <button class="btn_remove" @click="removeWriteTag(tag)">삭제</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- 20200527 첨부파일 추가내용 -->
                            <div class="divider" />
                            <div class="field-box">
                                <div class="label">
                                    첨부파일
                                </div>
                                <div class="field">
                                    <div class="file-add-box">
                                        <input
                                            type="button"
                                            class="button"
                                            value="파일찾기(선택시파일명노출)"
                                            @click="pickerFile"
                                        />
                                        <input
                                            id="docFile"
                                            ref="docFile"
                                            type="file"
                                            :accept="DOC_ALLOW_FILE"
                                            class="blind"
                                            @change="doDocFileAttach"
                                        />
                                        <a class="btn-file-add" @click="pickerFile">
                                            <span class="blind">파일검색</span>
                                        </a>
                                    </div>
                                    <div class="selected-list mb">
                                        <span v-for="(fileInfo, index) in addFileList" :key="index" class="file">
                                            {{ fileInfo.name }}
                                            <button class="btn_remove" @click="onClickFileDel(fileInfo)">삭제</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- 20200527 첨부파일 추가내용 //-->
                        </div>
                    </div>
                </article>
            </main>
            <filter-room ref="filterRoom" :room-id="roomId" @setRoom="setWriteRoom" />
            <filter-manager tp-id="write" @setManager="setWriteManager" />
            <filter-tags tp-id="write" @setTags="setWriteTags" />
        </div>

        <image-editor v-else ref="popImageEditor" @close="closeImgEditor" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/RegistHeader.vue';
import FilterManager from '../room/FilterManager.vue';
import FilterTags from '../room/FilterTags.vue';
import FilterRoom from '../room/FilterRoom.vue';

import ImageEditor from '../common/ImageEditor.vue';

import EventHelper from '../../helper/EventHelper';
import MenuHelper from '../../helper/MenuHelper';

import ArrayHelper from '../../helper/ArrayHelper';
import FileHelper from '../../helper/FileHelper';
import WorkApi from '../../api/WorkApi';
import FileApi from '../../api/FileApi';

//import DataHelper from '../../helper/DataHelper';
import MobileHelper from '../../helper/MobileHelper';
import { alertDialog } from '../../helper/DialogHelper';
import ImagePickerHelper from '../../helper/ImagePickerHelper';
import CameraHelper from '../../helper/CameraHelper';
import { MAX_IMG_COUNT } from '../../const';
//import { removeEmoji } from '../../utils/string';

export default {
    name: 'AddWork',
    components: { Header, FilterManager, FilterTags, FilterRoom, ImageEditor },
    props: {
        tempImageList: {
            type: Array,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            roomId: 0,
            fileId: 0,
            addTag: '',
            writeData: {
                room: {},
                content: '',
                managers: [],
                tags: [],
                tag: []
            },
            imgUrl: '',
            addFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            docfileId: 0,
            docfileNo: 0,
            imgFileList: [],
            imgfileId: 0,
            imgfileNo: 0,
            showImageEditor: false
        };
    },
    computed: {
        ...mapState({
            rooms: state => state.work.roomList,
            tempWorkContent: state => state.work.tempWorkContent,
            tempWorkDocFile: state => state.work.tempDocFile
        })
    },
    created() {
        $('html').scrollTop(0);
        this.roomId = Number(this.$route.params.roomId);
        //this.fileId = Number(this.$route.params.fileId);
    },
    mounted() {
        this.roomId = Number(this.$route.params.roomId);
        this.imgFileList = this.tempImageList || [];
        this.writeData.content = this.tempWorkContent;
        this.addFileList = this.tempWorkDocFile;

        this.getMyRooms().then(() => {
            if (this.roomId > 0) {
                this.writeData.room = this.rooms.find(room => room.id === this.roomId);
            }
        });
        /*
        FileApi.fileOne(this.fileId).then(res => {
            this.imgUrl = DataHelper.sThumbImgUrl(res.content.url);
        });
        */
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([
            'addWork',
            'getMyRooms',
            'setTempWorkContent',
            'setTempWorkDocFile',
            'showLoading',
            'hideLoading'
        ]),
        pickerFile() {
            if (FileHelper.isCount(this.addFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    ++this.docfileNo;
                    const formData = new FormData();
                    formData.append('file_id', '');
                    formData.append('file_no', this.docfileNo);
                    formData.append('file', fileObject);
                    const addFile = {
                        fileNo: this.docfileNo,
                        name: fileObject.name,
                        form: formData
                    };
                    this.addFileList.push(addFile);
                }
            }
        },
        onClickFileDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        setWriteRoom(room) {
            this.roomId = room.id;
            this.writeData.room = room;
            if (this.writeData.manager && this.writeData.manager.length > 0) {
                alertDialog('담당자 및 공정을 다시 선택하세요.');
            }
            //MenuHelper.addWorkNohistory(this.$router, this.roomId, this.fileId);
            MenuHelper.addWorkNohistory(this.$router, this.roomId, this.imgFileList);
        },
        setWriteManager(workers) {
            this.writeData.managers = workers;
            this.writeData.manager = workers.map(worker => worker.id);
        },
        removeWriteManager(worker) {
            const condition = item => item.id === worker.id;
            ArrayHelper.oneRemove(this.writeData.managers, condition);
            const condition2 = item => item === worker.id;
            ArrayHelper.oneRemove(this.writeData.manager, condition2);
        },
        setWriteTags(tags) {
            this.writeData.tags = tags;
        },
        removeWriteTag(tag) {
            const condition = item => item === tag;
            ArrayHelper.oneRemove(this.writeData.tags, condition);
        },
        async registWork() {
            if (this.roomId === 0) {
                alertDialog('작업그룹은 필수입니다.');
                return;
            }
            this.docfileNo = 0;
            this.showLoading();
            for (var docFile of this.addFileList) {
                const docFileForm = docFile.form;
                if (this.docFileId > 0) docFileForm.set('file_id', this.docFileId);
                docFileForm.set('file_no', ++this.docfileNo);
                const fileRes = await FileApi.fileUpload(docFileForm, 'doc');
                if (fileRes.content) {
                    this.docFileId = fileRes.content[0].id.fileId;
                    this.writeData.docFileId = this.docFileId;
                }
            }

            const moveImgs = [];
            /* 이미지 업로드 */
            if (this.imgFileList.length > 0) {
                this.imgfileNo = 0;
                for (var imgFile of this.imgFileList) {
                    const imgFileForm = new FormData();
                    //const now = new Date();
                    //const imageName = now.getTime() + '.jpg';
                    imgFileForm.append('file_id', '');
                    imgFileForm.append('file_no', imgFile.fileNo);
                    //imgFileForm.append('file_name', imageName);
                    imgFileForm.append('file_name', imgFile.name);
                    imgFileForm.append('file', imgFile.data);

                    if (this.imgfileId > 0) imgFileForm.set('file_id', this.imgfileId);
                    imgFileForm.set('file_no', ++this.imgfileNo);
                    if (imgFile.type === 'file') {
                        const fileRes = await FileApi.imgUpload(imgFileForm);
                        if (fileRes.content && this.imgfileId === 0) {
                            this.imgfileId = fileRes.content.fileId;
                        }
                    } else if (imgFile.type === 'blob') {
                        const fileRes = await FileApi.blolImgUpload(imgFileForm);
                        if (fileRes.content && this.imgfileId === 0) {
                            this.imgfileId = fileRes.content.fileId;
                        }
                    } else {
                        moveImgs.push(imgFile.data);
                    }
                }
                if (this.imgfileId > 0) this.writeData.fileId = this.imgfileId;
            }

            this.docfileNo = 0;

            this.writeData.tag = this.writeData.tags;
            this.writeData.room.id = this.roomId;
            this.writeData.type = this.writeData.managers.length > 0 ? 'work' : 'picture';
            this.writeData.finishYn = 'N';
            this.writeData.moveImgs = moveImgs;

            this.addWork(this.writeData).then(res => {
                this.hideLoading();
                if (res) {
                    this.writeData.content = '';
                    this.writeData.room = {};
                    this.writeData.type = '';
                    this.writeData.managers = [];
                    this.writeData.manager = [];
                    this.writeData.tags = [];
                    this.writeData.tag = [];
                    //this.$router.go(-1);
                    this.setTempWorkContent('');
                    this.setTempWorkDocFile([]);
                    window.stompClient.send(
                        '/app/chat.message/' + this.roomId,
                        JSON.stringify({ roomId: this.roomId }),
                        {}
                    );
                  
                    this.$router.replace({ path: `/room/${this.roomId}` });

                    EventHelper.onScrollATop();
                }
            });
        },
        addEtcTag() {
            if (this.addTag) {
                const addtag = this.addTag.trim();
                if (this.writeData.tags.findIndex(tag => tag === addtag) < 0) {
                    this.writeData.tags.push(addtag);
                    //this.writeData.tag.push(addtag);
                }
                this.addTag = '';
            }
        },
        onManager() {
            EventHelper.onManager('write');
        },
        onTag() {
            EventHelper.onTag('write');
        },
        onRoom() {
            this.setTempWorkContent(this.writeData.content);
            this.setTempWorkDocFile(this.addFileList);
            //여기에도 값을 추가해야 됨.
            EventHelper.onRoom();
        },
        imagePicker() {
            if (MobileHelper.isWebView()) {
                const cnt = MAX_IMG_COUNT - this.imgFileList.length;
                ImagePickerHelper.getPictures(this.doImagePickerAttach, cnt);
            } else {
                //document.getElementById('gallery').click();
            }
        },
        onClickImgDel(fileInfo) {
            /*
            if (fileInfo.type === 'server') {
                this.removeFileList.push(fileInfo);
            }
            */
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.imgFileList, condition);
        },
        openImgEditor(imgObject) {
            this.showImageEditor = true;
            setTimeout(() => {
                this.$refs.popImageEditor.setDataUrlImage(imgObject);
            }, 100);
            //MenuHelper.imageEdit(this.$router, this.roomId, imgObject);
        },
        closeImgEditor() {
            document.body.classList = 'write lightblue';
            this.showImageEditor = false;
        },
        async doImagePickerAttach(fileUrlList) {
            //this.showLoading();
            let fileNo = 0;
            //const imgList = [];

            for (var fileUrl of fileUrlList) {
                const blob = CameraHelper.base64ToBlob(fileUrl);
                const now = new Date();
                const imageName = now.getTime() + '.jpg';

                const addFile = {
                    fileNo: ++fileNo,
                    name: imageName,
                    type: 'blob',
                    dataUrl: fileUrl,
                    data: blob
                };
                //imgList.push(addFile);
                this.imgFileList.push(addFile);
            }
            //MenuHelper.addWork(this.$router, this.roomId, imgList);
        }
    }
};
</script>

<template>
    <section class="round-section">
        <div class="in-section right">
            <header class="rounder-header">
                <h2>
                    <a :class="headerClass()">{{ wtitle }}</a>
                </h2>
                <button ref="tooltipBtn" type="button" class="alarm-warning" @click="showTooltip()">
                    <span>{{ count }}건</span><span class="tooltip">최근 1달간 미조치 건수입니다.</span>
                </button>
            </header>
            <div class="round-photo-list">
                <div class="scroll-wrap">
                    <div class="inner">
                        <ul class="photo-list">
                            <li v-for="(work, index) in works" :key="index">
                                <a @click="menuWork(work)">
                                    <div class="thumb">
                                        <div class="thumb-inner">
                                            <img
                                                :src="work.imgUrl | sThumbUrl"
                                                onerror="javascript:this.src='/images/common/default.png'"
                                            />
                                        </div>
                                        <div class="count" :class="work.countOfAction === 0 ? 'done' : ''">
                                            {{ work.countOfAction }}
                                        </div>
                                    </div>
                                    <div class="thumb_comment">
                                        <p>{{ substr(work.content) }}</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MenuHelper from '../../helper/MenuHelper';

export default {
    name: 'WorkList',
    components: {},
    props: {
        wtitle: {
            type: String,
            required: true
        },
        count: {
            type: Number,
            required: true
        },
        wtype: {
            type: String,
            required: true
        },
        works: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        showTooltip() {
            this.$refs.tooltipBtn.classList.add('on');
            setTimeout(() => {
                this.$refs.tooltipBtn.classList.remove('on');
            }, 1000);
        },
        headerClass() {
            return this.wtype === 'my' ? 'send-task' : 'rcv-task';
        },
        menuWork(work) {
            MenuHelper.work(this.$router, work.id);
        },
        substr(value) {
            if (value && value.length > 10) return value.substring(0, 10);
            return value;
        }
    }
};
</script>

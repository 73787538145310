<template>
    <section class="notice-oneline-area oneline-link">
        <div class="in-section">
            <div class="shadow-box">
                <div class="swiper-container">
                    <swiper ref="mainSwiper" :options="swiperOption">
                        <swiper-slide v-for="(board, index) in boards" :key="index" class="swiper-slide">
                            <a @click="menuBoard(board)">
                                <span class="subject">{{ board.title }}</span>
                                <span class="date">{{ board.regDt | formatTime }}</span>
                            </a>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MenuHelper from '../../helper/MenuHelper';

import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'NoticeTicker',
    components: { swiper, swiperSlide },
    props: {
        boards: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {
            swiperOption: {
                direction: 'vertical',
                loop: true,
                autoplay: {
                    delay: 3000
                },
                lazy: true
            }
        };
    },
    computed: {
        ...mapState({}),
        swiper() {
            return this.$refs.mainSwiper.swiper;
        }
    },
    created() {},
    mounted() {
        //console.log('Current Swiper instance object', this.swiper);
        //this.swiper.slideTo(0, 1000, false);
        this.swiper.updateAutoHeight(3000);
        //this.swiper.slideReset(3000, true);
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['hideBoard']),

        menuBoard(board) {
            MenuHelper.notice(this.$router, board.id);
        }
    }
};
</script>

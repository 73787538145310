<template>
    <div class="task-info-btm-box">
        <dl>
            <dt><span class="tit-charger">담당</span></dt>
            <dd>
                <div>
                    <span v-for="(user, index) in users" :key="index">
                        <a @click="callhp(user.manager.hp)">
                            {{ user.manager.displayName }}&middot;{{ user.manager.company.name }}
                        </a>
                    </span>
                </div>
            </dd>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'ChargeBox',
    components: {},
    props: {
        users: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        callhp(hp) {
            if (hp) window.open('tel:' + hp, '_system');
        }
    }
};
</script>

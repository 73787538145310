import MutationTypes from '../../MutationTypes';

export default {
    setJoin: async ({ commit }, join) => {
        commit(MutationTypes.SET_JOIN, join);
    },
    setSmsCert: async ({ commit }, smsCert) => {
        commit(MutationTypes.SET_SMS_CERT, smsCert);
    }
};

<template>
    <ul class="list">
        <li v-for="(work, index) in works" :key="index">
            <section class="round-section">
                <div class="in-section task-box">
                    <div class="task-inbox">
                        <div class="task-type">
                            <a @click="menuWork(work.id)">
                                <span :class="taskType(work)">{{ taskName(work) }}</span>
                            </a>
                        </div>

                        <div v-if="work.imgUrl" class="task-thumb">
                            <a @click="menuWork(work.id)">
                                <div class="thumb">
                                    <div class="thumb-inner">
                                        <img :src="work.imgUrl | thumbUrl" />
                                    </div>
                                    <div v-if="work.type === 'work'" class="count">
                                        {{ work.countOfAction }}
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="task-summary">
                            <a class="charger" @click="menuWork(work.id)">
                                {{ work.register.displayName }}&middot;{{ work.register.company.name }}
                            </a>
                            <p class="summary">
                                <a @click="menuWork(work.id)">{{ work.content }}</a>
                            </p>
                            <div class="meta-info">
                                <a @click="menuWork(work.id)">
                                    <span class="datetime">{{ work.regDt | longFormatTime }}</span>
                                    <span class="comment-cnt">댓글 {{ work.countOfComment }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </li>
    </ul>
</template>

<script>
import { WORK_TYPE, WORK_TYPE_CSS } from '../../const';
import MenuHelper from '../../helper/MenuHelper';

export default {
    name: 'SearchItem',
    components: {},
    props: {
        works: {
            type: Array,
            required: true
        }
    },
    data: () => {
        return {
            selectId: {}
        };
    },
    computed: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        menuWork(id) {
            MenuHelper.work(this.$router, id);
        },
        taskName(work) {
            return WORK_TYPE[work.type];
        },
        taskType(work) {
            return WORK_TYPE_CSS[work.type];
        },
        getDetailClass(type) {
            if (type === 'work') return 'btn-reg-repair';
            else return 'btn-repair-detail';
        },
        getDetailText(type) {
            if (type === 'work') return '조치 등록';
            else return '상세보기';
        },
        showUtil(id) {
            //MenuHelper.work(this.$router, id);
            this.selectId = id;
        }
    }
};
</script>

<template>
    <div>
        <Header :loc-nm="basic.site + ' 요청자'" loc-type="regist" :fn-confirm="approveWorker" />
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="이름,직위, 회사 검색"
                            @keyup="filterWorker"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>
        <article id="content-wrap">
            <main id="contents">
                <div class="contents-body">
                    <ul class="group-list">
                        <li v-for="(worker, index) in filterWorkers" :key="index">
                            <div class="member-info-box">
                                <div class="chk">
                                    <input
                                        :id="'chk-1-' + index"
                                        v-model="workerIds"
                                        type="checkbox"
                                        :value="worker.id"
                                    />
                                    <label :for="'chk-1-' + index"><span class="blind">선택</span></label>
                                </div>
                                <div class="profile">
                                    <div class="short-nm">
                                        <img
                                            :src="worker.imgUrl | profileUrl"
                                            onerror="javascript:this.src='/images/common/default.png"
                                        />
                                    </div>
                                </div>
                                <div class="member-info">
                                    <p class="nm">
                                        {{ worker.name }}
                                    </p>
                                    <div class="meta-info">
                                        <span>{{ worker.companyName }}</span>
                                        <span>{{ worker.regDt | formatTime }}</span>
                                    </div>
                                </div>
                                <div class="phone">
                                    <a @click="callhp(worker.hp)"><span class="blind">전화</span></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </main>
        </article>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Header from '../header/RegistWhiteHeader.vue';
//import AffilationSiteApi from '../../api/AffilationSiteApi';
import AffilationSiteApi from '../../api/AffilationSiteApi';

export default {
    name: 'ApproveWorker',
    components: { Header },
    data: () => {
        return {
            searchText: '',
            workers: [],
            filterWorkers: [],
            workerIds: []
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        //this.condition.inCsId = this.basic.csId;
    },
    mounted() {
        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        doSearch() {
            AffilationSiteApi.siteOfrequest().then(res => {
                this.workers = res.content.map(item => {
                    const worker = {};
                    worker.id = item.worker.id;
                    worker.name = item.worker.displayName;
                    worker.companyName = item.worker.company.name;
                    worker.hp = item.worker.hp;
                    worker.imgUrl = item.worker.imgUrl;
                    worker.regDt = item.regDt;
                    return worker;
                });
                this.filterWorkers = this.workers;
            });
        },
        filterWorker() {
            this.filterWorkers = this.workers.filter(
                work => work.name.indexOf(this.searchText) > -1 || work.companyName.indexOf(this.searchText) > -1
            );
        },
        async approveWorker() {
            this.showLoading();
            await AffilationSiteApi.approveWorker(this.workerIds);
            await this.doSearch();
            this.hideLoading();
            //this.$router.go(-1);
        },
        delSearchText() {
            this.searchText = '';
            this.filterWorker();
        },
        callhp(hp) {
            if (hp) window.open('tel:' + hp, '_system');
        }
    }
};
</script>

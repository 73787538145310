import { httpClient, downloadClient, uploadClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import FileHelper from '../helper/FileHelper';
import authHeader from './Header';

const getFileName = contentDisposition => {
    const fileName = contentDisposition
        .split(';')
        .filter(ele => {
            return ele.indexOf('filename') > -1;
        })
        .map(ele => {
            return ele.replace(/"/g, '').split('=')[1];
        });
    return fileName[0] ? fileName[0] : null;
};

const getMimeByExt = fileName => {
    var extToMimes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        tif: 'image/tif',
        ai: 'image/ai',
        bmp: 'image/bmp',
        pdf: 'application/pdf',
        zip: 'application/zip',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.ms-excel',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.ms-powerpoint',
        doc: 'application/msword',
        docx: 'application/msword',
        hwp: 'application/x-hwp',
        mp4: 'video/mp4',
        mov: 'video/quicktime'
    };
    const ext = fileName.split('.').pop() || '';
    if (hasOwnProperty.call(extToMimes, ext.toLowerCase())) {
        return extToMimes[ext];
    }
    return 'application/x-msdownload';
};

const fileOne = id => httpClient.get(`/file/fileOne/${id}`, { headers: authHeader() }).then(res => res.data);
const list = id =>
    httpClient.get(`/file/fileInfo?fileId=${id}`, { headers: authHeader() }).then(res => res.data.content);

const fileDownload = id => {
    downloadClient
        .get(`/file/download/file?file_id=${id.fileId}&file_no=${id.fileNo}`, { headers: authHeader() })
        .then(res => {
            try {
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                let fileName = getFileName(res.headers['content-disposition']);
                fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
                // not IE
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.target = '_self';
                if (fileName) link.download = fileName;
                link.click();
            } catch (e) {
                console.error(e);
            }
        });
};

const downloadData = id => {
    downloadClient
        .get(`/file/download/file?file_id=${id.fileId}&file_no=${id.fileNo}`, { headers: authHeader() })
        .then(res => {
            try {
                let fileName = getFileName(res.headers['content-disposition']);
                fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
                const blob = new Blob([res.data], { type: getMimeByExt(fileName) });

                const data = { blob, fileName };
                FileHelper.appFileDownload(data);
            } catch (e) {
                console.error(e);
            }
        });
};

const printImage = (id, thumb) => {
    downloadClient
        .get(`/file/download/file?file_id=${id.fileId}&file_no=${id.fileNo}&thumNails=${thumb}`, {
            headers: authHeader()
        })
        .then(res => {
            try {
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                console.log(window.URL.createObjectURL(blob));
            } catch (e) {
                console.error(e);
            }
        });
};

const imgUpload = param => uploadClient.post('/file/imgupload', param, { headers: authHeader() }).then(res => res.data);

const blolImgUpload = param =>
    uploadClient.post('/file/blolImgupload', param, { headers: authHeader() }).then(res => res.data);

const myPhotoBlolImgupload = param =>
    uploadClient.post('/file/blolImgupload', param, { headers: authHeader() }).then(res => res.data);
/*
const fileUpload = (param, type) =>
    uploadClient.post('/file/upload/' + type, param, { headers: authHeader() }).then(res => res.data);
*/
const fileUpload = (param, type) =>
    uploadClient.post('/docfile/upload/' + type, param, { headers: authHeader() }).then(res => res.data);

const remove = param =>
    uploadClient
        .delete(`/file/delete?file_id=${param.fileId}&file_no=${param.fileNo}`, { headers: authHeader() })
        .then(res => res.data);

const updateGps = param =>
    httpClient
        .put(`/filemgmt-gps/${param.fileId}/${param.fileNo}`, param, { headers: authHeader() })
        .then(res => res.data);

const urlTobase64 = (fileId, fileNo) =>
    httpClient.get(`/file/getImageToBase64/${fileId}/${fileNo}`, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    fileOne: wrapHttpClient(fileOne),
    remove: wrapHttpClient(remove),
    updateGps: wrapHttpClient(updateGps),
    urlTobase64: wrapHttpClient(urlTobase64),
    fileDownload,
    imgUpload,
    blolImgUpload,
    fileUpload,
    downloadData,
    myPhotoBlolImgupload,
    printImage
};

<template>
    <div id="signup-wrap">
        <div id="signup-container">
            <div id="signup-header">
                <p class="single-tit">
                    {{ basic.site }}
                </p>
                <p class="subtit">
                    회원가입이 완료되었습니다.
                </p>
            </div>
            <div id="signup-body">
                <div class="signup-done">
                    <p class="signup-done-txt">
                        사진으로 협업하는
                        <br />
                        COCO 회원에 가입하셨습니다.
                    </p>
                    <p class="txt">
                        현장에서 가입 승인을 처리하는 동안,
                        <br />
                        COCO 모바일앱을 설치하지 않으신 분은 설치하세요.
                    </p>
                    <div class="btn-wrap">
                        <a @click="appDownload()"><span>사진으로 협업한다! COCO 시작하기</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MobileHelper from '../../helper/MobileHelper';

export default {
    name: 'JoinDone',
    components: {},
    data: () => {
        return {};
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        appDownload() {
            const platform = MobileHelper.getPlatform();
            if (MobileHelper.isWebView()) {
                this.$router.push({ path: '/login' });
                //alert('이미 설치하셨네요~');
            } else {
                if (platform === 'ios' || platform === 'android') {
                    window.location.href = process.env.APP_DOWNLOAD;
                } else {
                    alert('download 할 수 없는 플랫폼입니다.');
                }
            }
        }
    }
};
</script>

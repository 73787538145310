export const nvl = str => {
    if (str === null && str === '' && typeof str === 'undefined' && str === 'NaN') {
        str = '';
    }
    return str;
};

export const isEmpty = input => {
    // if (typeof input === 'array') {
    if (Array.isArray(input)) {
        return input.length === 0;
    }

    return !input || Object.keys(input).length === 0;
};

export const numComma = number => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};

export const removeChar = event => {
    event = event || window.event;
    var keyID = event.which ? event.which : event.keyCode;
    if (keyID === 8 || keyID === 46 || keyID === 37 || keyID === 39) return;
    else event.target.value = event.target.value.replace(/[^0-9.,]/g, '');
};

export const convertNewLine = value => {
    if (typeof value === 'undefined') {
        return;
    }

    var formatingValues = [{ from: '<br>', to: '\n' }];

    for (var i = 0; i < formatingValues.length; i++) {
        value = value.split(formatingValues[i].from).join(formatingValues[i].to);
    }

    return value;
};

const URL_REGEXP = [
    /(\b(http?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
    /(^|[^/])(www\.[\S]+(\b|$))/gim
];

export const linkUrl = value => {
    var exp = URL_REGEXP[0],
        text1 = value.replace(exp, "<a target='_blank' style='color:blue;' href='$1'>$1</a>"),
        exp2 = URL_REGEXP[1];
    return text1.replace(exp2, '$1<a target="_blank" style="color:blue;" href="http://$2">$2</a>');
};

const HTML_ENTITY_MAP = {
    '<': '&#60;',
    '>': '&#62;',
    "'": '&#39;',
    '"': '&#34;',
    '`': '&#96;'
};
const HTML_ENTITY_REGEXP = /[<>'"`]/gi;

export const convertHtmlEntity = value => {
    return value ? value.replace(HTML_ENTITY_REGEXP, match => HTML_ENTITY_MAP[match]) : '';
};

export const removeEmoji = value => {
    const ranges = [
        '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
        '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
        '\ud83d[\ude80-\udeff]' // U+1F680 to U+1F6FF
    ];
    if (value) return value.replace(new RegExp(ranges.join('|'), 'g'), '');

    return value;
};

const ENTITY_HTML_REGEXP = /&(nbsp|quot|lt|gt|#60|#62|#39|#34|#96|amp|yen|bull);/g;

const ENTITY_HTML_MAP = {
    '&quot;': '"',
    '&lt;': '<',
    '&gt;': '>',
    '&#60;': '<',
    '&#62;': '>',
    '&#39;': "'",
    '&#34;': '"',
    '&#96;': '`',
    '&amp;': '&',
    '&yen;': '¥',
    '&bull;': '•'
};

export const convertEntityHtml = value => {
    return value ? value.replace(ENTITY_HTML_REGEXP, match => ENTITY_HTML_MAP[match]) : '';
    //return value.replace(ENTITY_HTML_REGEXP, match => ENTITY_HTML_MAP[match]);
};

const URL_PARAM_REGEXP = /[&|@|#|/|%|=|~]/gi;

export const isDeniedChar = value => {
    return URL_PARAM_REGEXP.test(value);
};

export const convertSpecialChar = value => {
    return value ? value.replace(URL_PARAM_REGEXP, '') : '';
    //return value.replace(ENTITY_HTML_REGEXP, match => ENTITY_HTML_MAP[match]);
};

export const convertCrToBr = value => {
    if (value) return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    else return '';
};

const URLMatcher = /(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;

export const withLinks = value => {
    if (value) {
        const url = value.replace(URLMatcher, match => {
            if (match.indexOf('http') === 0) return `<a href="${match}" class="coco-link" target="_new">${match}</a>`;
            else return `<a href="http://${match}" class="coco-link" target="_new">${match}</a>`;
        });
        return url;
    } else return '';
};

const ImagePickerHelper = {};

ImagePickerHelper.setOptions = imgCount => {
    const options = {
        maximumImagesCount: imgCount,
        width: document.documentElement.clientWidth * 2.3,
        height: document.documentElement.clientHeight * 2.3,
        //width: 1200,
        //height: 1200,
        // window.imagePicker.OutputType.FILE_URI (0) or
        // window.imagePicker.OutputType.BASE64_STRING (1)
        outputType: 1
    };
    return options;
};

ImagePickerHelper.getPictures = (callbackFn, imgCount) => {
    if (imgCount === 0) return;
    if (!imgCount) imgCount = 5;
    const ImagePicker = window.imagePicker;
    ImagePicker.getPictures(
        results => {
            if (results.length > 0) {
                const responses = results.map(imageUri => {
                    //return base64ToBlob('data:image/jpeg;base64,' + imageUri);
                    return 'data:image/jpeg;base64,' + imageUri;
                });
                callbackFn(responses);
            }
        },
        error => {
            alert('Error: ' + error);
        },
        ImagePickerHelper.setOptions(imgCount)
    );
};

ImagePickerHelper.getPicture = callbackFn => {
    const ImagePicker = window.imagePicker;
    ImagePicker.getPictures(
        results => {
            if (results.length > 0) {
                const responses = results.map(imageUri => {
                    return 'data:image/jpeg;base64,' + imageUri;
                });
                callbackFn(responses[0]);
            }
        },
        () => {},
        ImagePickerHelper.setOptions(1)
    );
};

ImagePickerHelper.originPictures = callbackFn => {
    const ImagePicker = window.imagePicker;
    const options = {
        maximumImagesCount: 10,
        outputType: 0
    };

    ImagePicker.getPictures(
        results => {
            if (results.length > 0) {
                const responses = results.map(imageUri => {
                    //return base64ToBlob('data:image/jpeg;base64,' + imageUri);
                    //return 'data:image/jpeg;base64,' + imageUri;
                    return imageUri;
                });
                callbackFn(responses);
            }
        },
        error => {
            alert('Error: ' + error);
        },
        options
    );
};

export default ImagePickerHelper;

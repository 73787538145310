import BoardList from '../components/board/BoardList';
import BoardRegist from '../components/board/BoardRegist';
import BoardDetail from '../components/board/BoardDetail';
import KakaoBoardDetail from '../components/board/KakaoBoardDetail';

import QnaList from '../components/board/QnaList';
import QnaRegist from '../components/board/QnaRegist';
import QnaDetail from '../components/board/QnaDetail';

export default [
    {
        path: '/notice',
        name: 'Notice',
        component: BoardList,
        meta: {
            type: 'notice'
        }
    },
    {
        path: '/notice/regist',
        name: 'NoticeRegist',
        component: BoardRegist,
        meta: {
            type: 'notice',
            bodyClass: 'write-fixed'
        }
    },
    {
        path: '/notice/:id',
        name: 'NoticeDetailById',
        component: BoardDetail,
        meta: {
            type: 'notice',
            bodyClass: 'board-detail'
        }
    },
    {
        path: '/kakao/notice/:id',
        name: 'KakaoNoticeDetailById',
        component: KakaoBoardDetail,
        meta: {
            type: 'notice',
            bodyClass: 'board-detail'
        }
    },
    {
        path: '/notice/regist/:id',
        name: 'NoticeRegistById',
        component: BoardRegist,
        meta: {
            type: 'notice',
            bodyClass: 'write-fixed'
        }
    },
    {
        path: '/daily',
        name: 'Daily',
        component: BoardList,
        meta: {
            type: 'daily'
        }
    },
    {
        path: '/daily/regist',
        name: 'DailyRegist',
        component: BoardRegist,
        meta: {
            type: 'daily',
            bodyClass: 'write-fixed'
        }
    },
    {
        path: '/daily/:id',
        name: 'DailyDetailById',
        component: BoardDetail,
        meta: {
            type: 'daily',
            bodyClass: 'board-detail'
        }
    },
    {
        path: '/kakao/daily/:id',
        name: 'KakaoDailyDetailById',
        component: KakaoBoardDetail,
        meta: {
            type: 'daily',
            bodyClass: 'board-detail'
        }
    },
    {
        path: '/daily/regist/:id',
        name: 'DailyRegistById',
        component: BoardRegist,
        meta: {
            type: 'daily',
            bodyClass: 'write-fixed'
        }
    },
    {
        path: '/qna',
        name: 'Qna',
        component: QnaList,
        meta: {
            type: 'qna'
        }
    },
    {
        path: '/qna/regist',
        name: 'QnaRegist',
        component: QnaRegist,
        meta: {
            type: 'qna',
            bodyClass: 'write-fixed'
        }
    },
    {
        path: '/qna/:id',
        name: 'QnaDetailById',
        component: QnaDetail,
        meta: {
            type: 'qna',
            bodyClass: 'board-detail'
        }
    }
];

import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import authHeader from './Header';

const list = size => httpClient.get(`/searchword/last/${size}`, { headers: authHeader() }).then(res => res.data);
const remove = id => httpClient.delete(`/searchword/${id}`, { headers: authHeader() }).then(res => res.data);
const regist = param => httpClient.post(`/searchword`, param, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    regist: wrapHttpClient(regist),
    remove: wrapHttpClient(remove)
};

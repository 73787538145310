import MutationTypes from '../../MutationTypes';
import ArrayHelper from '../../../helper/ArrayHelper';

export default {
    [MutationTypes.SET_MY_ROOMS]: (state, payload) => {
        state.roomList = payload;
    },
    [MutationTypes.ADD_MY_ROOM_CNT]: (state, roomIdx) => {
        state.roomList[roomIdx].alarmCnt++;
    },
    [MutationTypes.ADD_MY_ALARM_CNT]: state => {
        state.alarmCnt++;
    },
    [MutationTypes.RESET_MY_ROOM_CNT]: (state, roomIdx) => {
        state.roomList[roomIdx].alarmCnt = 0;
    },
    [MutationTypes.SET_WORK_ID]: (state, payload) => {
        state.workId = payload;
    },
    [MutationTypes.SET_SHOW_WORK]: (state, payload) => {
        state.showWork = payload;
    },
    [MutationTypes.SET_WORK_OF_ROOM]: (state, payload) => {
        if (state.workOfRoom.roomId !== payload.roomId || payload.page === 1) {
            state.workOfRoom = payload;
        } else {
            //payload.workOfRoom.works.unshift(...payload.works);
            state.workOfRoom.works.push(...payload.works);
            state.workOfRoom.page = payload.page;
        }
    },
    [MutationTypes.ADD_WORK_OF_ROOM]: (state, payload) => {
        state.workOfRoom.works.unshift(payload);
        ++state.workOfRoom.total;
    },
    [MutationTypes.REMOVE_WORK_OF_ROOM]: (state, payload) => {
        const condition = item => item.id === payload.id;
        ArrayHelper.oneRemove(state.workOfRoom.works, condition);
        --state.workOfRoom.total;
    },
    [MutationTypes.SET_PICTURE]: (state, payload) => {
        state.picture = payload;
    },
    [MutationTypes.SET_WORK_OF_SEARCH]: (state, payload) => {
        state.searchList = payload;
    },
    [MutationTypes.SET_SEARCH_CONDITION]: (state, payload) => {
        state.searchCondition = payload;
    },
    [MutationTypes.SET_TEMP_WORK_CONTENT]: (state, payload) => {
        state.tempWorkContent = payload;
    },
    [MutationTypes.SET_TEMP_WORK_DOCFILE]: (state, payload) => {
        state.tempDocFile = payload;
    },
    [MutationTypes.SET_TEMP_IMG_LIST]: (state, payload) => {
        state.tempImageList = payload;
    }
};

<template>
    <header id="header" class="header primary">
        <div class="in-section">
            <ul>
                <li class="back">
                    <button type="button" class="btn-back" @click="back()">
                        <span class="blind">뒤로</span>
                    </button>
                </li>
                <li class="loc-nm">
                    <span>{{ locNm }}</span>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'SimpleHeader',
    components: {},
    props: {
        locNm: {
            type: String,
            required: true
        },
        headerClass: {
            type: String,
            required: false,
            default: 'header'
        }
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            this.$router.go(-1);
        }
    }
};
</script>

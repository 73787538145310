<template>
    <div>
        <Header loc-nm="개인정보처리방침" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <div class="rules-wrap">
                        <div class="rules-box">
                            <p>
                                ‘㈜대우건설’은(이하 ‘회사는’) “정보통신망 이용촉진 및 정보보호에 관한 법률” 및 “개인정
                                보보호법” 등 사업자가 준수하여야 할 관련 법령상의 개인정보 보호 규정을 준수하며, 관련
                                법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
                            </p>
                        </div>
                        <div class="rules-box">
                            <strong>01. 개인정보의 수집목적 및 수집항목</strong>
                            <p>
                                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의
                                목적이외의 용도로 사용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에
                                따라 필요한 조치를 이행합니다.
                            </p>

                            <ul>
                                <li>수집목적: 회원가입 및 자격유지, 서비스 제공</li>
                                <li>수집항목: 이름, 휴대번호, 비밀번호, 소속회사, 직위</li>
                                <li>
                                    회사의 서비스 이용 과정에서 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 불량이용
                                    기록이 생성되어 수집될 수 있습니다.
                                </li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>02. 개인정보의 보유 및 이용기간</strong>
                            <p>
                                소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는
                                관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를
                                그 보관의 목적으로만 이용하며, 위의 보관 기간이 지난 후에는 예외 없이 해당 정보를 지체
                                없이 파기합니다.
                            </p>
                            <ul>
                                <li>보존항목: 회원정보, 서비스 통한 이용 정보</li>
                                <li>보존근거: 전자상거래 등에서의 소비자보호에 관한 법률에 근거</li>
                                <li>보존기간: 5년</li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>03. 개인정보의 파기절차 및 방법</strong>
                            <p>
                                회사는 원칙적으로 회운의 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이
                                파기합니다. 다만, 다른 법률에 따라 보존하여야하는 경우에는 그러하지 않으며, 회사의
                                개인정보 파기절차 및 방법은 다음과 같습니다.
                            </p>
                            <ul>
                                <li style="margin-bottom: 10px;">
                                    파기절차: 회원이 입력하신 정보는 목적이 달성, 해당 서비스의 폐지, 사업의 종료 등 그
                                    개인정보파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는
                                    날로부터 지체 없이 재생할 수 없는 방법에 의하여 디스크에서 완전히 삭제하며, 추후
                                    열람이나 이용이 불가능한 상태로 파기됩니다.
                                </li>
                                <li>
                                    파기방법: 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을
                                    사용하여 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                                    파기합니다.
                                </li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>04. 개인정보의 제3자 제공</strong>
                            <p>
                                회사는 회원의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는
                                예외로 합니다.
                            </p>
                            <ul>
                                <li>고객들이 사전에 동의한 경우</li>
                                <li>
                                    법률의 특별한 규정이 있는 경우(법령에 정해진 절차와 방법에 따라 수사기관의 요구가
                                    있는 경우)
                                </li>
                                <li>
                                    통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는
                                    형태로 가공하여 제공하는 경우
                                </li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>05. 개인정보처리 위탁</strong>
                            <p>
                                회사는 원활한 업무처리를 위하여 아래와 같은 업무를 전문업체에 처리 위탁하여 운영하고
                                있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을
                                규정 하고 있습니다.
                            </p>
                            <ul>
                                <li>위탁업체: 주식회사 앤더비커뮤니케이션</li>
                                <li>위탁업무내용: 모바일앱 서비스 및 시스템 관리</li>
                                <li>담당자: 앤더비커뮤니케이션 개발팀</li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>06. 회원의 권리&middot;의무 및 그 행사방법에 대한 사항</strong>
                            <p>회원은 언제든지 다음 각 호의 개인정보 관련 권리를 행사할 수 있습니다.</p>
                            <ul>
                                <li>개인정보 열람요구, 오류 정정 요구, 삭제 요구</li>
                                <li>
                                    권리 행사는 고객의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
                                    있습니다.
                                </li>
                                <li>
                                    회원의 개인정보의 처리에 동의하지 않는 경우 서비스의 일부 또는 전부 이용이 제한될 수
                                    있습니다.
                                </li>
                                <li>
                                    개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
                                    있는 경우에는 그 삭제를 요구할 수 없습니다.
                                </li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>07. 개인정보의 안전성 확보 조치</strong>
                            <p>
                                회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적,
                                물리적 조치를 하고 있습니다.
                            </p>
                            <ul>
                                <li>개인정보 처리자 교육 실시</li>
                                <li>개인정보에 대한 접근 제한</li>
                                <li>개인정보의 암호화</li>
                                <li>해킹 등에 대비한 기술적 대책</li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>08. 개인정보 자동수집 장치와 운영&middot;거부에 관한 사항</strong>
                            <p>
                                회사는 회사의 정보를 수시로 저장하고 찾아내는 쿠키(cookie) 등을 운용합니다. 쿠키란
                                웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서
                                귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
                            </p>
                            <ul>
                                <li style="margin-bottom: 10px;">
                                    사용목적: 고객의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및
                                    자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인
                                    맞춤 서비스 제공
                                </li>
                                <li style="margin-bottom: 10px;">
                                    회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을
                                    설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나, 아니면
                                    모든 쿠키의 저장을 거부할 수도 있습니다.
                                </li>
                                <li style="margin-bottom: 10px;">
                                    쿠키 설정 거부방법: 예시)쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을
                                    거부할 수 있습니다.
                                </li>
                                <li style="margin-bottom: 10px;">
                                    쿠키 설정방법: 예시) 인터넷 익스플로어의 경우 - 웹 브라우저 상단의 도구 > 인터넷
                                    옵션 > 개인 정보
                                </li>
                                <li>쿠기 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</li>
                            </ul>
                        </div>

                        <div class="rules-box">
                            <strong>09. 고지의 의무</strong>
                            <p>
                                현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터
                                '공지사항'을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이
                                이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
                            </p>
                        </div>

                        <div class="rules-box">
                            <strong>10. 개인정보에 관한 민원서비스</strong>
                            <p>
                                회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이
                                관련 부서 및 개인정보보호책임자를 지정하고 있습니다.
                            </p>
                            <ul>
                                <li>개인정보보호책임 담당부서: 대우건설기술연구원</li>
                                <li>전화번호: 031-250-1106</li>
                                <li style="margin-bottom: 20px;">
                                    E-mail: sungoh.byun@daewooenc.com
                                </li>
                            </ul>
                            <p>
                                회원은 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호
                                담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한
                                답변을 드릴 것입니다.
                            </p>
                            <p>
                                기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
                                바랍니다.
                            </p>
                            <ul>
                                <li>개인정보침해신고센터<br />(privacy.kisa.or.kr / 국번없이 118)</li>
                                <li>대검찰청 사이버수사과<br />(www.spo.go.kr / 국번없이 1301)</li>
                                <li style="margin-bottom: 50px;">
                                    경찰청 사이버안전국<br />(cyberbureau.police.go.kr / 국번없이 182)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/SimpleHeader.vue';

export default {
    name: 'Privacy',
    components: { Header },
    data: () => {
        return {};
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([])
    }
};
</script>

import AlarmList from '../components/alarm/AlarmList';
//import { requireAuth } from './before';
export default [
    {
        path: '/alarm',
        name: 'AlarmList',
        component: AlarmList
        //beforeEnter: requireAuth()
    }
];

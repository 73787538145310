<template>
    <div ref="filterRegisterLayer" class="filter-select-wrap filter-register-wrap">
        <header class="header primary">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="close">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>등록자 선택</span>
                    </li>
                    <li class="etc">
                        <button type="button" class="btn-etc" @click="doConfirm()">
                            <span>확인</span>
                        </button>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="이름, 회사, 직위 검색"
                            @input="setFilterWorker"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-result-box">
            <div class="filter-result">
                <ul class="modal-option-list">
                    <li v-for="(worker, index) in filterWorkers" :key="index">
                        <div class="radio-list">
                            <input
                                :id="'modal-radiorg-1-1' + index"
                                v-model="register"
                                type="radio"
                                class="blind"
                                :value="worker"
                            />
                            <label :for="'modal-radiorg-1-1' + index">
                                {{ worker.name }}&middot;{{ worker.companyName }}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';
import WorkerApi from '../../api/WorkerApi';
import AffilationSiteApi from '../../api/AffilationSiteApi';

export default {
    name: 'FilterManager',
    components: {},
    data: () => {
        return {
            roomId: 0,
            workers: [],
            filterWorkers: [],
            register: {},
            searchText: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        this.roomId = Number(this.$route.params.roomId);
        this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterRegisterLayer.classList.remove('on');
        },
        setFilterWorker() {
            this.filterWorkers = this.workers.filter(
                work => work.name.indexOf(this.searchText) > -1 || work.companyName.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterWorker();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setRegister', this.register);
            this.close();
        },
        async bind() {
            let response = {};
            if (this.roomId) {
                response = await WorkerApi.listOfroom(this.roomId);

                this.workers = response.map(item => {
                    const worker = {};
                    worker.id = item.id;
                    worker.name = item.displayName;
                    worker.companyName = item.company.name;
                    return worker;
                });
            } else {
                response = await AffilationSiteApi.workerOfsite(this.basic.csId);
                this.workers = response.map(item => {
                    const worker = {};
                    worker.id = item.worker.id;
                    worker.name = item.worker.displayName;
                    worker.companyName = item.worker.company.name;
                    return worker;
                });
            }
            this.filterWorkers = this.workers;
        }
    }
};
</script>

import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import authHeader from './Header';

const list = query => httpClient.get(`/workDaily`, { params: query, headers: authHeader() }).then(res => res.data);

const detail = id => httpClient.get(`/workDaily/${id}`, { headers: authHeader() }).then(res => res.data.content);
const detailByDay = today =>
    httpClient.get(`/workDaily/by/${today}`, { headers: authHeader() }).then(res => res.data.content);

const remove = id => httpClient.delete(`/workDaily/${id}`, { headers: authHeader() }).then(res => res.data);
const regist = param => httpClient.post(`/workDaily`, param, { headers: authHeader() }).then(res => res.data);

const copyPrevData = today =>
    httpClient.post(`/workDaily/copyPrevData/${today}`, {}, { headers: authHeader() }).then(res => res.data);

const companyEquips = today =>
    httpClient.get(`/company/equip/${today}`, { headers: authHeader() }).then(res => res.data);
const companyOccupations = today =>
    httpClient.get(`/company/occupation/${today}`, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    detail: wrapHttpClient(detail),
    detailByDay: wrapHttpClient(detailByDay),
    regist: wrapHttpClient(regist),
    remove: wrapHttpClient(remove),
    copyPrevData: wrapHttpClient(copyPrevData),
    companyEquips: wrapHttpClient(companyEquips),
    companyOccupations: wrapHttpClient(companyOccupations)
};

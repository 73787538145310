import LogApi from '../api/LogApi';

import { APPLICATION_VERSION, APPLICATION_PHASE } from '../const';

import { getLanguage } from '../utils/system';

const initialize = () => {
    window.addEventListener('error', (message, file, line, column, errorObject) => {
        sendLog(errorObject, message, file, line, column);
        return true;
    });
};

const sendLog = (errorObject, message, file, line) => {
    if (!errorObject) {
        return;
    }

    const log2Object = buildQueryString(errorObject, message, file, line);
    LogApi.sendLog(log2Object);
};

const buildQueryString = (errorObject, message, file, line) => {
    //기본 LINE 정보
    const body = {};
    file = file || '';
    line = line || 0;

    //Error 정보 추가
    if (errorObject instanceof Error) {
        body.error = {
            name: errorObject.name,
            message: errorObject.message,
            stack: errorObject.stack
        };
    } else {
        body.error = errorObject;
    }

    //Platform 정보
    let platform = 'pc';
    if (navigator.userAgent.toLocaleLowerCase().indexOf('mobile') >= 0) {
        platform = 'mobile';
    }

    return {
        projectVersion: APPLICATION_VERSION,
        logType: body.error.message + '(' + file.split('/').pop() + ':' + line + ')',
        phase: APPLICATION_PHASE,
        language: getLanguage(),
        platform,
        userAgent: navigator.userAgent,
        body: JSON.stringify(body)
    };
};

export default {
    initialize,
    sendLog
};

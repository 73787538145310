export const profileUrl = url => {
    if (!url) return '/images/main/profile.jpg';

    return url;
};

export const profileThumbUrl = url => {
    if (!url) return '/images/main/profile.jpg';
    return url + '_400X400';
};

export const sProfileThumbUrl = url => {
    if (!url) return '/images/main/profile.jpg';
    return url + '_130X130';
};

export const thumbUrl = url => {
    if (!url) return '/images/common/default.png';

    return url + '_400X400';
};

export const sThumbUrl = url => {
    if (!url) return '/images/common/default.png';

    return url + '_130X130';
};

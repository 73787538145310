<template>
    <div ref="filterManagerLayer" class="filter-select-wrap filter-people-wrap" :class="tpId">
        <header class="header primary">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="close">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>담당자 선택</span>
                    </li>
                    <li class="board-detail">
                        <a class="btn-delete" @click="allSelect()">
                            <span>{{ allText }}</span>
                        </a>
                        <a class="btn-modify" @click="doConfirm()">
                            <span>확인</span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="이름, 회사, 직위 검색"
                            @input="setFilterWorker"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-result-box">
            <div class="filter-result">
                <ul class="modal-option-list">
                    <li v-for="(worker, index) in filterWorkers" :key="index">
                        <div class="chk-list">
                            <input
                                :id="tpId + '-modal-chkmg-1-1' + index"
                                v-model="managers"
                                type="checkbox"
                                class="blind"
                                :value="worker"
                            />
                            <label :for="tpId + '-modal-chkmg-1-1' + index">
                                {{ worker.name }}&middot;{{ worker.companyName }}
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
//import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';
import WorkerApi from '../../api/WorkerApi';
import AffilationSiteApi from '../../api/AffilationSiteApi';

import ArrayHelper from '../../helper/ArrayHelper';

export default {
    name: 'FilterManager',
    components: {},
    props: {
        tpId: {
            type: String,
            required: true
        },
        bindManagers: {
            type: Array,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            roomId: 0,
            workers: [],
            filterWorkers: [],
            workerId: 0,
            managers: [],
            searchText: '',
            allText: '전체선택',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    watch: {
        bindManagers: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) this.managers = val;
            }
        }
    },
    created() {},
    mounted() {
        this.roomId = Number(this.$route.params.roomId);
        this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterManagerLayer.classList.remove('on');
        },
        setFilterWorker() {
            this.filterWorkers = this.workers.filter(
                work => work.name.indexOf(this.searchText) > -1 || work.companyName.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterWorker();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setManager', this.managers);
            this.close();
        },
        async bind() {
            let response = [];

            if (this.tpId === 'write') {
                if (this.roomId) response = await WorkerApi.listOfroom(this.roomId);
                const condition = item => item.id === this.basic.id;
                ArrayHelper.oneRemove(response, condition);

                this.workers = response.map(item => {
                    const worker = {};
                    worker.id = item.id;
                    worker.name = item.displayName;
                    worker.companyName = item.company.name;
                    return worker;
                });
            } else {
                response = await AffilationSiteApi.workerOfsite(this.basic.csId);
                const condition = item => item.worker.id === this.basic.id;
                ArrayHelper.oneRemove(response, condition);

                this.workers = response.map(item => {
                    const worker = {};
                    worker.id = item.worker.id;
                    worker.name = item.worker.displayName;
                    worker.companyName = item.worker.company.name;
                    return worker;
                });
            }
            this.filterWorkers = this.workers;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.allText = '전체해제';
                this.managers = this.filterWorkers.map(item => item);
            } else {
                this.allText = '전체선택';
                this.managers = [];
            }
        }
    }
};
</script>

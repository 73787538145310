<template>
    <div ref="filterTagsLayer" class="filter-select-wrap filter-tags-wrap" :class="tpId">
        <header class="header primary">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="close">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>태그 선택</span>
                    </li>
                    <li class="board-detail">
                        <a class="btn-delete" @click="allSelect()">
                            <span>{{ allText }}</span>
                        </a>
                        <a class="btn-modify" @click="doConfirm()">
                            <span>확인</span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="태그 검색"
                            @input="setFilterTag"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-result-box">
            <div class="filter-result">
                <ul class="modal-option-list">
                    <li v-for="(tag, index) in filterTags" :key="index">
                        <div class="chk-list">
                            <input
                                :id="tpId + '-modal-chk-tag-1-11' + index"
                                v-model="selectTags"
                                type="checkbox"
                                class="blind"
                                :value="tag"
                            />
                            <label :for="tpId + '-modal-chk-tag-1-11' + index"> #{{ tag }}</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkTagApi from '../../api/WorkTagApi';

export default {
    name: 'FilterTags',
    components: {},
    props: {
        tpId: {
            type: String,
            required: true
        },
        bindTags: {
            type: Array,
            required: false,
            default: null
        }
    },
    data: () => {
        return {
            roomId: 0,
            tags: [],
            filterTags: [],
            selectTags: [],
            searchText: '',
            allText: '전체선택',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    watch: {
        bindTags: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val) {
                if (val) this.selectTags = val;
            }
        }
    },
    created() {},
    mounted() {
        this.$emit('setTags', this.selectTags);
        this.roomId = Number(this.$route.params.roomId);
        this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterTagsLayer.classList.remove('on');
            //this.tempTag = [];
        },
        setFilterTag() {
            this.filterTags = this.tags.filter(tag => tag.indexOf(this.searchText) > -1);
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterTag();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setTags', this.selectTags);
            this.$refs.filterTagsLayer.classList.remove('on');
        },
        async bind() {
            let response = { content: [] };
            if (this.tpId === 'write') {
                if (this.roomId) response = await WorkTagApi.tagsOfroom(this.roomId);
            } else {
                response = await WorkTagApi.tagsOfsite(this.basic.csId);
            }
            this.tags = response.content;
            this.filterTags = this.tags;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.allText = '전체해제';
                this.selectTags = this.filterTags.map(item => item);
            } else {
                this.allText = '전체선택';
                this.selectTags = [];
            }
        }
    }
};
</script>

import MobileHelper from './MobileHelper';

const DOC_ALLOW_FILE = 'image/*,.pdf,.doc,.xls,.ppt,.hwp,.docx,.xlsx,.pptx,.zip,.txt,.me.mp4,.mov';

const MAX_SIZE = 20971520;
const MAX_MOVE_SIZE = 50971520;
const MAX_COUNT = 3;
const ALLOW_FILE_DOC = '.pdf,.doc,.xls,.ppt,.hwp,.docx,.xlsx,.pptx,.zip,.txt,.me.mp4,.mov';
const ALLOW_FILE_IMG = '.jpg,.jpeg,.png,.bmp,.ai,.gif,tif';

export default {
    allowFileExt: () => {
        const platform = MobileHelper.getWebViewPlatform();
        if (platform === 'android') return '';
        return DOC_ALLOW_FILE;
    },
    isCount: count => {
        if (MAX_COUNT <= count) {
            alert('최대 파일 허용개수는 ' + MAX_COUNT + ' 입니다.');
            return false;
        }
        return true;
    },
    isSize: size => {
        if (MAX_SIZE < size) {
            alert('20MB이내로 첨부가 가능합니다.');
            return false;
        }
        return true;
    },
    isNewSize: (size, fileName) => {
        const ext = fileName.split('.').pop() || '';
        const fileExt = 'mp4,mov';
        if (fileExt.indexOf(ext.toLowerCase()) >= 0) {
            if (MAX_MOVE_SIZE < size) {
                alert('동영상은 50MB이내로 첨부가 가능합니다.');
                return false;
            }
        } else {
            if (MAX_SIZE < size) {
                alert('20MB이내로 첨부가 가능합니다.');
                return false;
            }
        }
        return true;
    },
    isAllowFile: fileName => {
        const fileExt = ALLOW_FILE_DOC + ',' + ALLOW_FILE_IMG;
        const ext = fileName.split('.').pop() || '';
        if (fileExt.indexOf(ext) < 0) {
            alert('첨부가능한 파일이 아닙니다.');
            return false;
        }
        return true;
    },
    appFileDownload: fileData => {
        const blob = fileData.blob;
        const filename = fileData.fileName;
        let storageLocation = '';

        switch (window.device.platform) {
            case 'Android':
                storageLocation = window.cordova.file.externalDataDirectory;
                break;

            case 'iOS':
                storageLocation = window.cordova.file.documentsDirectory;
                break;
        }

        const folderPath = storageLocation;
        window.resolveLocalFileSystemURL(
            folderPath,
            function(dir) {
                dir.getFile(
                    filename,
                    {
                        create: true
                    },
                    function(file) {
                        file.createWriter(
                            function(fileWriter) {
                                fileWriter.write(blob);

                                fileWriter.onwriteend = function() {
                                    var url = file.toURL();
                                    window.cordova.plugins.fileOpener2.open(url, blob.type, {
                                        error: function error(err) {
                                            console.error(err);
                                            alert('Unable to download');
                                        },
                                        success: function success() {
                                            console.log('success with opening the file');
                                        }
                                    });
                                };
                                fileWriter.onerror = function(err) {
                                    alert('Unable to download');
                                    console.error(err);
                                };
                            },
                            function(err) {
                                // failed
                                alert('Unable to download');
                                console.error(err);
                            }
                        );
                    },
                    function(err) {
                        alert('Unable to download');
                        console.error(err);
                    }
                );
            },
            function(err) {
                alert('Unable to download');
                console.error(err);
            }
        );
    }
};

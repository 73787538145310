<template>
    <div>
        <header id="header" class="header primary">
            <div class="in-section">
                <ul>
                    <li class="back">
                        <button type="button" class="btn-back" @click="back()">
                            <span class="blind">뒤로</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>{{ roomName + ' 멤버 추가' }}</span>
                    </li>
                    <li class="board-detail">
                        <a class="btn-delete" @click="allSelect()">
                            <span>{{ allText }}</span>
                        </a>
                        <a class="btn-modify" @click="addWorker()">
                            <span>확인</span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="이름,직위, 회사 검색"
                            @keyup="filterWorker"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>
        <article id="content-wrap">
            <main id="contents">
                <div class="contents-body">
                    <ul class="group-list">
                        <li v-for="(worker, index) in filterWorkers" :key="index">
                            <div class="member-info-box">
                                <div class="chk">
                                    <input
                                        :id="'chk-1-' + index"
                                        v-model="workerIds"
                                        type="checkbox"
                                        :value="worker.id"
                                    />
                                    <label :for="'chk-1-' + index"><span class="blind">선택</span></label>
                                </div>
                                <div class="profile">
                                    <div class="short-nm">
                                        <img
                                            :src="worker.imgUrl | profileUrl"
                                            onerror="javascript:this.src='/images/common/default.png"
                                        />
                                    </div>
                                </div>
                                <div class="member-info">
                                    <p class="nm">
                                        {{ worker.name }}
                                    </p>
                                    <div class="meta-info">
                                        <span>{{ worker.companyName }}</span>
                                        <span>{{ worker.regDt | formatTime }}</span>
                                    </div>
                                </div>
                                <div class="phone">
                                    <a @click="callhp(worker.hp)"><span class="blind">전화</span></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </main>
        </article>
    </div>
</template>

<script>
import { mapState } from 'vuex';
//import Header from '../header/RegistWhiteHeader.vue';
//import AffilationSiteApi from '../../api/AffilationSiteApi';
import WorkerApi from '../../api/WorkerApi';
import WorkRoomJoinApi from '../../api/WorkRoomJoinApi';
import WorkRoomApi from '../../api/WorkRoomApi';
import MenuHelper from '../../helper/MenuHelper';

export default {
    name: 'AddWorker',
    components: {},
    data: () => {
        return {
            searchText: '',
            condition: {
                inCsId: 0,
                page: 1,
                rows: 1000
            },
            roomId: 0,
            roomName: '',
            workers: [],
            filterWorkers: [],
            workerIds: [],
            allText: '전체선택',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {
        //this.condition.inCsId = this.basic.csId;
    },
    mounted() {
        this.roomId = Number(this.$route.params.roomId);
        this.condition.inCsId = this.basic.csId;
        WorkRoomApi.detail(this.roomId).then(res => {
            this.roomName = res.name;
        });

        this.doSearch();
    },
    beforeDestroy() {},
    methods: {
        back() {
            this.$router.go(-1);
        },
        doSearch() {
            this.condition.notInRoomId = this.roomId;
            WorkerApi.list(this.condition).then(res => {
                this.workers = res.content.map(item => {
                    const worker = {};
                    worker.id = item.id;
                    worker.name = item.displayName;
                    worker.positionName = item.positionName;
                    worker.companyName = item.company.name;
                    worker.hp = item.hp;
                    worker.firstName = item.name.substring(0, 1);
                    worker.imgUrl = item.imgUrl;
                    worker.regDt = item.regDt;
                    return worker;
                });
                this.filterWorkers = this.workers;
            });
            /*
            AffilationSiteApi.list(this.condition).then(res => {
                //wokers 에서 filter regId, name, 직위만 가진다.
                this.workers = res.content.map(item => {
                    const worker = {};
                    worker.id = item.worker.id;
                    worker.name = item.worker.displayName;
                    worker.positionName = item.worker.positionName;
                    worker.companyName = item.worker.company.name;
                    worker.hp = item.worker.hp;
                    worker.firstName = item.worker.name.substring(0, 1);
                    return worker;
                });
                this.filterWorkers = this.workers;
            });
            */
        },
        filterWorker() {
            this.filterWorkers = this.workers.filter(
                work => work.name.indexOf(this.searchText) > -1 || work.companyName.indexOf(this.searchText) > -1
            );
        },
        async addWorker() {
            await WorkRoomJoinApi.wkroomjoin(this.roomId, { workers: this.workerIds });
            MenuHelper.roomNoHistory(this.$router, this.roomId);
            window.stompClient.send("/app/chat.message/addRoom", JSON.stringify({'roomId': this.roomId, 'workerIds' : this.workerIds}), {});
            //this.$router.go(-1);
        },
        delSearchText() {
            this.searchText = '';
            this.filterWorker();
        },
        callhp(hp) {
            if (hp) window.open('tel:' + hp, '_system');
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.allText = '전체해제';
                this.workerIds = this.filterWorkers.map(item => item.id);
            } else {
                this.allText = '전체선택';
                this.workerIds = [];
            }
        }
    }
};
</script>

export default {
    SET_MY_ROOMS: 'SET_MY_ROOMS',
    SET_WORK: 'SET_WORK',
    SET_SHOW_WORK: 'SET_SHOW_WORK',
    SET_WORK_OF_ROOM: 'SET_WORK_OF_ROOM',
    ADD_WORK_OF_ROOM: 'ADD_WORK_OF_ROOM',
    ADD_MY_ROOM_CNT: 'ADD_MY_ROOM_CNT',
    ADD_MY_ALARM_CNT: 'ADD_MY_ALARM_CNT',
    RESET_MY_ROOM_CNT: 'RESET_MY_ROOM_CNT',
    SET_PICTURE: 'SET_PICTURE',
    SET_WORK_OF_SEARCH: 'SET_WORK_OF_SEARCH',
    SET_SEARCH_CONDITION: 'SET_SEARCH_CONDITION',
    REMOVE_WORK_OF_ROOM: 'REMOVE_WORK_OF_ROOM',
    SET_TEMP_WORK_CONTENT: 'SET_TEMP_WORK_CONTENT',
    SET_TEMP_WORK_DOCFILE: 'SET_TEMP_WORK_DOCFILE',

    SET_TEMP_IMG_LIST: 'SET_TEMP_IMG_LIST'
};

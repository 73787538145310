import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import authHeader from './Header';

const list = csId => httpClient.get(`/sitecompany/${csId}`, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list)
};

<template>
    <div>
        <header id="header" class="header primary">
            <div class="in-section blue">
                <span>현장선택</span>
            </div>
        </header>
        <!-- welcome-area -->
        <section class="top-blue-box">
            <div class="in-section left">
                <div class="user-info-box">
                    <p>
                        <strong>{{ basic.name }}</strong>
                        {{ basic.position }}님을 응원합니다!
                    </p>
                </div>
                <div class="top-blue-info-box">
                    <p class="msg">아래 회원 가입하신 현장 목록 중<br />참여하실 현장을 선택하시고 협업하세요.</p>
                </div>
            </div>
        </section>
        <!-- // welcome-area -->
        <article id="content-wrap" class="main-select-spot">
            <main id="contents">
                <div class="contents-body">
                    <div class="search-wrap1">
                        <div class="field-wrap">
                            <div class="field">
                                <input
                                    v-model="searchText"
                                    type="text"
                                    class="txt"
                                    placeholder="현장명 검색"
                                    @input="setFilterSite"
                                />
                            </div>
                            <button class="btn-del-keyword" @click="delSearchText">
                                <span class="blind">삭제</span>
                            </button>
                        </div>
                    </div>
                    <ul class="list">
                        <li v-for="(site, index) in filterSites" :key="index">
                            <section class="spot-oneline-area oneline-link">
                                <div class="in-section">
                                    <a class="section-shadow" @click="selectSite(site)">
                                        <span class="subject">{{ site.name }}</span>
                                    </a>
                                </div>
                            </section>
                        </li>
                    </ul>
                </div>
                <!-- div class="btn-wrap fixed">
                    <a class="btn-next" role="button" @click="selectMsg"><span>협업 시작하기</span></a>
                </div-->
            </main>
        </article>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AffilationSiteApi from '../api/AffilationSiteApi';
import LoginApi from '../api/LoginApi';
import LogApi from '../api/LogApi';

import MobileHelper from '../helper/MobileHelper';
import MutationTypes from '../store/MutationTypes';
import { JWT_TOKEN, JWT_REFRESH_TOKEN, WORKER_ID, CS_ID, CS_IDS } from '../const';

import { alertDialog } from '../helper/DialogHelper';

export default {
    name: 'SelectSite',
    components: {},
    data: () => {
        return { sites: [], csids: [], filterSites: [], searchText: '' };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        AffilationSiteApi.siteOfworker(this.basic.id).then(res => {
            this.filterSites = this.sites = res.map(item => item.site);
            this.csids = res.map(item => item.site.id);
        });
    },
    beforeDestroy() {},
    methods: {
        doLastAccess() {
            const param = {
                appVer: MobileHelper.isWebView() ? MobileHelper.getAppVersion() : process.env.VERSION,
                platform: MobileHelper.getPlatform(),
                userAgent: navigator.userAgent
            };
            LogApi.accessLog(param);
        },
        selectMsg() {
            alertDialog('현장을 선택하세요');
        },
        async selectSite(site) {
            const session = {
                name: this.basic.name,
                postionName: this.basic.position,
                companyName: this.basic.company,
                id: this.basic.id,
                csId: site.id
            };

            const res = await LoginApi.selectSite(session);
            //오류나는건 추후 공통처리한다.
            if (res.content) {
                const basic = {
                    csId: session.csId,
                    name: session.name,
                    position: session.postionName,
                    company: session.companyName,
                    companyId: res.content.companyId,
                    id: session.id,
                    hp: res.content.hp,
                    site: res.content.siteName,
                    imgUrl: res.content.imgUrl,
                    csAdminYn: res.content.csAdminYn,
                    csCompanyAdminYn: res.content.csCompanyAdminYn
                };

                this.$store.commit(MutationTypes.SET_MAIN_BASIC, basic);

                const oauth = res.content.token;
                this.$store.commit(MutationTypes.SET_OAUTH, oauth);
                window.localStorage.setItem(CS_ID, session.csId);
                window.localStorage.setItem(CS_IDS, this.csids);
                window.localStorage.setItem(WORKER_ID, session.id);
                window.localStorage.setItem(JWT_TOKEN, oauth.token);
                window.localStorage.setItem(JWT_REFRESH_TOKEN, oauth.refreshToken);
                await this.doLastAccess();
                this.$router.push({ name: 'Main' });
            }
        },
        setFilterSite() {
            this.filterSites = this.sites.filter(site => site.name.indexOf(this.searchText) > -1);
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterSite();
        }
    }
};
</script>

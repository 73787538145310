<template>
    <div>
        <header id="header" class="header ">
            <div class="in-section">
                <ul>
                    <li class="loc-nm">
                        <span>COCO {{ boardName[boardType] }}</span>
                    </li>
                </ul>
            </div>
        </header>
        <!-- // header -->

        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <ul class="board-list detail">
                        <li>
                            <div class="board-header">
                                <a href="#">
                                    <div class="board-box">
                                        <p>{{ board.title }}</p>
                                        <div class="meta-info">
                                            <span class="datetime">
                                                {{ board.regDt | longFormatTime }}
                                            </span>
                                            <span> {{ board.author }}&middot;{{ board.register.company.name }} </span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div v-if="board.type === 'notice'" class="board-memo" v-html="board.content" />
                        </li>
                    </ul>
                </div>
            </article>
        </main>
        <div class="app-download" @click="appDownload">
            <p>
                COCO 앱을 설치하시면 간편한 작업/공지 알림 서비스를<br />이용하실 수 있습니다.&nbsp;&nbsp;
                <span>COCO APP 설치하기</span>
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BoardApi from '../../api/BoardApi';
import FileApi from '../../api/FileApi';

export default {
    name: 'KakaoBoardDetail',
    components: {},
    data: () => {
        return {
            bid: null,
            auth: false,
            board: { register: { company: '' } },
            boardName: { notice: '공지', daily: '작업일보' },
            boardType: 'notice',
            fileList: []
        };
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {
        this.bid = this.$route.params.id;
        this.boardType = this.$route.meta.type;
        this.board.type = this.boardType;
        this.board.noticeYn = 'N';
        this.detail();
    },
    beforeDestroy() {
        //console.log('event제거');
    },
    methods: {
        ...mapActions([]),
        appDownload() {
            alert('app Store 연결');
        },
        async detail() {
            if (this.bid) {
                this.board = await BoardApi.kakaoDetail(this.$route.params.id);
                if (this.board.fileId) this.fileList = await FileApi.list(this.board.fileId);
            }
        },
        download(file) {
            FileApi.fileDownload(file.id);
            //console.log(file);
        }
    }
};
</script>

import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';

import authHeader from './Header';

const list = query => httpClient.get(`/affiliation`, { params: query, headers: authHeader() }).then(res => res.data);

const siteOfrequest = () => httpClient.get(`/affiliation/ofrequest`, { headers: authHeader() }).then(res => res.data);

const joinWorker = param => httpClient.post(`/join/affiliation`, param, {}).then(res => res.data);

const approveWorker = param => httpClient.put(`/affiliation`, param, { headers: authHeader() }).then(res => res.data);

const detail = (csId, workerId) =>
    httpClient.get(`/affiliation/${csId}/${workerId}`, { headers: authHeader() }).then(res => res.data);
const regist = param => httpClient.post(`/affiliation/`, param, { headers: authHeader() }).then(res => res.data);
const modify = (csId, workerId, param) =>
    httpClient.put(`/affiliation/${csId}/${workerId}`, param, { headers: authHeader() }).then(res => res.data);

const remove = (csId, workerId) =>
    httpClient.delete(`/affiliation/${csId}/${workerId}`, { headers: authHeader() }).then(res => res.data);

const workerOfsite = csId =>
    httpClient.get(`/affiliation/ofsite/${csId}`, { headers: authHeader() }).then(res => res.data.content);

const siteOfworker = workerId =>
    httpClient.get(`/affiliation/ofworker/${workerId}`, { headers: authHeader() }).then(res => res.data.content);

export default {
    list: wrapHttpClient(list),
    detail: wrapHttpClient(detail),
    regist: wrapHttpClient(regist),
    modify: wrapHttpClient(modify),
    remove: wrapHttpClient(remove),
    workerOfsite: wrapHttpClient(workerOfsite),
    siteOfrequest: wrapHttpClient(siteOfrequest),
    siteOfworker: wrapHttpClient(siteOfworker),
    approveWorker: wrapHttpClient(approveWorker),
    joinWorker: wrapHttpClient(joinWorker)
};

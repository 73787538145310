<template>
    <div ref="filterEquipsLayer" class="filter-select-wrap filter-equips-wrap">
        <header class="header primary">
            <div class="in-section">
                <ul>
                    <li class="close">
                        <button type="button" class="btn-close" @click="close">
                            <span class="blind">닫기</span>
                        </button>
                    </li>
                    <li class="loc-nm">
                        <span>장비 추가</span>
                    </li>
                    <li class="board-detail">
                        <a class="btn-delete" @click="allSelect()">
                            <span>{{ allText }}</span>
                        </a>
                        <a class="btn-modify" @click="doConfirm()">
                            <span>확인</span>
                        </a>
                    </li>
                </ul>
            </div>
        </header>
        <div class="subheader-list-box search2">
            <div class="search-wrap not-filter">
                <div class="field-wrap">
                    <div class="field">
                        <input
                            v-model="searchText"
                            type="text"
                            class="txt"
                            placeholder="장비 검색"
                            @input="setFilterEquip"
                        />
                    </div>
                    <button class="btn-del-keyword" @click="delSearchText">
                        <span class="blind">삭제</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-result-box">
            <div class="filter-result">
                <ul class="modal-option-list">
                    <li v-for="(equip, index) in filterEquips" :key="index">
                        <div class="chk-list">
                            <input
                                :id="'modal-chk-equip-' + index"
                                v-model="selectEquips"
                                type="checkbox"
                                class="blind"
                                :value="equip"
                            />
                            <label :for="'modal-chk-equip-' + index">{{ equip.id.code.name }}</label>
                        </div>
                        <div class="item-spec">
                            <p>{{ equip.id.code.spec }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkDailyApi from '../../api/WorkDailyApi';

export default {
    name: 'FilterEquip',
    components: {},
    props: {},
    data: () => {
        return {
            equips: [],
            filterEquips: [],
            selectEquips: [],
            searchText: '',
            allText: '전체선택',
            isAll: false
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {
        //this.$emit('setEquips', this.selectEquips);
        //this.bind();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        close() {
            this.$refs.filterEquipsLayer.classList.remove('on');
            //this.tempTag = [];
        },
        setFilterEquip() {
            this.filterEquips = this.equips.filter(
                equip =>
                    equip.id.code.name.indexOf(this.searchText) > -1 || equip.id.code.spec.indexOf(this.searchText) > -1
            );
        },
        delSearchText() {
            this.searchText = '';
            this.setFilterEquip();
        },
        doConfirm() {
            //console.log(this.workerIds);
            this.$emit('setEquips', this.selectEquips);
            this.$refs.filterEquipsLayer.classList.remove('on');
            this.selectEquips = [];
        },
        async bind(today) {
            const response = (await WorkDailyApi.companyEquips(today)) || { content: [] };
            this.equips = response.content.map(item => item);
            this.filterEquips = this.equips;
        },
        allSelect() {
            this.isAll = !this.isAll;
            if (this.isAll) {
                this.allText = '전체해제';
                this.selectEquips = this.filterEquips.map(item => item);
            } else {
                this.allText = '전체선택';
                this.selectEquips = [];
            }
        }
    }
};
</script>

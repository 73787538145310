<template>
    <div id="signup-wrap">
        <div id="signup-container">
            <div id="signup-header">
                <p class="single-tit">
                    COCO 현장 선택
                </p>
                <p class="subtit">
                    회원으로 가입할 현장을 선택하세요.
                </p>
            </div>

            <div id="signup-body">
                <div>
                    <autocomplete
                        ref="autocomplete"
                        placeholder="가입할 현장을 검색하세요."
                        :source="distributionGroupsEndpoint"
                        results-property="content"
                        :results-display="formattedDisplay"
                        :show-no-results="false"
                        @selected="addDistributionGroup"
                    />
                </div>
                <div class="signup-form-wrap">
                    <p>선택한 현장명</p>
                    <div class="field">
                        <input
                            v-model="displayName"
                            type="text"
                            class="txt readonly"
                            placeholder="선택된 현장이 없습니다."
                        />
                    </div>
                </div>
            </div>
            <div class="signup-btn-wrap">
                <a class="btn-next" @click="goJoinStep"><span>선택한 현장으로 회원가입</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import ConstructionApi from '../../api/ConstructionApi';
import Autocomplete from 'vuejs-auto-complete';

export default {
    name: 'JoinSelectSite',
    components: { Autocomplete },
    data: () => {
        return {
            filterSite: [],
            cssites: [],
            csId: 0,
            displayName: ''
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getCsList();
    },
    beforeDestroy() {},
    methods: {
        getCsList() {
            ConstructionApi.list().then(res => {
                if (res.content) {
                    this.cssites = res.content.map(item => {
                        return { id: item.id, name: item.name };
                    });
                }
            });
        },
        formattedDisplay(result) {
            return result.name;
        },
        addDistributionGroup(result) {
            this.csId = result.value;
            this.displayName = result.display;
            // access the autocomplete component methods from the parent
            this.$refs.autocomplete.close();
        },
        authHeaders() {
            return {
                Authorization: '',
                Workerid: 0
            };
        },
        distributionGroupsEndpoint(input) {
            return '/api/cstsite?finishYn=N&page=1&rows=1000&name=' + input;
        },
        goJoinStep() {
            if (this.csId > 0) this.$router.push({ path: '/join/step1/' + this.csId });
            else alert('현장을 검색하세요.');
        }
    }
};
</script>

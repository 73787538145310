<template>
    <header id="header" class="header primary">
        <div class="in-section">
            <ul>
                <li class="back">
                    <button type="button" class="btn-back" @click="back()">
                        <span class="blind">뒤로</span>
                    </button>
                </li>
                <li class="loc-nm">
                    <span>{{ locNm }}</span>
                </li>
                <li v-if="locType !== 'detail'" class="etc">
                    <button type="button" class="btn-etc" @click="fnConfirm()">
                        <span>{{ locType === 'list' ? btnName : '확인' }}</span>
                    </button>
                </li>
                <li v-if="locType === 'detail' && fnRemove" class="board-detail">
                    <a class="btn-delete" @click="fnRemove()"><span>삭제</span></a>
                    <a class="btn-modify" @click="fnModify()"><span>수정</span></a>
                    <a v-if="fnList" class="btn-modify" @click="fnList()"><span>목록</span></a>
                </li>
                <li v-else-if="locType === 'detail' && fnList" class="board-detail">
                    <a class="btn-modify" @click="fnList()"><span>목록</span></a>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'RegistWhiteHeader',
    components: {},
    props: {
        locNm: {
            type: String,
            required: true
        },
        locType: {
            type: String,
            required: true
        },
        btnName: {
            type: String,
            required: false,
            default: '작성'
        },
        fnConfirm: {
            type: Function,
            required: false,
            default: null
        },
        fnRemove: {
            type: Function,
            required: false,
            default: null
        },
        fnModify: {
            type: Function,
            required: false,
            default: null
        },
        fnList: {
            type: Function,
            required: false,
            default: null
        }
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        back() {
            this.$router.go(-1);
        }
    }
};
</script>

<template>
    <div>
        <div v-if="!showImageEditor">
            <Header loc-nm="조치등록" loc-type="regist" :fn-confirm="doModify" />
            <main id="content-wrap">
                <article id="contents">
                    <div class="contents-body blueFull">
                        <div class="repair-write-wrap">
                            <div class="write-photo-list">
                                <div class="scroll-wrap">
                                    <div class="inbox">
                                        <ul class="photo-roll">
                                            <li class="add-photo">
                                                <a class="add-photo" @click="imagePicker" />
                                                <div class="thumb-write">
                                                    <a @click="imagePicker">
                                                        <div class="thumb-inner" />
                                                    </a>
                                                </div>
                                            </li>
                                            <li v-for="(img, index) in imgFileList" :key="index">
                                                <a class="photo-delete" @click="onClickImgDel(img)" />
                                                <div class="thumb-write">
                                                    <a @click="openImgEditor(img)">
                                                        <div class="thumb-inner">
                                                            <img :src="img.dataUrl" loading="eager" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="repair-memo">
                                <textarea
                                    id="repair-memo-text"
                                    ref="commentMemo"
                                    v-model="writeData.content"
                                    placeholder="작업 또는 사진내용을 입력하세요."
                                    autofocus
                                />
                            </div>
                        </div>
                        <div class="alarm">
                            <p>조치 내용의 수신자는 초기 업무 등록자로 자동 지정되며, Push 알림이 전달됩니다.</p>
                        </div>
                        <!-- 20200527 첨부파일 추가내용 -->
                        <div class="form-wrap">
                            <div class="field-box">
                                <div class="label">
                                    첨부파일
                                </div>
                                <div class="field">
                                    <div class="file-add-box">
                                        <input
                                            type="button"
                                            class="button"
                                            value="파일찾기(선택시파일명노출)"
                                            @click="pickerFile"
                                        />
                                        <input
                                            id="docFile"
                                            ref="docFile"
                                            type="file"
                                            :accept="DOC_ALLOW_FILE"
                                            class="blind"
                                            @change="doDocFileAttach"
                                        />
                                        <a class="btn-file-add" @click="pickerFile">
                                            <span class="blind">파일검색</span>
                                        </a>
                                    </div>
                                    <div class="selected-list mb">
                                        <span v-for="(fileInfo, index) in addFileList" :key="index" class="file">
                                            {{ fileInfo.name }}
                                            <button class="btn_remove" @click="onClickFileDel(fileInfo)">삭제</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 20200527 첨부파일 추가내용 //-->
                    </div>
                </article>
            </main>
        </div>
        <image-editor v-else ref="popImageEditor" @close="closeImgEditor" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/RegistHeader.vue';
import FileApi from '../../api/FileApi';
import WorkActionApi from '../../api/WorkActionApi';
//import DataHelper from '../../helper/DataHelper';

import ArrayHelper from '../../helper/ArrayHelper';
import FileHelper from '../../helper/FileHelper';

//import { DOC_ALLOW_FILE } from '../../const';

import ImageEditor from '../common/ImageEditor.vue';
import ImagePickerHelper from '../../helper/ImagePickerHelper';
import CameraHelper from '../../helper/CameraHelper';
import MobileHelper from '../../helper/MobileHelper';
import { MAX_IMG_COUNT } from '../../const';

export default {
    name: 'ModifyWorkAction',
    components: { Header, ImageEditor },
    props: {},
    data: () => {
        return {
            workId: 0,
            fileId: 0,
            writeData: {
                work: {},
                content: '',
                file: {}
            },
            imgUrl: '',
            docFileList: [],
            removeFileList: [],
            addFileList: [],
            DOC_ALLOW_FILE: FileHelper.allowFileExt(),
            docfileId: 0,
            docfileNo: 0,
            imgFileList: [],
            imgfileNo: 0,
            showImageEditor: false
        };
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {
        $('html').scrollTop(0);
        this.workId = this.$route.params.workId;
        this.id = this.$route.params.workActionId;
        this.getWorkAction();
    },
    beforeDestroy() {},
    methods: {
        ...mapActions(['showLoading', 'hideLoading']),
        pickerFile() {
            if (FileHelper.isCount(this.addFileList.length)) this.$refs.docFile.click();
        },
        doDocFileAttach() {
            const fileObject = this.$refs.docFile.files[0];
            if (fileObject) {
                const size = fileObject.size;
                if (FileHelper.isNewSize(size, fileObject.name) && FileHelper.isAllowFile(fileObject.name)) {
                    ++this.docfileNo;
                    const formData = new FormData();
                    formData.append('file_id', '');
                    formData.append('file_no', this.docfileNo);
                    formData.append('file', fileObject);
                    const addFile = {
                        fileNo: this.docfileNo,
                        name: fileObject.name,
                        form: formData
                    };
                    this.addFileList.push(addFile);
                }
            }
        },
        onClickFileDel(fileInfo) {
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.addFileList, condition);
        },
        async doModify() {
            this.writeData.id = this.id;
            this.writeData.work.id = this.workId;
            this.writeData.file.id = this.fileId;
            this.showLoading();

            for (var removeFile of this.removeFileList) {
                await FileApi.remove({
                    fileId: removeFile.id.fileId,
                    fileNo: removeFile.id.fileNo
                });
            }

            for (var docFile of this.addFileList) {
                const docFileForm = docFile.form;
                if (this.docFileId > 0) docFileForm.set('file_id', this.docFileId);
                const fileRes = await FileApi.fileUpload(docFileForm, 'doc');
                if (fileRes.content) {
                    this.docFileId = fileRes.content[0].id.fileId;
                    this.writeData.docFileId = this.docFileId;
                }
            }

            /* 이미지 업로드 */

            if (this.imgFileList.length > 0) {
                //this.imgfileNo = 0;
                for (var imgFile of this.imgFileList) {
                    if (imgFile.type === 'server') continue;
                    const imgFileForm = new FormData();
                    imgFileForm.append('file_id', '');
                    imgFileForm.append('file_no', imgFile.fileNo);
                    imgFileForm.append('file_name', imgFile.name);
                    imgFileForm.append('file', imgFile.data);
                    if (this.fileId > 0) imgFileForm.set('file_id', this.fileId);
                    //imgFileForm.set('file_no', ++this.imgfileNo);

                    if (imgFile.type === 'file') {
                        const fileRes = await FileApi.imgUpload(imgFileForm);
                        if (fileRes.content && (this.fileId === 0 || this.fileId === null)) {
                            this.fileId = fileRes.content.fileId;
                        }
                    } else if (imgFile.type === 'blob') {
                        const fileRes = await FileApi.blolImgUpload(imgFileForm);
                        if (fileRes.content && (this.fileId === 0 || this.fileId === null)) {
                            this.fileId = fileRes.content.fileId;
                        }
                    } else {
                        //moveImgs.push(imgFile.data);
                    }
                }
                if (this.fileId > 0) this.writeData.file.id = this.fileId;
            }

            const response = await WorkActionApi.modify(this.id, this.writeData);
            this.hideLoading();
            if (response) this.$router.go(-1);
        },
        resetData() {
            this.writeData.content = '';
            this.docFileId = 0;
            this.docfileNo = 0;
            this.imgfileNo = 0;
            this.addFileList = [];
            this.imgFileList = [];
        },
        async getWorkAction() {
            //this.fileId = this.$route.params.fileId;
            const res = await WorkActionApi.detail(this.id);
            const workAction = res.content;
            if (workAction.file) this.fileId = workAction.file.id;
            this.docFileId = workAction.docFileId;
            this.writeData.content = workAction.content;
            this.imgFileList = [];
            if (this.fileId) {
                this.imgfileNo = 0;
                const fileRes = await FileApi.list(this.fileId);
                this.imgFileList = fileRes.map(item => {
                    this.imgfileNo = item.id.fileNo;
                    return {
                        fileId: item.id.fileId,
                        fileNo: item.id.fileNo,
                        name: item.name,
                        type: 'server',
                        dataUrl: item.url,
                        data: item
                    };
                });
            }

            if (this.docFileId) {
                FileApi.list(this.docFileId).then(result => {
                    this.docFileList = result;
                    const fileInfo = this.docFileList[result.length - 1];
                    this.docfileNo = fileInfo.id.fileNo;
                });
            }
        },
        openImgEditor(imgObject) {
            this.showImageEditor = true;
            setTimeout(() => {
                this.$refs.popImageEditor.setDataUrlImage(imgObject);
            }, 100);
        },
        closeImgEditor() {
            document.body.classList = 'write lightblue';
            this.showImageEditor = false;
        },
        onClickImgDel(fileInfo) {
            if (fileInfo.type === 'server') {
                this.removeFileList.push(fileInfo);
            }
            const condition = item => item.fileNo === fileInfo.fileNo;
            ArrayHelper.oneRemove(this.imgFileList, condition);
        },
        imagePicker() {
            if (MobileHelper.isWebView()) {
                const cnt = MAX_IMG_COUNT - this.imgFileList.length;
                ImagePickerHelper.getPictures(this.doImagePickerAttach, cnt);
            }
        },
        async doImagePickerAttach(fileUrlList) {
            //let fileNo = 0;
            for (var fileUrl of fileUrlList) {
                const blob = CameraHelper.base64ToBlob(fileUrl);
                const now = new Date();
                const imageName = now.getTime() + '.jpg';
                const addFile = {
                    fileNo: ++this.imgfileNo,
                    name: imageName,
                    type: 'blob',
                    dataUrl: fileUrl,
                    data: blob
                };
                this.imgFileList.push(addFile);
            }
        }
    }
};
</script>

<template>
    <header id="header" class="header primary">
        <div class="in-section">
            <ul>
                <li class="back">
                    <button type="button" class="btn-back" @click="close()">
                        <span class="blind">닫기</span>
                    </button>
                </li>
                <li class="loc-nm">
                    <span>{{ locNm }}</span>
                </li>
                <li v-if="locNm === '작업 상세' && auth()" class="onoff">
                    <input
                        id="task-chk"
                        v-model="work.isFinish"
                        type="checkbox"
                        class="blind"
                        value="Y"
                        @change="finish"
                    />
                    <div class="task-status">
                        <label for="task-chk"><span class="blind">업무 상태</span></label>
                    </div>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import WorkAPI from '../../api/WorkApi';
import { isAuth } from '../../helper/AuthHelper';
export default {
    name: 'WorkHeader',
    components: {},
    props: {
        locNm: {
            type: String,
            required: true
        },
        work: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {};
    },
    computed: {
        ...mapState({})
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions(['getWork', 'getWeather', 'getCount']),
        close() {
            this.$router.go(-1);
        },
        async finish() {
            await WorkAPI.finish(this.work.id);
            this.getWork();
            this.getWeather();
            this.getCount();
            const v = this.work.isFinish ? 'Y' : 'N';
            this.$emit('update:finishYn', v);
        },
        auth() {
            return isAuth(this.work.regId);
        }
    }
};
</script>

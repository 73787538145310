const CameraHelper = {};

import LogManager from '../module/LogManager';

const rImageType = /data:(image\/.+);base64,/;

CameraHelper.setOptions = srcType => {
    const Camera = window.Camera;
    const options = {
        // Some common settings are 20, 50, and 100
        quality: 30,
        //destinationType: Camera.DestinationType.FILE_URI,
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType: srcType,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: false,
        saveToPhotoAlbum: true,
        correctOrientation: true, //Corrects Android orientation quirks
        targetWidth: 1200,
        targetHeight: 1200
    };
    return options;
};

CameraHelper.getFileEntry = (imageUri, callbackFn) => {
    window.resolveLocalFileSystemURL(
        imageUri,
        function success(fileEntry) {
            fileEntry.file(function(fileData) {
                callbackFn(fileData);
            });
        },
        e => {
            //console.error('file upload error', e);
            LogManager.sendLog(e);
        }
    );
};

CameraHelper.getPicture = (sourceType, callbackFn, failcb) => {
    const Camera = window.Camera;
    if (!Camera) return;
    const PictureSourceType =
        sourceType === 0 ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA;
    navigator.camera.cleanup();
    navigator.camera.getPicture(
        // 사진 가져오기에 성공한 경우 호출될 함수
        imageUri => {
            if (!imageUri) return '';
            callbackFn('data:image/jpeg;base64,' + imageUri);
        },
        // 사진 가져오기에 실패한 경우 호출될 함수
        () => {
            failcb();
        },
        // 카메라,갤러리 호출 옵션
        CameraHelper.setOptions(PictureSourceType)
    );
};

CameraHelper.b64toBlobAlt = (dataURI, contentType) => {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contentType });
};

CameraHelper.base64ToBlob = data => {
    let mimeString = '';
    let raw, uInt8Array, i;

    raw = data.replace(rImageType, function(header, imageType) {
        mimeString = imageType;
        return '';
    });

    raw = atob(raw);
    const rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line
    for (i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: mimeString });
};

CameraHelper.originPicture = (callbackFn, failcb) => {
    const Camera = window.Camera;
    const originOptions = {
        // Some common settings are 20, 50, and 100
        quality: 100,
        //destinationType: Camera.DestinationType.FILE_URI,
        destinationType: Camera.DestinationType.DATA_URL,
        sourceType: Camera.PictureSourceType.CAMERA,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: false,
        saveToPhotoAlbum: true,
        correctOrientation: true //Corrects Android orientation quirks
    };

    if (!Camera) return;
    navigator.camera.cleanup();
    navigator.camera.getPicture(
        // 사진 가져오기에 성공한 경우 호출될 함수
        fileUri => {
            if (!fileUri) return '';
            callbackFn('data:image/jpeg;base64,' + fileUri);
            //return callbackFn([fileUri]);
        },
        // 사진 가져오기에 실패한 경우 호출될 함수
        () => {
            failcb();
        },
        // 카메라,갤러리 호출 옵션
        originOptions
    );
};

export default CameraHelper;

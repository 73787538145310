<template>
    <div id="signup-wrap">
        <div id="signup-container">
            <div id="signup-header">
                <p class="single-tit">
                    비밀번호 찾기
                </p>
                <p class="subtit">
                    초기화된 비밀번호가 문자 메시지로 발송됩니다.
                </p>
            </div>

            <div id="signup-body">
                <div class="signup-form-wrap">
                    <p>휴대번호 <span class="require">*</span></p>
                    <div class="field">
                        <input v-model="number" type="tel" class="txt" placeholder="-없이 입력 (ex.01012345678)" />
                    </div>
                    <p>성명 <span class="require">*</span></p>
                    <div class="field">
                        <input v-model="name" type="text" class="txt" placeholder="성명을 입력하세요." />
                    </div>
                </div>

                <div class="btn-wrap">
                    <a @click="doFindPasswd"><span>비밀번호 찾기</span></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoginApi from '../../api/LoginApi';
import { alertDialog } from '../../helper/DialogHelper';

export default {
    name: 'FindPasswd',
    components: {},
    data: () => {
        return {
            number: '',
            name: ''
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        async doFindPasswd() {
            try {
                await LoginApi.findPasswd({
                    number: this.number,
                    name: this.name
                });
                alertDialog('SMS로 임시 비밀번호를 발급하였습니다.');
                this.$router.push({ path: '/login' });
            } catch (error) {
                if (error.response) {
                    alertDialog(error.response.data.msg);
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <Header loc-nm="비밀번호 변경" loc-type="regist" :fn-confirm="passwd" />
        <main id="content-wrap">
            <article id="contents">
                <div class="contents-body">
                    <div class="form-wrap">
                        <div class="field-box">
                            <div class="label">
                                현재 비밀번호
                            </div>
                            <div class="field">
                                <input
                                    v-model="worker.passwd"
                                    type="password"
                                    class="txt"
                                    placeholder="현재 비밀번호를 입력하세요."
                                />
                            </div>
                        </div>
                        <div class="divider" />
                        <div class="field-box">
                            <div class="label">
                                새 비밀번호
                            </div>
                            <div class="field">
                                <input
                                    v-model="worker.newpasswd"
                                    type="password"
                                    class="txt"
                                    placeholder="새 비밀번호를 입력하세요."
                                />
                            </div>
                        </div>
                        <div class="field-box">
                            <div class="label">
                                비밀번호 재입력
                            </div>
                            <div class="field">
                                <input
                                    v-model="worker.renewpasswd"
                                    type="password"
                                    class="txt"
                                    placeholder="다시 한 번 입력하세요."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '../header/RegistWhiteHeader.vue';

import WorkerApi from '../../api/WorkerApi';

import MenuHelper from '../../helper/MenuHelper';
import { alertDialog } from '../../helper/DialogHelper';

export default {
    name: 'Passwd',
    components: { Header },
    data: () => {
        return {
            worker: {
                passwd: '',
                newpasswd: '',
                renewpasswd: ''
            }
        };
    },
    computed: {
        ...mapState({
            basic: state => state.main.basic
        })
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        ...mapActions([]),
        async passwd() {
            if (!this.worker.passwd.trim()) {
                alertDialog('현재 비밀번호를 입력하세요.');
                return;
            }

            if (this.worker.newpasswd !== this.worker.renewpasswd) {
                alertDialog('새로 입력하신 비밀번호가 일치하지 않습니다.');
                return;
            }
            const res = await WorkerApi.passwd(this.basic.id, this.worker);

            if (res) {
                MenuHelper.mypage(this.$router);
            }
            //이 부분의 오류,성공처리에 대해서 공통작업이 필요함
        }
    }
};
</script>

import MobileHelper from './MobileHelper';

export const alertDialog = message => {
    if (MobileHelper.isWebView()) {
        navigator.notification.alert(
            message, // message
            () => {}, // callback
            'COCO', // title
            '확인' // buttonName
        );
    } else {
        window.alert(message);
    }
};

export const confirmDialog = (message, okCallback, cancelCallback) => {
    if (MobileHelper.isWebView()) {
        navigator.notification.confirm(
            message, // message
            buttonIndex => {
                if (buttonIndex === 1) {
                    okCallback();
                } else {
                    if (cancelCallback) cancelCallback();
                }
            }, // callback
            'COCO', // title
            ['확인', '취소'] // buttonName
        );
    } else {
        if (window.confirm(message)) {
            okCallback();
        } else {
            if (cancelCallback) cancelCallback();
        }
    }
};

export const photoDialog = (cameraCallback, galleryCallback) => {
    navigator.notification.confirm(
        '찰영 또는 갤러리를 선택하세요', // message
        buttonIndex => {
            if (buttonIndex === 1) {
                cameraCallback();
            } else {
                galleryCallback();
            }
        }, // callback
        'COCO', // title
        ['찰영', '갤러리'] // buttonName
    );
};

export const exitDialog = () => {
    navigator.notification.confirm(
        '앱을 종료하시겠습니까?', // message
        buttonIndex => {
            if (buttonIndex === 1) {
                window.cordova.plugins.exit();
            }
        }, // callback
        'COCO', // title
        ['종료', '취소'] // buttonName
    );
};

import CommentList from '../components/common/CommentList';
export default [
    {
        path: '/comment/:id',
        name: 'CommentList',
        component: CommentList
    },
    {
        path: '/commentOfwork/:id/:commentId',
        name: 'commentOfwork',
        component: CommentList
    },
    {
        path: '/commentOfaction/:id/:commentId',
        name: 'commentOfaction',
        component: CommentList
    }
];

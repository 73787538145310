import { httpClient } from '../network/httpclient/HttpClientFactory';
import { wrapHttpClient } from '../helper/HttpHelper';
import authHeader from './Header';

const list = query => httpClient.get(`/worker`, { params: query, headers: authHeader() }).then(res => res.data);

const listOfroom = roomId =>
    httpClient.get(`/worker/ofroom/${roomId}`, { headers: authHeader() }).then(res => res.data.content);

const detail = id => httpClient.get(`/worker/${id}`, { headers: authHeader() }).then(res => res.data.content);
const regist = param => httpClient.post(`/join`, param, { headers: authHeader() }).then(res => res.data);
const modify = (id, param) =>
    httpClient.put(`/worker/${id}`, param, { headers: authHeader() }).then(res => res.data.content);
const passwd = (id, param) =>
    httpClient.put(`/worker/passwd/${id}`, param, { headers: authHeader() }).then(res => res.data.content);
const remove = id => httpClient.delete(`/worker/${id}`, { headers: authHeader() }).then(res => res.data);

const csAdmin = (csId, workerId) =>
    httpClient.get(`/csadmin/${csId}/${workerId}`, { headers: authHeader() }).then(res => res.data.content);

const siteLeave = id => httpClient.delete(`/siteLeave/${id}`, { headers: authHeader() }).then(res => res.data);

const dwencAuth = param => httpClient.post(`/dwenc/auth`, param, { headers: authHeader() }).then(res => res.data);

export default {
    list: wrapHttpClient(list),
    detail: wrapHttpClient(detail),
    regist: wrapHttpClient(regist),
    modify: wrapHttpClient(modify),
    passwd: wrapHttpClient(passwd),
    remove: wrapHttpClient(remove),
    listOfroom: wrapHttpClient(listOfroom),
    csAdmin: wrapHttpClient(csAdmin),
    siteLeave: wrapHttpClient(siteLeave),
    dwencAuth: wrapHttpClient(dwencAuth)
};
